import { KeyboardArrowRight, KeyboardReturnOutlined } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import  Button  from '../../components/shared-components/buttons/button/button';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import {Map} from '../../components/map/map';
import ConfirmDialog from '../../components/shared-components/form/confirm-dialog/confirm-dialog';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ImageUpload from './components/image-upload/image-upload'; 
import {VideoUpload} from './components/video-upload/video-upload'; 
import {BasicInfo} from './components/basic-info/basic-info'; 
import ContactInfo from './components/contact/contact';
import {AdditionalData} from './components/additional-data/additional-data';
import CheckboxFields from './components/checkbox-fields/checkbox-fields';

import CountryAPI from '../../axios/country';
import UserAPI from '../../axios/user';
import ListingAPI from '../../axios/listing';
import FileAPI from '../../axios/file';

function isNumeric(str) {
    if (typeof str != "string") return false 
    return !isNaN(str) && 
           !isNaN(parseFloat(str)) 
  }

export const NewListingData = ({type}) => {

    const params = useParams();
    const listingID = params.id;
    const addMode = !listingID;
    const navigate = useNavigate();

    const listingData = Object.freeze({
        "title" : "",
        "price" : "",
        "place" : "",
        "subplace" : "",
        "location" : "",
        "address" : "",
        "area" : "",
        "num_rooms" : null, 
        "num_bathrooms" : null, 
        "floor" : null,
        "max_floor" : null, 
        "bills" : "",
        "deposit" : "",
        "property_type" :0, 
        "property_state" : 0,
        "heating" : [],
        "urgent" : false,
        "habitable" : false,
        "registered" : false,
        "vat" : false,
        "salon" : false,
        "duplex" : false,
        "mortgage" : false,
        "not_house" : false,
        "not_last_floor" : false,
        "attic" : false,
        "energy_passport" : false, 
        "exchange" : false,
        "terrace" : false,
        "balcony" : false,
        "lift" : false,
        "basement" : false, 
        "hot_water" : false,
        "has_phone" : false,
        "internet" : false,
        "cable" : false,
        "ac" : false,
        "interphone" : false, 
        "surveillance" : false,
        "garage" : false,
        "parking" : false,
        "description" : "",
        "phone" : "",
        "mobile" : "",
        "email" : "",
        "lnglat" : [44.8125, 20.4612],
        "listing_type" : type
    });


    const { t } = useTranslation(["listing", "profile", "common"]);
    //-------------------------------------------
    //States
    //-------------------------------------------
    const [images, setImages] = React.useState([]);
    const [uploadedImages, setUploadedImages] = React.useState([]);
    const [videos, setVideos] = React.useState([]);
    const [uploadedVideos, setUploadedVideos] = React.useState([]);
    const maxNumber = 20;
    const [ showConfirmDialog, setShowConfirmDialog] = useState(false);

    const [mapFocus, setMapFocus] = useState(() => { return [44.8125, 20.4612] });


    const [selectedPlace, setSelectedPlace] = useState(null);
    const [selectedSubplace, setSelectedSubplace] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [currentUserType, setCurrentUserType] = useState(() => { return [] });
    const [price, setPrice] = useState(0);
    
    
    const [formDataListing, updateFormDataListing] = useState(listingData);
    const [countries, setCountries] = useState(() => { return [] });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [heating, setHeating] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
   

    //-------------------------------------------
    //useEffect
    //-------------------------------------------
    useEffect(() => { 
        //console.log("UseEffect: []");
        ListingAPI.getPromotionPrice(1).then(res => {
           
            setPrice(res.data.price)
        }) 


        CountryAPI.getListOfCountries()
        .then(function (response) {
            setCountries(response.data);
          });

        

        if (!addMode){
            ListingAPI.getListingData(listingID).then(
                function (response){
                    populateListingData(response.data);
                }
            );

            var user = JSON.parse(localStorage.getItem("user"));
            setCurrentUserType(user.user_type);
        }
        else{
            UserAPI.getProfileData().then(function (response) {
                populateProfileData(response.data);
              }); 
        }
        
        
       
    },[]);
   


    //-------------------------------------------
    //Functions
    //-------------------------------------------


    function populateProfileData(data){

        setCurrentUserType(data['user_type']);

        const fields = ['email', 'phone', 'mobile'];

        for(const i in fields){
            
            updateFormDataListing(prev => ({
                ...prev,
                [fields[i]] : data[fields[i]]
            }));
        }
        
    }

    
    

    function populateListingData(data){
        
        setSelectedPlace(data['place']);
        setSelectedSubplace(data['subplace']);
        setSelectedLocation(data['location']);
        setHeating(data['heating']);

        var img = [];
        data['photos'].forEach(element => {
          
            var obj = Object();
            obj['id'] = element.id;
            obj['photo'] = element.photo.ratios.null.sources['image/webp']['300'];
            img.push(obj)
            //console.log(obj);
        });

        setUploadedImages(img);
        delete data.photos;
        setUploadedVideos(data['videos']);
        delete data.videos;
        var a = [];
        a.push(data['lat']);
        a.push(data['lng']);
        updatePosition(a);

        for(const key in data){
            if (key !== "place" && key !== "subplace" && key !== "location" && key !== "price"){
            updateFormDataListing(prev => ({
                ...prev,
                [key] : data[key]
            }));}
        }

        updateFormDataListing(prev => ({
            ...prev,
            "place" : data["place"].id
        }));

        updateFormDataListing(prev => ({
            ...prev,
            "subplace" : data["subplace"].id
        }));

        updateFormDataListing(prev => ({
            ...prev,
            "location" : data["location"].id
        }));

        updateFormDataListing(prev => ({
            ...prev,
            "price" : parseFloat(data["price"]) === 0 ? "" : data["price"]
        }));
        
    }



    const itemChecked = (item) => {
        item.checked = !item.checked;
    }

    const heatingChange = (e) => {
        setHeating(e.target.value);
        updateFormDataListing({
			...formDataListing,
			"heating": e.target.value,
		});
	}; 


    const handleAutocomplete = (newValue, type) => {
        if(newValue){
            updateFormDataListing({
              ...formDataListing,
              [type]: newValue,
          });
          }  

    }

    

    

    const handleChangeListing = (e) => {
       
		updateFormDataListing({
			...formDataListing,
			[e.target.name]: e.target.value,
		});
	};

    const handleChangeType = (e) => {
        
		updateFormDataListing({
			...formDataListing,
			"property_type": e.target.value,
		});
	};   

    const handleChangeState = (e) => {
        
		updateFormDataListing({
			...formDataListing,
			"property_state": e.target.value,
		});
	};  

    const handleChangeCheckbox = (e) => {
        updateFormDataListing({
			...formDataListing,
			[e.target.name]: e.target.checked,
		});
      };

      const handleChangeNumber = (e) => {
      const result = e.target.value.trim().replace(/[a-zA-Z_?!$%@#&*\/\-+<>\[\]{}()]*/g, '');
        
       
        updateFormDataListing({
			...formDataListing,
			[e.target.name]: result,
		});
      };

      const onChangeImage = (imageList, addUpdateIndex) => {
        setImages(imageList);
      };
    
      const onChangeVideo = (videoList, addUpdateIndex) => {
        setVideos(videoList);
      };

  
      const updatePosition = (value) => {
        updateFormDataListing({
			...formDataListing,
			"lnglat": value,
		});
      }

    const checkMandatory = (data) => {

       
        
        if (data.title === "")
        {
           
            return false;}
      //  if (data.price === "")
       //     return false;
        if (data.place === "")
        {
          
            return false;}
        if (data.subplace === "")
        {
          
            return false;}
        if (data.location === "")
        {
           
            return false;}
        if (data.property_type == 0)
        {
           
            return false;}
        if (data.property_state == 0)
            {
               
                return false;}
        if (data.area === "")
        {
           
            return false;}
        if (data.email === "")
        {
           
            return false;}
        if (data.phone === "")
        {
            
            return false;}
        if (data.mobile === "")
        {
           
            return false;}
        if (data.num_rooms === null)
        {
            
            return false;}
        if (data.num_bathrooms === null)
        {
            
            return false;}
        if (data.floor === null)
        {
            
            return false;}
        if (data.max_floor === null)
            {
                
                return false;}

        return true;
    }

    const checkType = (data) => {

       
        
        if (!isNumeric(data.bills) && data.bills !== "" && data.bills !== null){
         //   console.log("Bills ne valja", data.bills);
            return false;
        }
        if (!isNumeric(data.area)){
          //  console.log("Area ne valja", data.area);
            return false;}
        if (!isNumeric(data.deposit) && data.deposit !== "" && data.deposit !== null)
            {
         //       console.log("Deposit ne valja", data.deposit);
                return false;
            }
        if (!isNumeric(data.price) && data.price !== "")
            {
            //    console.log("Price ne valja", data.price);
                return false;
            }

        return true;
    }

    const checkLnglat = (data) => {
        
        if (data.lnglat.toString() === [44.8125, 20.4612].toString()){
            return false;
        }
        return true;
    }




    const deleteAllPhotos = () => {
        setImages([]);
        if (!addMode){
            FileAPI.removeAllImages(listingID);
        }
    };

    const deleteAllVideos = () => {
        setImages([]);
        if (!addMode){
            FileAPI.removeAllVideos(listingID);
        }
    };




    const preprocess = () => {
            setError(false);
            setSuccess(false);
            
            if (!checkMandatory(formDataListing)){
                setErrorMessage(t("error_missing_values"));
                setError(true);
                return false;
            }

            if (!checkType(formDataListing)){
                setErrorMessage(t("error_wrong_type"));
                setError(true);
                return false;
            }

            if (!checkLnglat(formDataListing)){
                setErrorMessage(t("error_lnglat"));
                setError(true);
                return false;
            }

            if (formDataListing.bills === ""){
                formDataListing.bills = null;
            }

            if (formDataListing.deposit === ""){
                formDataListing.deposit = null;
            }
    
            formDataListing.lng = formDataListing.lnglat[1];
            formDataListing.lat = formDataListing.lnglat[0];

            return true;

    }

    const handleSave = () => {

          
        if (addMode){
         setShowConfirmDialog(true);
        }
        else{
            setIsUploading(true);
            var ret = preprocess();
            if (!ret){
                setIsUploading(false);
                return;
            }

            ListingAPI.editListing(listingID, formDataListing).then((res) => {
                //Saljemo slike
                var combined = [];
                images.forEach(element => {
                    combined.push({'type' : 'image', 'data' : element});
                });
                videos.forEach(element => {
                    combined.push({'type' : 'video', 'data' : element});
                });
                console.log(combined);
                Promise.allSettled( 
                    
                    combined.map(el => {
                    var formdata = new FormData();
                    if (el.type === 'image'){
                        formdata.append("photo", el.data.file);
                        return FileAPI.postImage(listingID, formdata);
                    }
                    else if (el.type ==='video'){
                        formdata.append("video", el.data.file);
                        return FileAPI.postVideo(listingID, formdata);

                    }
                    

                })
                ).then((res) => {
                //Proveriti da li je doslo do greske
                var photoError = false;
                res.forEach(r => {
                    if (r.status !== 'fulfilled')
                        photoError = true;
                })
                if (photoError){
                    setErrorMessage(t("photo_error"));
                    setError(true);
                }
                else{
                    setSuccess(true);
                }
                setIsUploading(false);
            })

            }).catch((err) => {
                setErrorMessage(t("unspecified_edit_error"));
                setError(true);
                setIsUploading(false);
            });

        }
    }

    const confirmPublish = () => {

            setIsUploading(true);
            var ret = preprocess();
            if (!ret){
                setIsUploading(false);
                return;
            }
       
            ListingAPI.postListing(formDataListing).then((res) => {
                    var id = res.data.id;
                //Saljemo slike
                var combined = [];
                images.forEach(element => {
                    combined.push({'type' : 'image', 'data' : element});
                });
                videos.forEach(element => {
                    combined.push({'type' : 'video', 'data' : element});
                });
                Promise.allSettled( 
                    
                    combined.map(el => {
                    var formdata = new FormData();
                    if (el.type === 'image'){
                        formdata.append("photo", el.data.file);
                        return FileAPI.postImage(res.data.id, formdata);
                    }
                    else if (el.type ==='video'){
                        formdata.append("video", el.data.file);
                        return FileAPI.postVideo(id, formdata);

                    }
                    

                })
                ).then((res) => {
                    //Proveriti da li je doslo do greske

                


                    var photoError = false;
                    res.forEach(r => {
                        if (r.status !== 'fulfilled')
                            photoError = true;
                    })
                    navigate("/postavljen-novi", {
                        state : {
                            listing : id,
                            photoError : photoError
                        }
                    });
                })

               

            }).catch((err) => {
                
                if (err.response.status === 402){
                    setErrorMessage(t("low_funds"));
                    setError(true);
                    setIsUploading(false);
                     
                 }else{
                    setErrorMessage(t("unspecified_insert_error"));
                    setError(true);
                    setIsUploading(false);
                    }
           
            });
         
        
    }

    return(
        <div className='new-listing-data'>
            <div className='listing-general-info new-listing-section'>
                <BasicInfo currentUserType={currentUserType}  handleChangeListing={handleChangeListing} formDataListing={formDataListing} 
    handleChangeNumber={handleChangeNumber} selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} selectedSubplace={selectedSubplace} setSelectedSubplace={setSelectedSubplace}
     selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} updateFormDataListing={updateFormDataListing} setMapFocus={setMapFocus}></BasicInfo>
                <div className='new-listing-map-wrap '>
                    <p className='listing-general-title'>{t("select_location")}</p>
                </div>

                <div  height="600px">
                <div className='flex row a-center jc-sb'>
                      <div className='new-listing-page-map-wrap'>
                <Map position={formDataListing.lnglat} updatePosition={updatePosition} focus={mapFocus}></Map>
                </div>
                </div>
                </div>

                <div className='new-listing-map-wrap '>
                    <p className='listing-general-title'>{t("photos")}</p>
                </div>

                <ImageUpload
                images={images}
                onChangeImage={onChangeImage}
                uploadedImages={uploadedImages} 
                setUploadedImages={setUploadedImages}
                deleteAllPhotos={deleteAllPhotos}
                />

              
       
                <VideoUpload
                videos={videos}
                onChangeVideo={onChangeVideo}
                uploadedVideos={uploadedVideos} 
                setUploadedVideos={setUploadedVideos}
                deleteAllVideos={deleteAllVideos}
                />        
      

    

                <div className='new-listing-basic-data new-listing-section'>
                   <AdditionalData formDataListing={formDataListing} handleChangeNumber={handleChangeNumber} handleAutocomplete={handleAutocomplete} handleChangeType={handleChangeType} handleChangeState={handleChangeState} heating={heating} heatingChange={heatingChange}></AdditionalData>
                    
                   <CheckboxFields formDataListing={formDataListing} handleChangeCheckbox={handleChangeCheckbox} ></CheckboxFields>
                    <div className='new-listing-item new-listing-section'>
                        <p className='listing-general-title'>{t("description")}</p>
                        <textarea 
                        name="description"
                        value={formDataListing.description}
                        onChange={handleChangeListing}
                        rows="10" cols="100" placeholder={t("description_placeholder")}></textarea>
                    </div>

                    <div className='new-listing-item new-listing-section'>
                        <p className='listing-general-title'>{t("contact")}</p>
                        <div className='new-listing-contact-info'>
                           <ContactInfo formDataListing={formDataListing} handleChangeListing={handleChangeListing}></ContactInfo>
                       
                        
                        </div>
                    </div>
                </div>
            </div>

           
           
            { error &&  <Alert severity="error">{errorMessage}</Alert>}
            
            { success &&   <Alert severity="success">{t('edit_success')}</Alert>}                  
           
             { isUploading?         
                    <CircularProgress />

                    :
                    
                    <Button 
                    text={t("save")} 
                    color='secondary' 
                     onClick={handleSave}
                    icon={<KeyboardArrowRight/>}
                    // className={`back-button ${hasBackButton && 'visible'}`}
                    />
                }

        <ConfirmDialog open={showConfirmDialog} setOpen={setShowConfirmDialog} onConfirm={confirmPublish} title={t("attention", {'ns' : 'common'})} message={`${t("confirm_publish_1")}${price}${t("confirm_publish_2")}`}></ConfirmDialog>
        </div>
    )
}
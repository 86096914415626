import React from 'react';


export const IconText = ({icon, text, className, onClick}) => {
    return (
        <div className={`icon-text flex row a-center jc-start ${className} ${onClick && 'clickable'}`} onClick={()=>onClick()}>
            <span className='icon-text-icon'>{icon && icon}</span>
            <p>{text}</p>
        </div>
    )
}
import axios from 'axios';

import { userEventEmitter } from '../eventEmitter';
import config from '../config';
const baseURL = `${config.api.host}:${config.api.port}/api/`;




const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});

axiosInstance.interceptors.request.use(

	config => {
		config.headers['Authorization'] = localStorage.getItem('access_token')
		? 'Bearer ' + localStorage.getItem('access_token')
		: null;
			return config;
		},
		error => {
			return Promise.reject(error);
		}

);

axiosInstance.interceptors.response.use(response => {
    return response;
}, async error => {
    
    const originalRequest = error.config;

    // Utility function to handle user logout
    const handleLogout = () => {
		
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        userEventEmitter.emit('logout');
        window.location.href = '/login/';
    }

    if (typeof error.response === 'undefined') {
        // Handle network error here if needed
        return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === baseURL + 'auth/token/refresh/') {
        handleLogout();
        return Promise.reject(error);
    }

    if (error.response.data.code === 'token_not_valid' && 
        error.response.status === 401 
    ) {
        
        const refreshToken = localStorage.getItem('refresh_token');
        
        if (refreshToken && refreshToken !== "undefined") {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
            const now = Math.ceil(Date.now() / 1000);

            if (tokenParts.exp > now) {
                return axiosInstance
                    .post('/auth/token/refresh/', { refresh: refreshToken })
                    .then(response => {
                        localStorage.setItem('access_token', response.data.access);
                        axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                        originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

                        return axiosInstance(originalRequest);
                    })
                    .catch(err => {
                        handleLogout();
                    });
            } else {
                console.log('Refresh token is expired', tokenParts.exp, now);
                handleLogout();
            }
        } else {
            console.log('Refresh token not available.');
            handleLogout();
        }
    }

    return Promise.reject(error);
});

export default axiosInstance;
import React from "react";
import { useTranslation } from "react-i18next";
import InputField from "../../../../components/shared-components/form/input-field/input-field";

const ContactInfo = ({ formDataListing, handleChangeListing }) => {
    const { t } = useTranslation(["listing", "common", "profile"]);
    
    return (
        <div className='new-listing-contact-info-wrap'>
            <div className='new-listing-item-box'>
                <p>{t("mail", { ns: "profile" }) + " *"}</p>
                <InputField 
                    placeholder={t("mail_placeholder", { ns: "profile" })}
                    name="email"
                    handleChange={handleChangeListing}
                    value={formDataListing.email}
                />
            </div>
            <div className='new-listing-item-box'>
                <p>{t("phone", { ns: "profile" }) + " *"}</p>
                <InputField 
                    placeholder={t("phone_placeholder", { ns: "profile" })}
                    name="phone"
                    handleChange={handleChangeListing}
                    value={formDataListing.phone}
                />
            </div>
            <div className='new-listing-item-box'>
                <p>{t("mobile", { ns: "profile" }) + " *"}</p>
                <InputField 
                    placeholder={t("mobile_placeholder", { ns: "profile" })}
                    name="mobile"
                    handleChange={handleChangeListing}
                    value={formDataListing.mobile}
                />
            </div>
        </div>
    );
}

export default React.memo(ContactInfo);

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InfoRounded, ThumbUp, ReportProblem, ConnectedTvOutlined } from '@mui/icons-material';
import { ListingOverviewItem } from '../../components/listing-overview-item/listing-overview-item';
import Button from '../../components/shared-components/buttons/button/button';
import InfoBox from '../../components/shared-components/layout/info-box/info-box';
import ListingAPI from '../../axios/listing';
import { useTranslation } from "react-i18next";

export const NewListingSuccess = ({ className }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation(["listing"]);

    const [listings, setListings] = useState([]);
    const [listingID, setListingID] = useState(0);
    const [photoError, setPhotoError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.state) {
            const id = location.state.listing;
            setPhotoError(location.state.photoError);

            if (id) {
                setListingID(id);
                fetchListingCard(id);
            } else {
                navigate("/", {});
            }
        } else {
            navigate("/", {});
        }
    }, []);

    const fetchListingCard = (id) => {
        ListingAPI.getListingCard(id, t('language')).then(response => {
            setListings([response.data]);
        }).catch(e => { console.log(e); });
    }

    useEffect(() => {
        fetchListingCard(listingID);
    }, [t]);

    return (
        <div className={`page-wrap new-listing-success-wrap flex column a-center jc-center ${className}`}>
            <div className='new-listing-success-message flex column a-center jc-center '>
                <p className='blue bold'>{t('success_title')}</p>
                <p className='blue'><ThumbUp/></p>
                <p>
                    <Link to='/'>
                        <span className='blue bold'>halostan.rs  </span> 
                    </Link>
                    {t('success_subtitle_1')} 
                    <span className='bold'>  {t('success_subtitle_2')} </span>
                </p>
            </div>
           
            <div className='new-listing-content'>
                <p>{t('success_preview')}</p>
                {listings.map((listing, index) => (
                    <ListingOverviewItem key={`listing-${index}`} listing={listing} activeBottomPanel={false} listingFollow={false} />
                ))}

                {photoError && <InfoBox announcement={true} color="red-text" info={t('success_photo_error_1')} icon={<ReportProblem />} link={t('success_photo_error_2')} linkTo={`/postavi-oglas/azuriranje/${listingID}`} />}
                <InfoBox info={t('success_info_1_part_1')} icon={<InfoRounded />} link={t('success_info_1_part_2')} />
                <InfoBox info={t('success_info_2_part_1')} icon={<InfoRounded />} link={t('success_info_2_part_2')} />
                <InfoBox announcement={true} info={t('success_info_3_part_1')} icon={<InfoRounded />} link={t('success_info_3_part_2')} />
            </div>
            <div className='new-listing-success-button-wrap flex row a-end jc-end'>
                <Link to='/korisnik/oglasi'>
                    <Button className='extra-wide-button' color='secondary' text={t('success_all_listings')} />
                </Link>
            </div>
        </div>
    );
}



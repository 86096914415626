import React, { memo } from 'react';

const InputField = ({
  name,
  type = "text",
  placeholder = "",
  label,
  position = "column",
  icon,
  handleChange,
  value,
  width,
  additionalInformation,
  maxlength = null,
}) => {
  const wrapperStyle = width ? { width } : {};

  return (
    <div className={`input-field-wrapper ${position}`} style={wrapperStyle}>
      {label && <label>{label}</label>}
      <div className='input-field-icon'>
        {icon && <p>{icon}</p>}
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxlength}
          value={value || ""} 
        />
        {additionalInformation && (
          <div className='additional-info'>{additionalInformation}</div>
        )}
      </div>
    </div>
  );
};

export default memo(InputField);

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../contexts/userContext";
import { useTranslation } from "react-i18next";
import UserAPI from '../../axios/user';

export const Logout = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const { t } = useTranslation(["navigation"]);

    const handleLogout = async (e) => {
        e.preventDefault();

        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            // Direct the user to the login page if the refresh token isn't available.
            navigate('/login');
            return;
        }

        try {
            await UserAPI.logout({ refresh_token: refreshToken });

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            setUser(null);
            navigate('/login');
        } catch (err) {
            console.error("Error during logout:", err);
            // Here you might also want to notify the user about the error.
        }
    };

    return <li onClick={handleLogout}>{t('logout')}</li>;
}
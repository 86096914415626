import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Logo } from '../../../../components/shared-components/logo/logo';


export const OfferItem = ({selected, title, onClick}) => {

    return(
        <Stack onClick={onClick} direction={'row'} alignItems={'center'} justifyContent={'space-between'} 
            sx={{border: "1px solid gray", 
                padding: "10px 20px",
                borderRadius: '5px', 
                minWidth: "280px",
                cursor: 'pointer',
                "&:hover": {opacity: '0.8'},
                backgroundColor: selected ? 'orange' : 'white',
                color: selected ? 'white' : 'orange',
                }} >
                    <div style={{borderRadius:"50%", width:'50px', height:'50px', overflow:'hidden', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                        <Logo/>
                    </div>
                
                <Typography sx={{fontWeight:'bold', color: selected ? "white":"orange"}}>{title}</Typography>
        </Stack>
    )
}
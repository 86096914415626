import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const InfoBox = ({ info, link, linkTo, icon, announcement = false, color = 'black-text' }) => {
    const { t } = useTranslation(["common"]);

    const content = useMemo(() => {
        let announcementText = announcement ? <span className={`bold ${color}`}>{t('important')}: </span> : null;
        let linkElement = linkTo ? <Link to={linkTo}><span>{link}</span></Link> : link ? <span>{link}</span> : null;

        return (
            <>
                {announcementText}
                {info}
                {linkElement}
            </>
        );
    }, [announcement, color, link, linkTo, info, t]);

    return (
        <div className='info-box-wrapper'>
            {icon && icon}
            <p>{content}</p>
        </div>
    );
};

export default React.memo(InfoBox);
import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { UserMessageList } from '../user-message-list/user-message-list';
import { BlockedUsers } from '../blocked-users/blocked-users';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const UserInbox = () => {
    const { t } = useTranslation(["message"]);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='user-inbox-wrap'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="user-inbox">
                        <Tab label={t('received_messages')} {...a11yProps(0)} />
                        <Tab label={t('sent_messages')} {...a11yProps(1)} />
                        <Tab label={t('blocked_users')} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <UserMessageList type="received_messages" />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <UserMessageList type="sent_messages" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <BlockedUsers />
                </TabPanel>
            </Box>
        </div>
    )
}



import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from "react-i18next";
import NotificationAPI from '../../../../axios/notification';
import Alert from '@mui/material/Alert';
import { Pagination } from '@mui/material';
import UserNotification from '../user-notification/user-notification';
import i18n from "i18next";

export const UserNotificationList = () => {
    const { t } = useTranslation(["message"]);
    const [notifications, setNotifications] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pageNum, setPageNum] = useState(1);

    const fetchNotifications = async (page) => {
        try {
            const response = await NotificationAPI.getNotifications(page, i18n.language);
            setNotifications(response.data.results);
            setPageCount(response.data.page_count);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    useEffect(() => {
        fetchNotifications(pageNum);
    }, [pageNum, t]);

    const handleChange = (event, value) => {
        setPageNum(value);
    };

    return (
        <PerfectScrollbar className="full-width">
            <h3>{t('notification_title')}</h3>
            {notifications.map(notification => (
                <UserNotification notification={notification} key={`user-notification-${notification.id}`} />
            ))}
            {notifications.length === 0 && <Alert severity="info">{t("no_notifications")}</Alert>}
            {notifications.length > 0 && <Pagination count={pageCount} page={pageNum} onChange={handleChange} />}
        </PerfectScrollbar>
    );
};

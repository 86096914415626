import React, { useContext, useState, useEffect } from 'react';
import  CheckBoxField  from '../../form/check-box-field/check-box-field';
import  CheckboxesDropdown  from '../checkboxes-dropdown/checkboxes-dropdown';
import  MainSearchBox  from '../main-search-box/main-search-box';
import MiscAPI from '../../../../axios/misc';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { ConfigContext } from '../../../../contexts/config-context';
import { useNavigate } from 'react-router-dom';
import  TreeMultiselect  from '../../form/tree-multiselect/tree-multiselect';
import LocationAPI from '../../../../axios/locations';
import { InterfaceContext } from '../../../../contexts/interface-context';

export const MainFilterMenu = () => {

    const [userTypes, setUserTypes] = useState([]);
    const [heatingOptions, setHeatingOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const { filterPhotoOptions, checkboxItems, checkboxItemsRestData, searchData } = useContext(ConfigContext);
    const navigate = useNavigate();
    const [subplaces, setSubplaces] = useState([]);
    const { t } = useTranslation(["search", "listing"]);

    const fetchData = async () => {
        try {
            const userTypeResponse = await MiscAPI.getUserTypeOptions(i18n.language);
            setUserTypes(userTypeResponse.data);
            
            const heatingResponse = await MiscAPI.getHeatingOptions(i18n.language);
            setHeatingOptions(heatingResponse.data);
            
            const propertyTypeResponse = await MiscAPI.getPropertyTypeOptions(i18n.language);
            setTypeOptions(propertyTypeResponse.data);
            
            const propertyStateResponse = await MiscAPI.getPropertyStateOptions(i18n.language);
            setStateOptions(propertyStateResponse.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [t]);
    

    

    const handleChangeCheckbox = (e) => {
        
        var checked = e.target.checked;
        var name = e.target.name;
        var field = e.target.getAttribute('data-field');
        
        if (checked){
            var temp = formSearchData[field];
            temp.push(name);
            updateFormSearchData({
                ...formSearchData,
                [field]: temp,
            });

        }
        else{
            
            var temp = formSearchData[field];
            var index = temp.indexOf(name);
            temp.splice(index, 1);
            updateFormSearchData({
                ...formSearchData,
                [field]: temp,
            });

        }
       
      };

    const isChecked = (field, name) => {
        const found = formSearchData[field].find(element => element == name);
        if (found !== undefined) {
            return true;
        }
        else{
            return false;
        }
    }

    const [formSearchData, updateFormSearchData] = useState(searchData);

    useEffect(() => { 

        if(formSearchData.place){
        LocationAPI.getTreeLocations(formSearchData.place.id)
        .then(function (response) {
            setSubplaces(response.data);
          });

       
        
        }

       
          
    },[formSearchData.place]);


    const handleSearch = () => {
        sessionStorage.removeItem('currentPage');
        var link = ""; 
        switch(formSearchData.listing_type) {
            case '1':
              link = "/prodaja";
              break;
            case '2':
                link = "/izdavanje";
              break;
            case '3':
                link = "/stan-na-dan";
              break;
            default:
              
          } 
       
        navigate(link, {
            state : {
                data : formSearchData
            }
        });
       
      }

      const onChangeLocation = (checked) => {
     
        updateFormSearchData({
			...formSearchData,
			"subplaces": checked,
		});
      };
   
    return (
        <div className='main-filter-menu-wrap'>
             <MainSearchBox formSearchData={formSearchData} updateFormSearchData={updateFormSearchData} handleSearch={handleSearch}/>

            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-1`} 
                    title={t('city_part')}>
                    <TreeMultiselect options={subplaces} value={formSearchData.subplaces} onChange={onChangeLocation}/>
            </CheckboxesDropdown>

            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-2`} 
                    title={t('publisher')}>
                    {userTypes.map((op, index)=>(
                        <CheckBoxField 
                            field="publisher"
                            name={op.id}
                            key={`checkbox-main-filter-checkbox-publisher-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={op.label} 
                            value={isChecked("publisher", op.id)}/>
                    ))}
            </CheckboxesDropdown>


            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-3`} 
                    title={t('heating')}>
                    {heatingOptions.map((op, index)=>(
                            <CheckBoxField 
                            field="heating"
                            name={op.value}
                            key={`checkbox-main-filter-checkbox-heating-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={op.name} 
                            value={isChecked("heating", op.value)}/>
                    ))}
            </CheckboxesDropdown>


            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-4`} 
                    title={t('property_state')}>
                    {stateOptions.map((op, index)=>(
                        <CheckBoxField 
                            field="property_state"
                             name={op.id}
                            key={`checkbox-main-filter-checkbox-property_state-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={op.label} 
                            value={isChecked("property_state", op.id)}/>
                    ))}
            </CheckboxesDropdown>


            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-5`} 
                    title={t('property_type')}>
                    {typeOptions.map((op, index)=>(
                        <CheckBoxField 
                            field="property_type"
                            name={op.id}
                            key={`checkbox-main-filter-checkbox-property_type-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={op.label} 
                            value={isChecked("property_type", op.id)}/>
                    ))}
            </CheckboxesDropdown>

            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-6`} 
                    title={t('photo_section')}>
                    {filterPhotoOptions.options.map((op, index)=>(
                        <CheckBoxField 
                        field="photo"
                        name={op.value}
                            key={`checkbox-main-filter-checkbox-photo-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={t(op.name)} 
                            value={isChecked("photo", op.value)}/>
                    ))}
            </CheckboxesDropdown>

            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-7`} 
                    title={t('additional')}>
                    {checkboxItems.map((op, index)=>(
                        <CheckBoxField 
                            field="additional"
                            name={op.name}
                            key={`checkbox-main-filter-checkbox-additional-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={t(op.name, {"ns" : "listing"})} 
                            value={isChecked("additional", op.name)}/>
                    ))}
            </CheckboxesDropdown>

            <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-8`} 
                    title={t('rest')}>
                    {checkboxItemsRestData.map((op, index)=>(
                        <CheckBoxField 
                        field="rest"
                        name={op.name}
                            key={`checkbox-main-filter-checkbox-rest-${index}`} 
                            direction="row" 
                            onChange={handleChangeCheckbox}
                            text={t(op.name, {"ns" : "listing"})} 
                            value={isChecked("rest", op.name)}/>
                    ))}
            </CheckboxesDropdown>
            
            
            {/* {checkBoxOptions.map((option, index) => (
                <CheckboxesDropdown 
                    key={`checkbox-main-filter-option-${index}`} 
                    title={option.title}>
                    {option.options.map((op, index)=>(
                        <CheckBoxField 
                            key={`checkbox-main-filter-checkbox-${index}`} 
                            direction="row" 
                            text={op.name} 
                            value={op.value}/>
                    ))}
                </CheckboxesDropdown>
            ))}
                    */}
           
        </div>
    )
}
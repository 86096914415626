import React, { useContext, useState } from "react";
import { ConfigContext } from '../../../../contexts/config-context';
import ImageUploading from 'react-images-uploading';
import { useTranslation } from "react-i18next";
import FileAPI from '../../../../axios/file';
import ConfirmDialog from '../../../../components/shared-components/form/confirm-dialog/confirm-dialog';



const ImageUpload = ({ images, onChangeImage, uploadedImages, setUploadedImages, deleteAllPhotos }) => {
    const { t } = useTranslation(["listing"]);
    const maxNumber = 20;
    const { api } = useContext(ConfigContext);
    const baseURL = api.host + ":" + api.port; 
    const [openConfirmPhotoDelete, setOpenConfirmPhotoDelete] = useState(false);

    const onExistingImageRemove = id => {
        FileAPI.removeImage(id).then(() => {
            setUploadedImages(current => current.filter(img => img.id !== id));
        });
    }

    const myOnImageRemoveAll = () => setOpenConfirmPhotoDelete(true);

    return (
        <div>
            <ImageUploading
                multiple
                value={images}
                onChange={onChangeImage}
                maxNumber={maxNumber}
                dataURLKey="photo"
                acceptType={['jpg', 'png']}
            >
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                    <div className="upload__image-wrapper">
                        <button
                            className={isDragging ? 'dragging' : ''} // Add relevant styles to the 'dragging' class
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            {t("choose_photos")}
                        </button>
                        &nbsp;
                        <button onClick={myOnImageRemoveAll}>{t("delete_all_photos")}</button>
                        {uploadedImages.map(image => (
                            <div key={image.id} className="image-item">
                                <img src={`${baseURL}${image['photo']}`} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                    <button onClick={() => onExistingImageRemove(image['id'])}>{t("delete_photo")}</button>
                                </div>
                            </div>
                        ))}
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image['photo']} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageRemove(index)}>{t("delete_photo")}</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>
            <ConfirmDialog 
                message={t("all_photos_delete")} 
                open={openConfirmPhotoDelete} 
                setOpen={setOpenConfirmPhotoDelete} 
                onConfirm={deleteAllPhotos} 
            />
        </div>
    )
}

export default ImageUpload;

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { Pagination, Alert } from '@mui/material';
import i18n from "i18next";
import  SelectField  from '../../components/shared-components/form/select-field/select-field';
import {ListingOverviewItem} from '../../components/listing-overview-item/listing-overview-item';
import ListingAPI from '../../axios/listing';
import { ConfigContext } from '../../contexts/config-context';

export const ListingsListPage = ({ title, editBar, source, listingFollow = false, inactiveListing, className }) => {
    const { filterOptions } = useContext(ConfigContext);
    const { t } = useTranslation(["listing"]);
    const location = useLocation();
    

   

    const [listingCardData, setListingCardData] = useState([]);
    const [pageNum, setPageNum] = useState(() => {
        return sessionStorage.getItem('currentPage') || 1;
      });
    const [pageCount, setPageCount] = useState(1);
    const [numOfListings, setNumOfListings] = useState(0);
    const [sortingOption, setSortingOption] = useState("-date_created");
    const [promotionPriceAndDuration, setPromotionPriceAndDuration] = useState({});

    useEffect(() => {
        fetchData();
        // Fetch promotion price
        ListingAPI.getPromotionPrice(2).then(res => setPromotionPriceAndDuration(res.data)).catch(console.error);
    }, [pageNum, sortingOption, t]);

    const fetchData = async () => {
        let requestFunction;
        const lang = i18n.language;
        const data = location.state?.data || { listing_type: title === 'sale' ? 1 : title === 'rent' ? 2 : 3 };
        sessionStorage.setItem('currentPage', pageNum);
        switch (source) {
            case "active": requestFunction = ListingAPI.getActiveListings; break;
            case "inactive": requestFunction = ListingAPI.getInactiveListings; break;
            case "search": requestFunction = () => ListingAPI.getListingsSearch(pageNum, data, sortingOption, lang); break;
            case "favorite": requestFunction = ListingAPI.getFavoriteListings; break;
            default: return;
        }

        try {
            const response = await requestFunction(pageNum, sortingOption, lang);
            setListingCardData(response.data.results);
            setNumOfListings(response.data.count);
            setPageCount(response.data.page_count);
        } catch (error) {
            console.error("Error fetching listings:", error);
        }
    };

    useEffect(() => {
        fetchData();
        // Fetch promotion price
        ListingAPI.getPromotionPrice(2).then(res => setPromotionPriceAndDuration(res.data)).catch(console.error);
    }, [pageNum, sortingOption, t]);

    return (
        <div className={`listing-list-page-wrapper flex column jc-center a-start ${className}`}>
            <h2>{t(title)}</h2>
            <div className="listing-list-page-content-header flex row a-center jc-sb">
                <p>{t('num_found')}<span className='orange'>{numOfListings}</span></p>
                <SelectField className='green-select' value={sortingOption} handleChange={(e) => setSortingOption(e.target.value)} options={filterOptions} placeholder="Filter" translate_namespace={'common'} />
            </div>
            <div className='listings-list-pagination-wrap flex a-center column'>
                <div className={`listings-list-wrap ${editBar && 'background-gray'}`}>
                    {listingCardData.map((listing, index) => (
                        <ListingOverviewItem promotionPriceAndDuration={promotionPriceAndDuration} listing={listing} key={`listing-${index}`} inactiveListing={inactiveListing} activeBottomPanel={editBar} listingFollow={listingFollow} />
                    ))}
                </div>
                {numOfListings === 0 && <Alert severity="info">{t("no_results")}</Alert>}
                {numOfListings > 0 && <Pagination count={pageCount} page={pageNum} onChange={(event, pageNumber) => setPageNum(pageNumber)} />}
            </div>
        </div>
    );
};

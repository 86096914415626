import React, { useState, useEffect, useCallback, useMemo } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@mui/material';

const TreeMultiselect = ({ className = 'row', options, value, onChange }) => {
  const { t } = useTranslation(["search"]);
  
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState(options);

  useEffect(() => {
    const filterNodes = (nodes) => {
      return nodes.reduce((filtered, node) => {
        const children = (node.children || []).reduce(filterNodes, []);
        if (
          node.label.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) ||
          children.length
        ) {
          filtered.push({ ...node, children });
        }
        return filtered;
      }, []);
    };

    if (filterText) {
      setNodesFiltered(filterNodes(options));
    } else {
      setNodesFiltered(options);
    }
  }, [filterText, options]);

  const onFilterChange = useCallback((e) => {
    setFilterText(e.target.value);
  }, []);

  return (
    <div>
      <div className="router-search">
        <span>
          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
          <Input
            sx={{ border: '1px solid #bebebe', borderRadius: "5px", padding: "2px" }}
            type="text"
            className="search"
            placeholder={t('filter')}
            onChange={onFilterChange}
          />
        </span>
      </div>
      <div>
        <CheckboxTree
          nodes={nodesFiltered}
          checked={value}
          expanded={expanded}
          onCheck={onChange}
          onExpand={setExpanded}
          showNodeIcon={false}
          checkModel="all"
        />
      </div>
    </div>
  );
};

export default React.memo(TreeMultiselect);

import axiosInstance from './axios';

const AdvertisementAPI = {

    getAds : () => {
        return axiosInstance.get('advertisements', { });
    },
   


}

export default AdvertisementAPI;
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Navigation } from '../../navigation/navigation';
import Button from '../buttons/button/button';
import { ProfileNavigation } from '../../profile-navigation/profile-navigation';
import { Logo } from '../logo/logo';
import { ConfigContext } from '../../../contexts/config-context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FilterList, KeyboardArrowLeft, Menu, MenuBook } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { InterfaceContext } from '../../../contexts/interface-context';
import { ResponsiveNavigation } from '../../responsive-navigation/responsive-navigation';

const MainHeader = React.memo(({ shadow }) => {
    const { i18n, t } = useTranslation(["common"]);
    const { navItems } = useContext(ConfigContext);
    const {
        responsiveMenuVisible,
        openResponsiveMenu,
        filterMenuVisible,
        openFilterMenu
    } = useContext(InterfaceContext);

    const headerItems = useMemo(() => navItems.filter(item => item.header), [navItems]);
    const location = useLocation();
    const navigate = useNavigate();
    const [selected, setSelected] = useState(() => i18n.language === "sr" ? "RS" : "GB");

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("sr");
        }

        
    }, []);

    const handleLanguageChange = (val) => {
        setSelected(val);
        if (val === "GB")
            i18n.changeLanguage("en");
        else if (val === "RS")
            i18n.changeLanguage('sr');
    };
    
    const hasBackButton = location.pathname !== '/';

    return (
        <div className={`main-header-wrapper ${shadow && 'shadow'}`}>
            {!responsiveMenuVisible && <div className='header-nav-bar'>
                <Button
                    text='Nazad'
                    color='secondary'
                    onClick={() => navigate(-1)}
                    icon={<KeyboardArrowLeft />}
                    className={`back-button ${hasBackButton && 'visible'}`}
                />
                <Navigation
                    navItems={headerItems}
                    direction='row' />
            </div>}
            <div className='header-logo-nav-bar'>
                {filterMenuVisible && <FilterList sx={{ cursor: 'pointer', display: `${filterMenuVisible ? 'block' : 'none'}` }} onClick={openFilterMenu} />}
                <Logo />

                {!responsiveMenuVisible && <div className={`regular-menu ${responsiveMenuVisible && 'not-visible'}`}>
                    <Link to={'postavi-oglas'}>
                        <Button text='Postavi oglas' color='secondary' onClick={() => { navigate('/postavi-oglas') }} />
                    </Link>
                    <ReactFlagsSelect
                        selected={selected}
                        countries={["RS", "GB"]}
                        customLabels={{ GB: "English", RS: "Srpski" }}
                        onSelect={(code) => handleLanguageChange(code)}
                    />
                    <ProfileNavigation direction='row' />
                </div>}
                {responsiveMenuVisible && <Menu sx={{ cursor: 'pointer', display: `${responsiveMenuVisible ? 'block' : 'none'}` }} onClick={openResponsiveMenu} />}
            </div>
        </div>
    );
});

export { MainHeader };

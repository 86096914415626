import React, { useContext, useState, useCallback } from "react";
import { ConfigContext } from '../../../../contexts/config-context';
import ImageUploading from 'react-images-uploading';
import { useTranslation } from "react-i18next";
import FileAPI from '../../../../axios/file';
import ConfirmDialog from '../../../../components/shared-components/form/confirm-dialog/confirm-dialog';

export const VideoUpload = ({ videos, onChangeVideo, uploadedVideos, setUploadedVideos, deleteAllVideos }) => {
    const { t } = useTranslation(["listing"]);
    const maxNumber = 20;
    const { api } = useContext(ConfigContext);
    const [openConfirmVideoDelete, setOpenConfirmVideoDelete] = useState(false);

    const myOnVideoRemoveAll = useCallback(() => {
        setOpenConfirmVideoDelete(true);
    }, []);

    const onExistingVideoRemove = useCallback((id) => {
        FileAPI.removeVideo(id).then((res) => {
            setUploadedVideos((current) => current.filter((vid) => vid.id !== id));
        });
    }, [setUploadedVideos]);

    return (
        <div>
            <ImageUploading
                multiple
                value={videos}
                onChange={onChangeVideo}
                maxNumber={maxNumber}
                dataURLKey="video"
                acceptType={['mp4']}
                allowNonImageType={true}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div className="upload__image-wrapper">
                        <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            {t("choose_videos")}
                        </button>
                        &nbsp;
                        <button onClick={myOnVideoRemoveAll}>{t("delete_all_videos")}</button>
                        {uploadedVideos.map((image, index) => (
                            <VideoItem
                                key={index}
                                source={api.host + ":" + api.port + image['video']}
                                onDelete={() => onExistingVideoRemove(image['id'])}
                            />
                        ))}
                        {imageList.map((image, index) => (
                            <VideoItem
                                key={index}
                                source={image['video']}
                                onDelete={() => onImageRemove(index)}
                            />
                        ))}
                    </div>
                )}
            </ImageUploading>
            <ConfirmDialog
                message={t("all_videos_delete")}
                open={openConfirmVideoDelete}
                setOpen={setOpenConfirmVideoDelete}
                onConfirm={deleteAllVideos}
            />
        </div>
    );
}

const VideoItem = React.memo(({ source, onDelete }) => {
    const { t } = useTranslation(["listing"]);
    return (
        <div className="image-item">
            <video controls width="250">
                <source src={source} type="video/mp4" />
            </video>
            <div className="image-item__btn-wrapper">
                <button onClick={onDelete}>{t("delete_video")}</button>
            </div>
        </div>
    );
});

import React, { useState, useEffect, useCallback } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";
import ListingAPI from '../../axios/listing';
import ListingCard from "../../components/listing-card/listing-card";

export const PromotedListings = () => {
    const [pageNum, setPageNum] = useState(1);
    const [listingCardData, setListingCardData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const { t } = useTranslation(["common"]);

    const fetchData = useCallback(async (page) => {
        try {
            const res = await ListingAPI.getPromotedList(page, i18n.language);
            setListingCardData(res.data.results);
            setPageCount(res.data.page_count);
        } catch (error) {
            console.error("Error fetching promoted listings:", error);
        }
    }, []);

    useEffect(() => {
        let timer;
        if (pageNum) {
            timer = setTimeout(() => {
                fetchData(pageNum);
            }, 300);
        }
        return () => clearTimeout(timer);
    }, [pageNum, fetchData, t]);

    return (
        <div className='promoted-listings-wrap'>
            <div className='promoted-listings'>
                {listingCardData.map((listing, index) => (
                    <ListingCard listingCardData={listing} key={`listing-${index}`} />
                ))}
            </div>
            {listingCardData.length >= pageCount && 
                <Pagination
                    count={pageCount} 
                    page={pageNum} 
                    onChange={(event, pageNumber) => setPageNum(pageNumber)}
                />
            }
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { 
    useTheme, Box, Table, TableBody, TableCell, 
    TableContainer, TableFooter, TablePagination, TableRow, 
    Paper, IconButton, TableHead 
} from '@mui/material';
import {
    FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight,
    LastPage as LastPageIcon 
} from '@mui/icons-material';

import AccountAPI from '../../axios/account';

const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
    const handleBackButtonClick = (event) => onPageChange(event, page - 1);
    const handleNextButtonClick = (event) => onPageChange(event, page + 1);
    const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
    );
};

export const TransactionPage = ({ className }) => {
    const { t } = useTranslation(["account"]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0); // Pages typically start from 0.
    const [rows, setRows] = useState([]);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        // Fetching transactions and balance
        const fetchTransactions = async () => {
            try {
                const resTransactions = await AccountAPI.getTransactions();
                setRows(resTransactions.data);

                const resBalance = await AccountAPI.getBalance();
                setBalance(resBalance.data.result);
            } catch (error) {
                // Handle error (can be enhanced later with error notifications or logging)
            }
        };
        fetchTransactions();
    }, []);

    const handlePageChange = (e, newPage) => setPage(newPage);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
      <div  className={`listing-list-page-wrapper flex column jc-center a-start ${className}`}>
      <h2>{t('page_title')}</h2>

      <div>
        {t("balance") + balance}
      </div>
      
      <TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell align="left">{t('date')}</TableCell>
      <TableCell align="left">{t('amount')}</TableCell>
      <TableCell align="left">{t('description')}</TableCell>
      
    </TableRow>
  </TableHead>
  <TableBody>
  {(rowsPerPage > 0
      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : rows
    ).map((row) => (
      <TableRow
        key={row.date}
        className={row.income ? 'rowGreen' : 'rowRed'}
      >
        
        <TableCell align="left">{new Date(row.date).toLocaleString('sr-SP')}</TableCell>
        <TableCell align="left">{row.income ? ""+ row.amount : "-" + row.amount}</TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
    ))}
  </TableBody>

  <TableFooter>
    <TableRow>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  </TableFooter>
</Table>
</TableContainer>

      </div>
    );
};

export default TransactionPage;

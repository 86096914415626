import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import './center-radar.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import ConfigContextProvider from './contexts/config-context';
import {BrowserRouter  as Router  } from 'react-router-dom';
import "./i18n";
import InterfaceContextProvider from './contexts/interface-context';
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container);

root.render(

  <Router>
    <InterfaceContextProvider>
   <ConfigContextProvider>  
     <App />
   </ConfigContextProvider>
   </InterfaceContextProvider>
   </Router>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

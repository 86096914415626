import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationAPI from '../../../../axios/notification';

export default function UserNotification({ notification }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    if (!notification.is_read) {
      NotificationAPI.readNotification(notification.id)
        .then(() => {
          notification.is_read = true;
        })
        .catch(() => {});
    }
    setExpanded(prevExpanded => !prevExpanded);
  };

  const getBoldClass = () => (notification.is_read ? "" : "bold-text");

  return (
    <Accordion expanded={expanded} onChange={handleChange} className='full-width'>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }} className={getBoldClass()}>
          {new Date(notification.date_created).toLocaleString('sr-SP')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} className={getBoldClass()}>
          {notification.notification_type.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="new_lines">
          <div dangerouslySetInnerHTML={{ __html: notification.body }} />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

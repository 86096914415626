import { KeyboardArrowDown, KeyboardArrowUp, PermIdentity } from '@mui/icons-material';
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Logout } from "../logout/logout";
import { UserContext } from "../../contexts/userContext";
import { useTranslation } from "react-i18next";
import Badge from '@mui/material/Badge';
import MessageAPI from '../../axios/message';
import NotificationAPI from '../../axios/notification';

export const ProfileNavigation = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation(["navigation"]);
    const [state, setState] = useState({
        toggleProfileMenu: false,
        numOfNotifications: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const msgResult = await MessageAPI.countUnreadMessage();
                const notifResult = await NotificationAPI.getNotificationCount();
                const total = msgResult.data.result + notifResult.data.result;
                setState(prevState => ({ ...prevState, numOfNotifications: total }));
            } catch (e) {
                console.error("Error fetching data:", e);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='navigation-wrapper'>
            <nav>
                <ul>
                    {user ? (
                        <li className='profile-navigation' onClick={() => setState(prevState => ({ ...prevState, toggleProfileMenu: !prevState.toggleProfileMenu }))}>
                            <PermIdentity />
                            <Badge badgeContent={state.numOfNotifications || "0"} color="primary" sx={{ position: 'absolute', top: "2px", right: "0" }} />
                            <Link to="/korisnik/podaci" className='profile-navigation-username'>{user.first_name + ' ' + user.last_name}</Link>
                            {state.toggleProfileMenu ? <KeyboardArrowUp className='color-b' /> : <KeyboardArrowDown className='color-b' />}
                            {state.toggleProfileMenu && (
                                <div className="profile-navigation-menu visible">
                                    <ul>
                                        <li><Link to="/korisnik/podaci">{t('my_panel')}</Link></li>
                                        <Logout />
                                    </ul>
                                </div>
                            )}
                        </li>
                    ) : (
                        <li>
                            <Link to="/login">{t('login')}</Link>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
};

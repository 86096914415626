import React from 'react';
import  Button  from '../../../../components/shared-components/buttons/button/button';
import  InputField  from '../../../../components/shared-components/form/input-field/input-field';


export const ContactSupport = () => {

    return(
        <div className='user-panel-contact-support-wrap flex column a-sb jc-sb'>
            <p className='bold user-panel-contact-support-title'>Kontaktirajte nas</p>
            <InputField type='text' label={"Primalac"} position="row" placeholder="office@halostan.rs"/>
            <InputField type='text' label={"Pošiljalac"} position="row" />
            <div className='flex row a-start jc-sb'>
                <label htmlFor="user-contact">Opis</label>
                <textarea id='user-contact' rows="20" cols="50"></textarea>
            </div>
            <div className='flex row a-center jc-end'>
                <Button color="orange-b" text='Pošalji'/>
            </div>
        </div>
    )
}
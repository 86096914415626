import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MessageAPI from '../../../../axios/message';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../../../components/shared-components/form/confirm-dialog/confirm-dialog';
import AlertDialog from '../../../../components/shared-components/form/alert-dialog/alert-dialog';

export const BlockedUsers = ({ type }) => {
    const { t } = useTranslation(["message"]);
    const [blockedUsers, setBlockedUsers] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [alertDialogTitle, setAlertDialogTitle] = useState("");
    const [alertColor, setAlertColor] = useState("");
    const [userToUnblock, setUserToUnblock] = useState("");

    useEffect(() => {
        MessageAPI.getBlockedUsers().then(res => {
            setBlockedUsers(res.data);
        }).catch(e => {
            console.log(e);
        });
    }, []);

    const handleDelete = (userId) => {
        setUserToUnblock(userId);
        setShowConfirmDialog(true);
    };

    const unblockUser = () => {
        MessageAPI.unblockUser(userToUnblock).then(() => {
            setAlertDialogTitle(t("success", { "ns": "common" }));
            setAlertDialogMessage(t("unblock_success"));
            setAlertColor("green");
            setBlockedUsers(prevUsers => prevUsers.filter(user => user.blocked.id !== userToUnblock));
            setShowAlertDialog(true);
        }).catch(() => {
            setAlertDialogTitle(t("error", { "ns": "common" }));
            setAlertDialogMessage(t("unblock_error"));
            setAlertColor("red");
            setShowAlertDialog(true);
        });
    };

    return (
        <PerfectScrollbar>
            <h3>{t("blocked_users")}</h3>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {blockedUsers.map(user => (
                    <ListItem alignItems="flex-start" key={user.blocked.id}>
                        <ListItemText primary={user.blocked.first_name + ' ' + user.blocked.last_name} />
                        <IconButton onClick={() => handleDelete(user.blocked.id)} aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <ConfirmDialog 
                open={showConfirmDialog} 
                setOpen={setShowConfirmDialog} 
                onConfirm={unblockUser} 
                title={t("block_confirm_title")} 
                message={t("unblock_confirm_message")} 
            />
            <AlertDialog 
                open={showAlertDialog} 
                setOpen={setShowAlertDialog} 
                title_color={alertColor} 
                title={alertDialogTitle} 
                message={alertDialogMessage} 
            />
        </PerfectScrollbar>
    );
};



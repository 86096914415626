import React, { useContext, useState, useEffect, useRef } from 'react';
import  InputField  from '../../../../components/shared-components/form/input-field/input-field';
import  SelectField  from '../../../../components/shared-components/form/select-field/select-field';
import { ConfigContext } from '../../../../contexts/config-context';
import { EditButtonsWrap } from '../../../../components/shared-components/buttons/edit-buttons-wrap/edit-buttons-wrap';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ImageUploading from 'react-images-uploading';
import Checkbox from '@mui/material/Checkbox';

import CountryAPI from '../../../../axios/country';
import UserAPI from '../../../../axios/user';
import MiscAPI from '../../../../axios/misc';
import i18n from "i18next";

export const UserData = () => {
    //const {userData} = useContext(ConfigContext);
    
    const [countries, setCountries] = useState(() => { return [] });
    const [userTypes, setUserTypes] = useState(() => { return [] });
    const [images, setImages] = useState(() => { return [] });
    const [oldImage, setOldImage] = useState(() => { return [] });

    const { api } = useContext(ConfigContext);
    
    useEffect(() => { 

        CountryAPI.getListOfCountries()
        .then(function (response) {
            setCountries(response.data);
          }).catch((err) => {
         console.log(err);
        });

        MiscAPI.getUserTypeOptions(i18n.language)
          .then(function (response) {
              setUserTypes(response.data);
        }).catch((err) => {
            console.log(err);
           });

        UserAPI.getProfileData().then(function (response) {
            populateProfileData(response.data);
            oldProfileData.current = response.data;
          }).catch((err) => {
            console.log(err);
           }); 
        
          
    },[]);
    

    const { t } = useTranslation(["profile", "register", "common"]);


    useEffect(() => { 

    
        MiscAPI.getUserTypeOptions(i18n.language)
        .then(function (response) {
            setUserTypes(response.data);
        }).catch((err) => {
            console.log(err);
           });
        
       
    },[t]);

    const userData = Object.freeze({
        "email" : "",
        "first_name" : "",
        "last_name" : "",
        "phone" : "",
        "mobile" : "",
        "country" : "RS",
        "city" : "",
        "zip_code" : "",
        "address" : "",
        "user_type" : 1,
        "photo" : undefined,
        "send_messages" : false,
        "send_notifications": false
    });

    const [formDataProfile, updateFormDataProfile] = useState(userData);
    const oldProfileData = useRef();

    function populateProfileData(data){

        if (data['photo'])
            setOldImage([data['photo']]);
       
        for(const key in data){
            
            updateFormDataProfile(prev => ({
                ...prev,
                [key] : data[key]
            }));
        }

       
        
    }

    const onChangeImage = (imageList, addUpdateIndex) => {
        updateFormDataProfile(prev => ({
            ...prev,
            "photo" : imageList
        }));
      };

    const handleChangeProfile = (e) => {
        
		updateFormDataProfile({
			...formDataProfile,
			[e.target.name]: e.target.value.trim(),
		});
	};

    const handleChangeCountry = (e) => {
        
		updateFormDataProfile({
			...formDataProfile,
			"country" :e.target.value,
		});
	};   

    const handleChangeType = (e) => {
        
		updateFormDataProfile({
			...formDataProfile,
			"user_type" :e.target.value,
		});
	};   

    const onCancelProfile = () => {

   

        setSuccessProfile(false);
        setErrorProfile(false);
        populateProfileData(oldProfileData.current);
        setImages([]);
     
    }

    const onConfirmProfile = () => {
        
        setSuccessProfile(false);
        setErrorProfile(false);

      
        if (images.length > 0){
           
            formDataProfile.photo = images[0].file;
        }
        else 
        {
        if (oldImage.length > 0) {
           
            delete formDataProfile.photo;
        }
        else {
           
            formDataProfile.photo = null;
        }
        }

        UserAPI.saveProfile(formDataProfile).then(function (response) {
            setSuccessProfile(true);
            oldProfileData.current = response.data;
          }).catch((err) => {
           setErrorProfile(true);
        });
    }

   
    const passwordData = Object.freeze({
        "old_password" : "",
        "new_password1" : "",
        "new_password2" : ""
    });

    const [formDataPassword, updateFormDataPassword] = useState(passwordData);

    const handleChangePassword = (e) => {
        
		updateFormDataPassword({
			...formDataPassword,
			[e.target.name]: e.target.value.trim(),
		});
	};

    const handleChangeCheckbox = (e) => {
        updateFormDataProfile({
			...formDataProfile,
			[e.target.name]: e.target.checked,
		});
      };

   

    const onConfirmPassword = () => {

        setSuccessPassword(false);
        setErrorPassword(false);

        UserAPI.changePassword(formDataPassword).then(function (response) {
            setSuccessPassword(true);
          }).catch((err) => {

            if(err.response.status === 400){
                var e = err.response.data;
                   
                    if (e.hasOwnProperty('new_password2')) {
                      
                        if (Array.isArray(e.new_password2)){
                            var msg = "";
                            e.new_password2.forEach( item => {
                                msg += t(item, {"ns" : "register"});
                                msg += " \n";
                            });
                           
                            setPasswordErrorMessage(msg);
                          

                        }
                        else
                            {
                                setPasswordErrorMessage(t(e.new_password2, {"ns" : "register"}));
                                
                            }


                      }
                      else{
                          setPasswordErrorMessage(t("change_pass_err"));
                      }
            }
            else{
                setPasswordErrorMessage(t("change_pass_err"));
            }

            setErrorPassword(true);
        });

      
    }

    const onOldImageRemove = (index) => {
        setOldImage([]);
    }

    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const onCancelPassword = () => {

        setSuccessPassword(false);
        setErrorPassword(false);
        
        updateFormDataPassword({
            "old_password" : "",
            "new_password" : "",
            "confirm_password" : ""
        });
     
    }

    const setNewImages = (imageList, addUpdateIndex) => {
        setImages(imageList);
        setOldImage([]);
      };

    const [successPassword, setSuccessPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);


    const [successProfile, setSuccessProfile] = useState(false);
    const [errorProfile, setErrorProfile] = useState(false);

    return(
        <div className='user-data-page-wrap'>
            <h3>{t("title")}</h3>
            <div className='user-data-sections-wrap'>
            <div className='user-data-wrap'>
                <p className='bold user-page-subtitle'>{t("subtitle_1")}</p>

               
                <InputField 
                            name="first_name"
                            key={`user-data-1`}
                            type='text' 
                            placeholder=""
                            label={t("first_name")}
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.first_name}
                            />

                <InputField 
                            name="last_name"
                            key={`user-data-2`}
                            type='text' 
                            placeholder=""
                            label={t("last_name")} 
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.last_name}
                            />            

                    <SelectField 
                            translate={false}
                            name="type"
                            key={`user-data-10`}
                            label={t("type")} 
                            options={userTypes} 
                            value={formDataProfile.user_type}
                            handleChange={handleChangeType}
                            />

                <InputField 
                            name="email"
                            key={`user-data-3`}
                            type='text' 
                            placeholder=""
                            label={t("mail")} 
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.email}
                            />   


                <InputField 
                            name="phone"
                            key={`user-data-4`}
                            type='text' 
                            placeholder=""
                            label={t("phone")}
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.phone}
                            />  


                <InputField 
                            name="mobile"
                            key={`user-data-5`}
                            type='text' 
                            placeholder=""
                            label={t("mobile")} 
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.mobile}
                            />  

                <SelectField 
                            name="country"
                            key={`user-data-6`}
                            label={t("country")} 
                            options={countries} 
                            translate={false}
                            handleChange={handleChangeCountry}
                            value={formDataProfile.country}
                            />

                <InputField 
                            name="city"
                            key={`user-data-7`}
                            type='text' 
                            placeholder=""
                            label={t("city")}
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.city}
                            />  


                <InputField 
                            name="zip_code"
                            key={`user-data-8`}
                            type='text' 
                            placeholder=""
                            label={t("postal")}
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.zip_code}
                            />  


                <InputField 
                            name="address"
                            key={`user-data-9`}
                            type='text' 
                            placeholder=""
                            label={t("address")} 
                            position='column'
                            handleChange={handleChangeProfile}
                            value={formDataProfile.address}
                            />  

<ImageUploading
        value={images}
        onChange={setNewImages}
        maxNumber={1}
        dataURLKey="photo"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              {t("choose_photo")}
            </button>
            &nbsp;
           

            {oldImage.map((image, index) => (
              <div key={index} className="image-item">
                <img src={api.host + ":" + api.port + image} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onOldImageRemove(index)}>{t("delete_photo")}</button>
                </div>
              </div>
            ))}


            {images.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['photo']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageRemove(index)}>{t("delete_photo")}</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

      <div className='checkbox-wrap clickable' key={`user-data-send-messages`}>
        <Checkbox
            name="send_messages"
            checked={formDataProfile.send_messages}
            onChange={handleChangeCheckbox}
            inputProps={{ 'aria-label': 'controlled' }}
            />
            <p>{t('send_messages')}</p>
                </div>

        <div className='checkbox-wrap clickable' key={`user-data-send-notifications`}>
        <Checkbox
            name="send_notifications"
            checked={formDataProfile.send_notifications}
            onChange={handleChangeCheckbox}
            inputProps={{ 'aria-label': 'controlled' }}
            />
             <p>{t('send_notifications')}</p>
            </div>

                    { errorProfile &&  <Alert severity="error">
                        <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                        {t("change_profile_err")}
                     </Alert>}
                    

                    { successProfile && <Alert severity="success">
                    <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                        {t("change_profile_success")}
                    </Alert>}

                
                <EditButtonsWrap onCancel={onCancelProfile} onConfirm={onConfirmProfile}/>
            </div>
            
                <div className='user-data-ps-wrap'>
                    <p className='bold user-page-subtitle'>{t("subtitle_2")} </p>
                    <InputField 
                                type="password"
                                name="old_password"
                                key={`user-data-ops`}
                                label={t("old_password")} 
                                position='column'
                                handleChange={handleChangePassword}
                                value={formDataPassword.old_password}/>
                    <InputField 
                                type="password"
                                name="new_password1"
                                key={`user-data-nps`}
                                label={t("new_password")} 
                                position='column'
                                handleChange={handleChangePassword}
                                value={formDataPassword.new_password1}
                                />
                    <InputField 
                                type="password"
                                name="new_password2"
                                key={`user-data-nps2`}
                                label={t("confirm_password")} 
                                position='column'
                                handleChange={handleChangePassword}
                                value={formDataPassword.new_password2}/>
                    {/* <p className='user-page-subtitle'>
                        Povežite se sa: 
                    </p> */}
                    {/* <CheckBoxField direction='row' text='Facebook' value='fb'/>
                    <CheckBoxField direction='row' text='Instagram' value='ig'/> */}
                    { errorPassword &&  <Alert severity="error">
                        <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                        {passwordErrorMessage}
                     </Alert>}
                    

                    { successPassword && <Alert severity="success">
                    <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                        {t("change_pass_success")}
                    </Alert>}
                     


                    <EditButtonsWrap onConfirm={onConfirmPassword} onCancel={onCancelPassword}/>
                </div>
                
            </div>
        </div>
    )
}
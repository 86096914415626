import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const SelectMultipleField = ({ className = 'row', label, options, handleChange, value = [] }) => {
    // Create a mapping of option values to their names for efficient lookups
    const optionValueToName = React.useMemo(() => {
        const map = new Map();
        options.forEach(option => map.set(option.value, option.name));
        return map;
    }, [options]);

    const renderSelected = (selected) => {
        return selected.map(item => optionValueToName.get(item)).join(', ');
    }

    return (
        <div className={`select-field-wrap ${className}`}>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                <Select
                    className='select-field'
                    onChange={handleChange}
                    value={value}
                    renderValue={renderSelected}
                    multiple
                >
                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={value.includes(option.value)} />
                            <ListItemText primary={option.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default React.memo(SelectMultipleField);

import React, { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";
import  InputField  from '../shared-components/form/input-field/input-field';
import TextField from "@mui/material/TextField";
import MessageAPI from '../../axios/message';
import Alert from '@mui/material/Alert';

export const NewMessageDialog = ({ 
    open, 
    setOpen, 
    user, 
    listing = "", 
    reply_to = null, 
    old_subject = "", 
    old_message 
}) => {
    const { t } = useTranslation(["message", "common"]);
    const [error, setError] = useState(false);

    const messageInit = Object.freeze({
        subject: old_subject === "" ? "" : "Re: " + old_subject,
        body: "",
        reply_to,
        listing
    });

    const [formData, setFormData] = useState(messageInit);

    const handleChange = useCallback((e) => {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }, []);

    const handleCancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleConfirm = useCallback(() => {
        let text = `${formData.body}\n\n-------------------------\n\n${old_message ? new Date(old_message.date_created).toLocaleString('sr-SP') + " : " + old_message.sender.first_name + " " + old_message.sender.last_name + "\n" + old_message.body : ""}`;
        const data = { ...formData, body: text };

        MessageAPI.sendMessage(data).then(() => {
            setOpen(false);
        }).catch(() => {
            setError(true);
        });
    }, [formData, old_message, setOpen]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('title')}</DialogTitle>
                <DialogContent>
                    <div><span><b>{t("user") + user}</b></span></div>
                    <div><span><b>{t("listing") + listing}</b></span></div>
                    <div>
                        <span><b>{t("subject")}</b></span>
                        <InputField
                            maxlength={500}
                            name="subject"
                            value={formData.subject}
                            handleChange={handleChange}
                            placeholder={t("subject")}
                        />
                    </div>
                    <div>
                        <span><b>{t("text")}</b></span>
                        <TextField
                            name="body"
                            fullWidth
                            rows={3}
                            value={formData.body}
                            onChange={handleChange}
                            multiline
                        />
                    </div>
                    {error && <Alert severity="error">{t("new_message_error")}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{t("cancel", { "ns": "common" })}</Button>
                    <Button onClick={handleConfirm} autoFocus>{t("confirm", { "ns": "common" })}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};



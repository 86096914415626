import axiosInstance from './axios';

const FileAPI = {

    
    //----------------------------------------------------------------
    postImage : (listing_id, data) => {
        return axiosInstance.post(`listing/${listing_id}/image`, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    //----------------------------------------------------------------
    removeImage : (image_id) => {
        return axiosInstance.delete(`image/${image_id}`, { });
    },
    //----------------------------------------------------------------
    removeAllImages : (listing_id) => {
        return axiosInstance.delete(`listing/${listing_id}/images`, { });
    },
    //----------------------------------------------------------------
    postVideo : (listing_id, data) => {
            return axiosInstance.post(`listing/${listing_id}/video`, data, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
        },
    //----------------------------------------------------------------
    removeVideo : (image_id) => {
            return axiosInstance.delete(`video/${image_id}`, { });
        },
    //----------------------------------------------------------------
    removeAllVideos : (listing_id) => {
            return axiosInstance.delete(`listing/${listing_id}/videos`, { });
        }

}

export default FileAPI;
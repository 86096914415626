import axiosInstance from './axios';

const MiscAPI = {

    getHeatingOptions : (lang) => {
        return axiosInstance.get(`heating/${lang}`, { });
    },

    //----------------------------------------------------------------
  
    getPropertyStateOptions : (lang) => {
        return axiosInstance.get(`property_state/${lang}`, { });
    },
    //----------------------------------------------------------------
  
    getPropertyTypeOptions : (lang) => {
         return axiosInstance.get(`property_type/${lang}`, { });
    },

    //----------------------------------------------------------------
  
    getUserTypeOptions : (lang) => {
            return axiosInstance.get(`user_type/${lang}`, { });
       },

    //----------------------------------------------------------------
  
    getListingTypeOptions : (lang) => {
        return axiosInstance.get(`listing_type/${lang}`, { });
   }
}

export default MiscAPI;
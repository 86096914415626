import axiosInstance from './axios';

const ListingAPI = {

    postListing : (data) => {
        return axiosInstance
        .post(`listing/`, data);
    },

    //----------------------------------------------------------------

    getListingData : (id) => {
        return axiosInstance
        .get('listing/'+id);
    },

    //----------------------------------------------------------------

    editListing : (id, data) => {
        return axiosInstance
        .put(`listing/${id}`, data);
    },

    //----------------------------------------------------------------

    getActiveListings : (page, sort, language) => {
        return axiosInstance
        .get(`my_listings/active/${sort}/${language}?page=${page}`);
    },
 
    //----------------------------------------------------------------

    getInactiveListings : (page, sort, language) => {
        return axiosInstance
        .get(`my_listings/inactive/${sort}/${language}?page=${page}`);
    },

    //----------------------------------------------------------------

    getListingDetails : (id, lang) => {
        return axiosInstance
        .get(`details/listing/${id}/${lang}`);
    },

     //----------------------------------------------------------------

     getListingCount : () => {
        return axiosInstance
        .get('count/listings');

     },
     //----------------------------------------------------------------

    getListingsSearch : (page, data, sort, language) => {
        return axiosInstance
        .post(`search/listings/${sort}/${language}?page=${page}`, data);
    },
//----------------------------------------------------------------
    getListingsSearchCount : ( data ) => {
        return axiosInstance
        .post(`search/count`, data);
    },
     //----------------------------------------------------------------
    countLikes : (listing_id) => {
        return axiosInstance.get(`favorite/count/${listing_id}`)
    },
    //----------------------------------------------------------------
    isListingLikedByUser : (listing_id) => {
        return axiosInstance.get(`favorite/${listing_id}`)
    },
    //----------------------------------------------------------------
    likeListing : (listing_id) => {
        return axiosInstance.post(`favorite/${listing_id}`)
    },
    //----------------------------------------------------------------
    unlikeListing : (listing_id) => {
        return axiosInstance.delete(`favorite/${listing_id}`)
    },
    //----------------------------------------------------------------
    getFavoriteListings : (page, sort, language) => {
        return axiosInstance.get(`favorite/list/${sort}/${language}?page=${page}`)
    },
    //----------------------------------------------------------------
    getPromotionPrice : (group) => {
        return axiosInstance.get(`listing_group/price/${group}`)
    },
    //----------------------------------------------------------------
    promoteListing : (data) => {
        return axiosInstance.post("promote", data)
    },
    //----------------------------------------------------------------
    renewListing : (listing) => {
        return axiosInstance.post(`renew/listing/${listing}`, {})
    },
    //----------------------------------------------------------------
    activateListing : (listing) => {
        return axiosInstance.post(`activate/listing/${listing}`, {})
    },
    //----------------------------------------------------------------
    deactivateListing : (listing) => {
        return axiosInstance.post(`deactivate/listing/${listing}`, {})
    },
    //----------------------------------------------------------------
    deleteListing : (listing) => {
        return axiosInstance.delete(`listing/${listing}`, {})
    },
    //----------------------------------------------------------------
    getListingCard : (listing, language) => {
        return axiosInstance.get(`card/listing/${listing}/${language}`, {})
    },
    //----------------------------------------------------------------
    getPromotedList: (page, language) => {
        return axiosInstance.get(`promoted/${language}?page=${page}`, {})
    }
}

export default ListingAPI;
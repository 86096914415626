import axiosInstance from './axios';

const AccountAPI = {

    getTransactions : () => {
        return axiosInstance.get('transactions', { });
    },
    getBalance : () => {
        return axiosInstance.get('transactions/balance', { });
    },


}

export default AccountAPI;
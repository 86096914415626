import React from "react";

import logo from '../../../assets/icons/halostan_logo.png';

export const Logo = () =>{
    return(
        <div className='logo-wrapper'>
            <img src={logo} alt='halostan'/>
        </div>
    );
}
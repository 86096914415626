import React from 'react';


export const InformationPage = ({title, backgroundImage}) => {
    
    return(
        <div className='information-page-wrap page-wrap'>
            <div className='information-page-background-image-wrap' style={{backgroundImage: `url(${backgroundImage})`}}>

            </div>
            <div className='information-page-title-content-wrap'>
                <div className='information-page-title-wrap flex row a-center jc-center'>
                    <p className='title'>{title}</p>
                </div>
                <div className='information-page-content'>
                    <p>
quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."
Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet est sit amet odio sagittis facilisis. Vivamus lobortis elit sed tellus convallis auctor. Curabitur eget lectus nulla. Aliquam quis cursus dolor, ac ultrices libero. Aliquam erat volutpat. Praesent quis ullamcorper libero. Fusce sit amet gravida lorem. Mauris magna urna, ultrices quis magna lacinia, tincidunt porta lectus.

Vestibulum a lorem nec tortor congue commodo. Suspendisse molestie risus eu tortor dapibus vulputate. Integer eget nibh eu dui condimentum laoreet quis quis turpis. Donec at eros mi. Fusce sed mi eu felis feugiat cursus. Etiam posuere pharetra lorem eget ullamcorper. Sed vel gravida nulla. Etiam semper enim ac condimentum sodales. Aenean pharetra nisi nulla, sit amet tristique est consequat eu.

Ut et nunc eget mauris aliquam consequat in nec elit. In consequat efficitur tincidunt. Quisque vestibulum libero nibh, sed dignissim velit finibus eu. Nunc porttitor nunc nec mauris convallis, vitae fringilla neque tristique. Quisque vehicula cursus mauris, nec dictum turpis pulvinar vitae. Quisque volutpat arcu nec metus suscipit eleifend. Phasellus a luctus est, faucibus egestas magna. Nam sed varius libero. Proin tincidunt nibh semper ante rutrum porttitor. In eleifend viverra libero, sed efficitur mi ullamcorper non. Phasellus iaculis ut massa vitae consequat. Vivamus varius cursus nibh, vel congue dolor rhoncus id. Phasellus bibendum ipsum sed accumsan bibendum. Nam dignissim justo ipsum, non placerat massa ullamcorper non. Nunc sodales eget lectus finibus sollicitudin. Duis dignissim finibus blandit.

Vestibulum ullamcorper porta pellentesque. Etiam ullamcorper mauris vel pulvinar posuere. Donec sollicitudin, felis vitae pharetra interdum, arcu magna porta nisl, a tempor purus leo in nisi. Cras eu aliquet erat, hendrerit elementum odio. Pellentesque nec mi id arcu viverra facilisis. Ut eleifend libero consequat iaculis varius. Aenean vel est ac dolor tincidunt ultrices. Integer id cursus lectus. Mauris tincidunt suscipit augue pellentesque luctus. Nunc feugiat pellentesque diam, in lobortis metus porttitor et. Mauris a est ut massa consequat aliquet. Suspendisse sodales, orci non posuere mattis, justo risus pellentesque nisi, a gravida lacus ante quis dolor. Donec pulvinar vel tellus sed mollis.

Ut volutpat bibendum pellentesque. Nunc quis erat ac quam placerat fermentum at non eros. Vivamus tempor malesuada tellus vel fermentum. Sed diam leo, ullamcorper sed risus non, fermentum dignissim dolor. Nam quam massa, fermentum at nibh in, pharetra scelerisque velit. Praesent bibendum sit amet nulla nec suscipit. Ut aliquet lorem sed enim varius elementum eu a sem. Nam scelerisque, neque eu dapibus faucibus, nulla nisl ornare arcu, eget ornare augue est vitae erat. Vestibulum et tellus pharetra, malesuada dui at, sodales diam. Donec at aliquet nibh. Sed felis est, rhoncus sit amet imperdiet id, dictum quis orci. Quisque consectetur risus ac tempus tristique. Etiam nec sapien tincidunt, rutrum diam non, convallis erat.

Praesent gravida purus lectus, at fringilla leo porttitor et. Sed tempus, ante sit amet luctus convallis, erat est iaculis orci, sed venenatis lorem metus vel lectus. Curabitur id nibh quis eros placerat vehicula. Vivamus gravida eget libero quis vestibulum. Donec volutpat ipsum sed est facilisis hendrerit. Aenean sit amet massa mi. Donec a efficitur nisi, sit amet ultricies tellus. Fusce iaculis velit at nibh cursus, et commodo magna cursus. Praesent eleifend odio at quam aliquam facilisis. Sed posuere sem consectetur, accumsan dolor quis, tempus turpis. Cras vitae lorem quis orci ornare lacinia non a arcu. Phasellus pretium nisl laoreet urna dictum, id volutpat mi egestas. Donec condimentum blandit ex, non lobortis libero. Donec in lobortis leo, at facilisis mi. Nulla dictum risus tortor.

Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce varius facilisis eros nec convallis. Aliquam arcu mi, ultricies et scelerisque sed, imperdiet a nunc. Quisque ut condimentum velit, in euismod tortor. Donec ullamcorper nunc et arcu ornare bibendum. Suspendisse egestas, turpis ac mollis tempor, leo nisi scelerisque est, non ullamcorper enim tellus sed arcu. Duis justo ante, mattis vitae ipsum vel, lacinia mollis mi.

Nunc pretium commodo massa, et dapibus ligula elementum et. Duis euismod nec erat a tincidunt. Nullam posuere elementum arcu sed accumsan. Nulla facilisi. Morbi eget diam ut quam sodales fringilla congue sit amet odio. Aenean facilisis nisi sit amet dolor aliquam, et volutpat ex malesuada. Vivamus velit sapien, posuere quis sodales vitae, mollis a sapien. Morbi velit nisi, mollis nec ipsum eu, porta fermentum turpis. Phasellus neque augue, ullamcorper a quam sed, scelerisque vestibulum ipsum. Mauris hendrerit tortor leo, et suscipit eros dapibus eget. Mauris porta euismod leo, tincidunt porttitor ipsum consequat sed. Sed ut fringilla mauris. Sed id urna non dolor venenatis faucibus. In aliquam mi in magna lacinia aliquam. In vitae mauris ullamcorper, cursus nisl sit amet, dignissim lorem. Aliquam varius ultricies dictum.

Nunc ante odio, dapibus sit amet rhoncus in, venenatis id enim. Curabitur varius justo orci, sed aliquet velit vestibulum ac. Vestibulum finibus consequat lacus ut venenatis. Donec porta lectus in commodo pellentesque. Cras placerat massa sapien, et eleifend erat tempor vitae. Donec facilisis, nunc vel tristique tempus, odio dui mattis tellus, eget scelerisque nulla augue sed augue. Ut risus mauris, interdum non augue at, efficitur convallis magna. In in felis pulvinar, tincidunt elit id, tempus elit. Nunc sit amet tempor tortor, ut tincidunt erat. Praesent a eros ac purus molestie feugiat eget nec mi.

Nulla enim nibh, rutrum at imperdiet vitae, feugiat eu augue. In hac habitasse platea dictumst. Quisque vitae ipsum vel magna malesuada porttitor. Vivamus id sem tincidunt justo pharetra viverra. Sed vestibulum elit in pharetra blandit. Donec dictum eleifend tristique. Nam rhoncus neque vel enim egestas vestibulum. Vivamus ultrices pharetra dui eget ultricies. Praesent molestie consectetur commodo. Etiam dignissim eget sem venenatis pulvinar. In dictum arcu magna, quis luctus turpis posuere vel. Ut rhoncus blandit convallis. Proin ultricies purus erat, at imperdiet enim commodo placerat. Sed magna odio, mattis in ante molestie, efficitur pellentesque neque. Nam vel turpis semper, imperdiet purus in, ultrices nisi. Donec felis urna, pharetra vel quam ac, molestie aliquet justo.

</p>
                </div>
            </div>
        </div>
    )
}
import axiosInstance from './axios';

const PackageAPI = {

    getPackageGroups : (lang) => {
        return axiosInstance.get(`package-group/${lang}`, { });
    },

    getPackageList : (group, lang) => {
        return axiosInstance.get(`package/${group}/${lang}`, { });
    },

}

export default PackageAPI;
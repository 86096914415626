import React, { useContext, useState, useEffect, useCallback } from "react";
import { ConfigContext } from '../../contexts/config-context';
import { SmallInfoBox } from "../shared-components/layout/small-info-box/small-info-box";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MessageAPI from '../../axios/message';
import NotificationAPI from '../../axios/notification';
import AccountAPI from '../../axios/account';

export const UserNavigation = ({ className }) => {
    const { userNavigation } = useContext(ConfigContext);
    const { t } = useTranslation(["profile"]);
    const location = useLocation();

    const [myUserNavigation, setMyUserNavigation] = useState(userNavigation);

    const fetchData = useCallback(async () => {
        const [messageRes, notificationRes, balanceRes] = await Promise.all([
            MessageAPI.countUnreadMessage(),
            NotificationAPI.getNotificationCount(),
            AccountAPI.getBalance()
        ]);

        const data = {
            messages: messageRes.data.result,
            notifications: notificationRes.data.result,
            credit: balanceRes.data.result
        };

        setMyUserNavigation(prevNavigation => 
            prevNavigation.map(item => ({
                ...item,
                additional: data[item.title] !== "0" ? data[item.title] : undefined
            }))
        );

    }, []);

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    }, [fetchData]);

    return (
        <div className={`user-navigation-wrap ${className}`}>
            <ul>
                {myUserNavigation.map((item, index) => (
                    <Link
                        onClick={() => sessionStorage.removeItem('currentPage')}
                        className={`user-navigation-link flex row a-center jc-sb ${location.pathname === item.path && 'active'}`}
                        to={item.path}
                        key={`user-nav-link-${index}`}>
                        <li className='bold flex row a-center jc-sb'>
                            {item.icon && <span className='blue'>{item.icon}</span>} 
                            {t(item.title)}
                        </li>
                        {item.additional !== undefined && <SmallInfoBox color="orange-b" label={item.additional} />}
                    </Link>
                ))}
            </ul>
        </div>
    )
}

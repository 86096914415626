import axiosInstance from './axios';

const StatisticsAPI = {

    getStatisticsData : (listing) => {
        return axiosInstance.get(`statistics/${listing}`, { });
    },
   


}

export default StatisticsAPI;
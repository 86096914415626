import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState, useCallback } from 'react';

const CheckboxesDropdown = ({ className, children, title }) => {
    const [toggleCheckboxes, setToggleCheckboxes] = useState(false);

    const handleToggle = useCallback(() => {
        setToggleCheckboxes(prevState => !prevState);
    }, []);

    return (
        <div className={`checkboxes-dropdown-wrap ${className}`}>
            <div className='checkboxes-dropdown-header flex row a-center jc-sb' onClick={handleToggle}>
                <p>{title}</p>
                {toggleCheckboxes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {toggleCheckboxes && 
                <div className='checkboxes-visible'>
                    {children}
                </div>
            }
        </div>
    );
}

export default React.memo(CheckboxesDropdown);

import { Apartment, Assignment, AssignmentTurnedIn, AttachMoney, BadgeOutlined, BarChart, Chat, ConnectWithoutContact, Favorite, Garage, Grain, Home, Info, Key, Logout, Luggage, MeetingRoom, Paid, Person, PresentToAll, Public, Store, Terrain } from '@mui/icons-material';
import { Badge } from '@mui/material';
import {  Train,Restaurant,Factory, DirectionsBus,FitnessCenter,LocalGroceryStore,LocalGasStation,DirectionsBusFilled, LocalPostOffice, LocationCity,Healing,Atm, AccountBalance, LocalHospital, School,MenuBook, ChildCare,LocalPharmacy,Stadium, Storefront,LocalMall, FlightTakeoff } from '@mui/icons-material';
const config = { 
  /* api: {
        host: 'http://localhost',
        port: 8000,
    },

    website : 'http://localhost:3000',*/
 
    api: {
        host: 'https://api.halostan.rs',
        port: 443,
    },

    website : 'https://test.halostan.rs',

    listingTypeCode: {
        "sale" : 1,
        "rent" : 2,
        "short_term" : 3
    },

    locationItemsLimit : 5,

    navItems: [
        {
            title: "home",
            path: '/',
            header: true,
            footer: true,
        },{
            title: "buy",
            path: '/prodaja',
            header: true,
            footer: true,
        },{
            title: "rent",
            path: '/izdavanje',
            header: true,
            footer: true,
        },{
            path: '/stan-na-dan',
            title: "daily",
            header: true,
            footer: true,
        },
        {
            title: "login",
            path: '/login',
            header: false,
            footer: false,
        },
        
        {
            title: "create_listing",
            path: '/nov-oglas',
            header: false,
            footer: true,
        },
        {
            title: "Blog",
            path: '/blog',
            header: false,
            footer: true,
        },
        {
            title: "Informacije i uputstva",
            path: '/uputstvo',
            header: false,
            footer: true,
        },
        {
            title: "Kupci",
            path: '/kupci',
            header: false,
            footer: false,
        },
        {
            title: "Zakupci",
            path: '/zakupci',
            header: false,
            footer: false,
        },
        {
            title: "Prodavci",
            path: '/prodavci',
            header: false,
            footer: false,
        },
        {
            title: "Oglašavanje",
            path: '/oglasavanje',
            header: false,
            footer: false,
        }
    ],
    sideNav: [
        {
            title:"instructions",
            path:'/uputstvo',
            header: true,
            footer: true
        },
        {
            title: "sellers",
            path: '/uputstvo-prodavci',
            header: true,
            footer: true,
        },
        {
            title: "tenants",
            path: '/uputstvo-zakupci',
            header: true,
            footer: true,
        },
        {
            title: "advertisement",
            path: '/uputstvo-oglasavanje',
            header: true,
            footer: true,
        },
    ],
    userNavigation: [
        {title: "my_data", path:'/korisnik/podaci', icon: <Person/>},
        {title: "active", path:'/korisnik/oglasi', icon:<AssignmentTurnedIn/>},
        {title: "non_active", path:'/korisnik/neaktivni-oglasi', icon:<Assignment/>},
        {title: "messages", path:'/korisnik/poruke', icon:<Chat/>},
        {title: "following", path:'/korisnik/praceni', icon:<Favorite/>},
        {title: "notifications", path:'/korisnik/obavestenja', icon: <Info/>},
        {title: "contact", path:'/korisnik/kontakt', icon: <ConnectWithoutContact/>},
        {title: "credit", path:'/korisnik/kredit',  icon: <Paid/>},
        {title: "offer", path:'/korisnik/oglasavanje', icon: <BarChart/>},
        {title: "logout", path:'/login', icon: <Logout/>},
    ],
    
    listingOptions: [
        {name: "Stan", icon: <Apartment/>, link:'/postavi-oglas/stan'},
        {name: "Kuća", icon: <Home/>, link:'/postavi-oglas/kuca' },
        {name: 'Soba', icon: <MeetingRoom/>, link:'/postavi-oglas/soba'},
        {name: 'Poslovni prostor', icon: <Store/>, link:'/postavi-oglas/poslovni-prostor'},
        {name: 'Zemljište', icon: <Terrain/>,link:'/postavi-oglas/zemljiste'},
        {name: 'Garaža', icon: <Garage/>,link:'/postavi-oglas/garaza'},
    ],
    listingType: [
        {name: "sale", code: 1, icon: <AttachMoney/>, link:'/postavi-oglas/prodaja'},
        {name: "rent", code: 2, icon: <Key/>,link:'/postavi-oglas/izdavanje'},
        {name: 'short_term', code: 3, icon: <Luggage/>, link:'/postavi-oglas/stan-na-dan'},
        // {name: 'Inostranstvo', icon: <Public/>, link:'/postavi-oglas/inostranstvo'},
        // {name: 'Ostalo', icon: <Grain/>,link:'/postavi-oglas/ostalo'}
    ],
 
    filterPhotoOptions : {
        title:"Foto/Video", 
        options: [
            {name:"without_photo", value:'nophoto'}, 
            {name:"with_photo", value:'photo'},
            {name:"with_video", value:'video'},
        ]
    },
  
    
    filterOptions: [
        {label: "date_created_desc", id: "-date_created"}, 
        {label: "date_created_asc", id: "date_created"},
        {label: "price_asc", id:"price"},
        {label: "price_desc", id:"-price"}],
    userData: [
        {
            name: 'Ime',
            value: "",
            type: "text"
        },
        {
            name: 'Prezime',
            value: "",
            type: "text"
        },
        {
            name: 'E-mail',
            value: "",
            type: "text"
        },{
            name: 'Telefon',
            value: "",
            type: "text"
        },{
            name: 'Mobilni',
            value: "",
            type: "text"
        },
        {
            name: 'Država',
            value: "",
            options:[ {
                "value": 1,
                "name": "Bangladesh"
            },
            {
                "value": 2,
                "name": "Belgium"
            },
            {
                "value": 3,
                "name": "Burkina Faso"
            }],
            type: "select"
        },
        {
            name: 'Mesto',
            value: "",
            options:[{}],
            type: "select"
        },
        {
            name: 'Deo mesta',
            value: "",
            options:[{}],
            type: "select"
        },
        {
            name: 'Postanski broj',
            value: "",
            type: "text"
        },
        {
            name: 'Adresa',
            value: "",
            type: "text"
        }
    ],
    announcements: [
        {
            message: "Vam nudi poklon kao novom korisniku!",
            icon: <PresentToAll/>,
            highlight: "halostan.rs",
            link: "/"
        },
        {
            message: "Vam nudi poklon kao novom korisniku!",
            icon: <PresentToAll/>,
            highlight: "halostan.rs",
            link: "/"
        },
        {
            message: "Vam nudi poklon kao novom korisniku!",
            icon: <PresentToAll/>,
            highlight: "halostan.rs",
            link: "/"
        },
        {
            message: "Vam nudi poklon kao novom korisniku!",
            icon: <PresentToAll/>,
            highlight: "halostan.rs",
            link: "/"
        }
    ]
    ,
    num_rooms_options : [
        {id:1, label : "0.5"},
        {id:2, label : "1.0"},
        {id:3, label : "1.5"},
        {id:4, label : "2.0"},
        {id:5, label : "2.5"},
        {id:6, label : "3.0"},
        {id:7, label : "3.5"},
        {id:8, label : "4.0"},
        {id:9, label : "4.5"},
        {id:10, label : "5.0"},
        {id:11, label : "5+"}
    ]
	,		
	
    num_bathrooms_options : [
        {id:1, label : "1"},
        {id:2, label : "2"},
        {id:3, label : "3"},
        {id:4, label : "3+"}
    ]
    ,
    floor_options : [
        {id:1, label :"basement_level"},
        {id:2, label :"lower_ground"},
        {id:3, label :"ground"},
        {id:4, label :"higher_ground"},
        {id:5, label :"1"},
        {id:6, label :"2"},
        {id:7, label :"3"},
        {id:8, label :"4"},
        {id:9, label :"5"},
        {id:10, label :"6"},
        {id:11, label :"7"},
        {id:12, label :"8"},
        {id:13, label :"9"},
        {id:14, label :"10"},
        {id:15, label :"11"},
        {id:16, label :"12"},
        {id:17, label :"13"},
        {id:18, label :"14"},
        {id:19, label :"15"},
        {id:20, label :"16"},
        {id:21, label :"17"},
        {id:22, label :"18"},
        {id:23, label :"19"},
        {id:24, label :"20"},
        {id:25, label :"21"},
        {id:26, label :"22"},
        {id:27, label :"23"},
        {id:28, label :"24"},
        {id:29, label :"25"},
        {id:30, label :"26"},
        {id:31, label :"27"},
        {id:32, label :"28"},
        {id:33, label :"29"},
        {id:34, label :"30"}
    ]
    ,
    max_floor_options : [
        {id:1, label :"1"},
        {id:2, label :"2"},
        {id:3, label :"3"},
        {id:4, label :"4"},
        {id:5, label :"5"},
        {id:6, label :"6"},
        {id:7, label :"7"},
        {id:8, label :"8"},
        {id:9, label :"9"},
        {id:10, label :"10"},
        {id:11, label :"11"},
        {id:12, label :"12"},
        {id:13, label :"13"},
        {id:14, label :"14"},
        {id:15, label :"15"},
        {id:16, label :"16"},
        {id:17, label :"17"},
        {id:18, label :"18"},
        {id:19, label :"19"},
        {id:20, label :"20"},
        {id:21, label :"21"},
        {id:22, label :"22"},
        {id:23, label :"23"},
        {id:24, label :"24"},
        {id:25, label :"25"},
        {id:26, label :"26"},
        {id:27, label :"27"},
        {id:28, label :"28"},
        {id:29, label :"29"},
        {id:30, label :"30"}
    ],

    checkboxItems : [
        {name:"urgent", checked: false},
        {name:"habitable", checked: false},
        {name:"registered", checked: false},
        {name:"vat", checked: false},
        {name:"salon", checked: false},
        {name:"duplex", checked: false},
        {name:"mortgage", checked: false},
        {name:"not_house", checked: false},
        {name:"not_last_floor", checked: false},
        {name:"attic", checked: false},
        {name:"energy_passport", checked: false},
        {name:"exchange", checked: false},
    ]
    ,
    checkboxItemsRestData : [
        {name:"terrace", checked: false},
        {name:"balcony", checked: false},
        {name:"lift", checked: false},
        {name:"basement", checked: false},
        {name:"hot_water", checked: false},
        {name:"has_phone", checked: false},
        {name:"internet", checked: false},
        {name:"cable", checked: false},
        {name:"ac", checked: false},
        {name:"interphone", checked: false},
        {name:"surveillance", checked: false},
        {name:"garage", checked: false},
        {name:"parking", checked: false},
    ],
    searchData : {
        "place" : "",
        "subplaces" : [],
        "property_type" : [],
        "listing_type" : "1",
        "min_price" : "",
        "max_price" : "",
        "min_area" : "",
        "max_area" : "",
        "min_rooms" : null,
        "max_rooms" : null,
        "min_floor" : null,
        "max_floor" : null,
        "publisher" : [],
        "heating" : [],
        "property_state" : [],
        "photo" : [],
        "additional" : [],
        "rest" : []
       
    },

    LISTING_GROUP : {
        'BASIC': 1,
        'PROMOTED': 2
    },

    listingDataInit : {
        "title" : "",
        "price" : "",
        "place" : "",
        "subplace" : "",
        "location" : "",
        "address" : "",
        "area" : "",
        "num_rooms" : null, 
        "num_bathrooms" : null, 
        "floor" : null,
        "max_floor" : null, 
        "bills" : "",
        "deposit" : "",
        "property_type" :0, 
        "property_state" : 0,
        "heating" : [],
        "urgent" : false,
        "habitable" : false,
        "registered" : false,
        "vat" : false,
        "salon" : false,
        "duplex" : false,
        "mortgage" : false,
        "not_house" : false,
        "not_last_floor" : false,
        "attic" : false,
        "energy_passport" : false, 
        "exchange" : false,
        "terrace" : false,
        "balcony" : false,
        "lift" : false,
        "basement" : false, 
        "hot_water" : false,
        "has_phone" : false,
        "internet" : false,
        "cable" : false,
        "ac" : false,
        "interphone" : false, 
        "surveillance" : false,
        "garage" : false,
        "parking" : false,
        "description" : "",
        "phone" : "",
        "mobile" : "",
        "email" : "",
        "lnglat" : [44.8125, 20.4612]
    },

    locationLegend :  [{
        icon: <ChildCare/>,
        name: 'kindergartens',
        distance: '1'
    },{
        icon:<MenuBook/>,
        name: 'schools',
        distance: '1'
    },{
        icon:  <School/>,
        name: 'universities',
        distance: '1'
    },{
        icon: <Healing/>,
        name: 'health_center',
        distance: '1'
    },{
        icon: <LocalHospital/>,
        name: 'hospitals',
        distance: '1'
    },{
        icon: <AccountBalance/>,
        name: 'banks',
        distance: '1'
    },{
        icon: <Atm/>,
        name: 'atms',
        distance: '1'
    },{
        icon: <LocalPostOffice/>,
        name: 'post_office',
        distance: '1'
    },{
        icon: <LocalGasStation/>,
        name: 'gas_stations',
        distance: '1'
    },{
        icon: <LocalGroceryStore/>,
        name: 'supermarkets',
        distance: '1'
    },{
        icon: <LocalMall/>,
        name: 'shopping_malls',
        distance: '1'
    },{
        icon: <Storefront/>,
        name: 'farmers_markets',
        distance: '1'
    },{
        icon: <FitnessCenter/>,
        name: 'gyms',
        distance: '1'
    },{
        icon: <Restaurant/>,
        name: 'restaurants',
        distance: '1'
    },{
        icon: <DirectionsBusFilled/>,
        name: 'bus_stations',
        distance: '1'
    },{
        icon: <LocalPharmacy/>,
        name: 'pharmacies',
        distance: '1'
    },{
        icon: <FlightTakeoff/>,
        name: 'airport',
        distance: '10'
    },{
        icon: <LocationCity/>,
        name: 'parks',
        distance: '1'
    },{
        icon: <Stadium/>,
        name: 'stadiums',
        distance: '1'
    },{
        icon: <Factory/>,
        name: 'museums',
        distance: '1'
    },{
        icon: <DirectionsBus/>,
        name: 'main_bus_station',
        distance: '5'
    },{
        icon: <Train/>,
        name: 'train_station',
        distance: '5'
    }]
    

}
export default config;
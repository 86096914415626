import axiosInstance from './axios';

const UserAPI = {

    register : (data) => {
        return axiosInstance.post(`auth/registration/`, data);
    },

    //----------------------------------------------------------------

    getProfileData : () => {
        return axiosInstance.get('profile', { });
    },

    //----------------------------------------------------------------

    resendVerificationMail : (data) => {
        return axiosInstance
        .post(`auth/registration/resend-email/`, data);
    },

    //----------------------------------------------------------------

    saveProfile : (data) => {
        return axiosInstance.put('profile', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },

    //----------------------------------------------------------------

    changePassword : (data) => {
        return axiosInstance.post('auth/password/change/', data);
    },

    //----------------------------------------------------------------

    passwordResetConfirmation : (data) => {
        return axiosInstance
        .post(`auth/password/reset/confirm/`, data);
    },

    //----------------------------------------------------------------

    passwordReset : (data) => {
        return axiosInstance
        .post(`auth/password/reset/`, data);
    }, 

    //----------------------------------------------------------------

    confirmMail : (data) => {
        return axiosInstance
        .post(`auth/registration/verify-email/`, data);
    },

    //----------------------------------------------------------------

    logout : (data) => {
        return axiosInstance.post('auth/logout/', data);
    }

}

export default UserAPI;
import React, { useState, useEffect } from "react";
import AdvertisementAPI from '../../axios/advertisement';
import { AdItem } from "../ad-item/ad-item";

const MemoizedAdItem = React.memo(AdItem);

export const AdsList = () => {
    
    // Directly initialize ads as an empty array
    const [ads, setAds] = useState([]);

    useEffect(() => {
        AdvertisementAPI.getAds()
            .then(res => {
                setAds(res.data);
            })
            .catch(err => {
                console.error("Failed to fetch ads:", err);
               
            });
    }, []);

    return (
        <div>
            {ads.map(item => (
                
                <MemoizedAdItem key={item.id} data={item} />
            ))}
        </div>
    );
};
import React from 'react';


export const BigSmallTitle = ({className, big, small}) => {

    return(
        <div className={`big-small-title-wrap ${className && className}`}>
            <p className='bold medium'>{big}</p>
            <p>{small}</p>
        </div>
    )
}
import axiosInstance from './axios';

const NotificationAPI = {

    
    //----------------------------------------------------------------
    getNotificationCount : () => {
        return axiosInstance.get('notifications/count');
    },
    //----------------------------------------------------------------
    getNotifications : (page, language) => {
        return axiosInstance.get(`notifications/all/${language}?page=${page}`);
    },
    //----------------------------------------------------------------
    readNotification : (notification) => {
        return axiosInstance.put(`notifications/is_read/${notification}`, {});
    },
   

}

export default NotificationAPI;
import React from 'react';
import { UserNotificationList } from '../../../../pages/user-panel/components/user-notification-list/user-notification-list';

export const Announcements = ({className, message, icon, type, link, highlight}) => {

    return(


        <div className={`announcements-wrap flex row a-center jc-start ${className && className}`}>
            <UserNotificationList />
            {/*<span>{icon && icon}</span>
            <p className={`announcement-message`}>{link && <Link to={link} className='blue bold'>{highlight}</Link>}{message}</p>
    <InfoBox icon={<Info/>} info="Ovo je obavestenje" /> */}
        </div>
    )
}
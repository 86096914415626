import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import Button from "../../components/shared-components/buttons/button/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UserAPI from "../../axios/user";

export const ConfirmMail = () => {
    const { key } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(["login", "common"]);

    const [dialogState, setDialogState] = useState({
        open: false,
        title: "",
        message: "",
        error: false
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await UserAPI.confirmMail({ key });
            setDialogState({
                open: true,
                title: t('success', { "ns": "common" }),
                message: t('confirm_success'),
                error: false
            });
        } catch (err) {
            setDialogState({
                open: true,
                title: t('error', { "ns": "common" }),
                message: t('confirm_error'),
                error: true
            });
        }
    };

    const handleClose = () => {
        setDialogState({ ...dialogState, open: false });
        if (!dialogState.error) {
            navigate("/login/");
        }
    };

    return (
        <div className="page-wrap">
            <div>{t("confirm_mail_text")}</div>
            <Button
                onClick={handleSubmit}
                color="primary"
                text={t("confirm_mail_button")}
                className="login-button"
            />
            <Dialog
                open={dialogState.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogState.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogState.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      t("agree")
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

import axiosInstance from './axios';

const CountryAPI = {

    getListOfCountries : () => {
        return axiosInstance.get('countries', { });
    }

}

export default CountryAPI;
import React, { useState, useContext } from "react";
import  Button  from "../../components/shared-components/buttons/button/button";
import  InputField  from "../../components/shared-components/form/input-field/input-field";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/login";
import FacebookLogin from "../../axios/facebookLogin";
import gLogin from "../../axios/googleLogin";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FbLogin from "react-facebook-login";
import GoogleLogin from "@leecheuk/react-google-login";
import { UserContext } from "../../contexts/userContext";

import { useTranslation } from "react-i18next";

export const Login = () => {
  const { user111, setUser } = useContext(UserContext);

  const navigate = useNavigate();
  const [errorMessage, updateErrorMessage] = useState("");
  const [showError, updateShowError] = useState(false);
  const [showValidation, updateShowValidation] = useState(false);

  const onRegister = () => {
    navigate("/register");
  };

  const user = Object.freeze({
    email: "",
    password: "",
  });

  const [formData, updateFormData] = useState(user);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateShowError(false);
    updateShowValidation(false);
    setSuccess(false);
    setError(false);
    
    axiosInstance
      .post(`api/auth/login/`, {
        grant_type: "password",
        username: formData.email,
        password: formData.password,
        //	client_id: '05GYopkyMDxFakgCSuNtpOLrNcyJwU054wZfYBt1',
        //	client_secret:					'U3r3rmtff7t52Xa1chfzGf5vtl06zr4PwGbi8sWBcilTKs0YJEm278JPmXbcgMsyYpxYMQbDKFsyjhrzKwDLW8VMfW0ePZmrRuSmssnFPvqjeodYsNTfObTAzxOxZKuH',
      })
      .then((res) => {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setUser(res.data.user);
        navigate("/");
        // window.location.reload();
      })
      .catch( err => {
        if(err.response.status === 400){
          var e = err.response.data;
          if (e.hasOwnProperty('non_field_errors')) {

            e.non_field_errors.forEach( field => {
              if (field == "E-mail is not verified."){
                updateErrorMessage(t("email_not_verified"));
                updateShowError(true);
                updateShowValidation(true);
              }
              else if (field == "Unable to log in with provided credentials."){
                updateErrorMessage(t("wrong_credencials"));
                updateShowError(true);
              }
              else {
                updateErrorMessage(t("login_error"));
                updateShowError(true);
              }

            });


          }
          else{
            updateErrorMessage(t("login_error"));
            updateShowError(true);
          }
        }
        else{
          updateErrorMessage(t("login_error"));
          updateShowError(true);
        }

      });
  };

  const responseFacebook = async (response) => {
    // alert(JSON.stringify(response));
    FacebookLogin(response.accessToken).then((res) => {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setUser(res.data.user);
      navigate("/");
      // window.location.reload();
    }).catch( err => {
      updateErrorMessage(t("login_error"));
      updateShowError(true);

    });;
  };

  const responseGoogle = (response) => {
    gLogin(response.accessToken).then((res) => {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setUser(res.data.user);
      navigate("/");
      // window.location.reload();
    }).catch( err => {
      updateErrorMessage(t("login_error"));
      updateShowError(true);

    });
  };

  const resend = () => {
    setSuccess(false);
    setError(false);

    axiosInstance
    .post(`api/auth/registration/resend-email/`, {
      email: formData.email,
          })
    .then((res) => {
        setMessage(t("resend_success", { ns: 'register' }));
        setSuccess(true);
    }).catch((err) => {
        setMessage(t("resend_error", { ns: 'register' }));
        setError(true);
    });
};

const [message, setMessage] = useState("");
const [success, setSuccess] = useState(false);
const [error, setError] = useState(false);

  const { t } = useTranslation(["login", "common", "register"]);

  return (
    <div className="page-wrap login-wrapper">
      <div className="login">
        <h2>{t("title")}</h2>
        <InputField
          name="email"
          handleChange={handleChange}
          placeholder={t("email-hint")}
          value={formData.email}
          icon={<PersonIcon />}
        />
        <InputField
          name="password"
          handleChange={handleChange}
          placeholder={t("password-hint")}
          value={formData.password}
          type="password"
          icon={<KeyIcon />}
        />
        {/*<CheckBoxField text={t("remember")} direction="row" /> */}
        <Button
          onClick={handleSubmit}
          color="primary"
          text={t("login")}
          className="login-button"
        />

        {(() => {
          if (showError) {
            return (
              <div>
                <br />
                <Alert severity="error">
                  <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                  {errorMessage}

                  { showValidation && <div>
                     <a href="#" onClick={resend}>
                        {t("resend_mail", { ns: 'register' })}
                        </a>
                        </div>
                    }
                     
                </Alert>
              </div>
            );
          }
        })()}

        <br/>

        { success && <Alert severity="success">
                    <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                        <div className="display-linebreak">
                            {message}
                       
                        </div>
                    
                    </Alert>}


                        { error && <Alert severity="error">
                    <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                        <div className="display-linebreak">
                            {message}
                       
                        </div>
                    
                    </Alert>}


        <a href="/password/reset">{t("forgotten_pass")}</a>

        <br />
        <Button
          onClick={onRegister}
          color="primary"
          text={t("register")}
          className="login-button"
        />
        <p>{t("alternative")}</p>

        {/* <FbLogin
          appId="494690895323264"
          fields="first_name, last_name, email"
          callback={responseFacebook}
      
        /> */}
        <div className="social-login">
          <FbLogin
            appId="1322846145008621"
            fields="first_name, last_name, email"
            callback={responseFacebook}
            cssClass="fb-button"
            icon="fa-facebook"
          />

          <GoogleLogin
            clientId="196596446612-6e8g7gcde4n26v5n3ekjphi9qev9vnpk.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
            cssClass="gg-button"
          />
        </div>
     
      </div>
    </div>
  );
};

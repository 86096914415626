import React, { useContext, useMemo } from 'react';
import { SmallInfoBox } from '../small-info-box/small-info-box';
import { CameraAltOutlined } from '@mui/icons-material';
import { ConfigContext } from '../../../../contexts/config-context';
import { useTranslation } from "react-i18next";


import defaultImage from '../../../../assets/images/home.jpg';

export const ListingImageData = ({ listingCardData, priceDateVisible = true }) => {
    const { api } = useContext(ConfigContext);
    const { t } = useTranslation(["listing"]);
    

    const { photos, listing_type, promoted, promoted_value, additionalInfo, price, area, date_created, user } = listingCardData;

    const coverImage = useMemo(() => {
        let numOfPics = photos?.length || 0;
        if (numOfPics > 0) {
            return `${api.host}:${api.port}${photos[0].photo.ratios.null.sources['image/webp']['500']}`;
        }
        return `${api.host}:${api.port}/media/No_Image_Available.jpg`;
    }, [photos, api]);

    const pricePerM2 = useMemo(() => {
        return Math.ceil(price / area);
    }, [price, area]);

    return (
        <div className='listing-card-image-panel'
            style={{ backgroundImage: `url(${coverImage})`, backgroundSize: 'cover' }}>
            <div className="flex column a-start jc-end full-height">
                <div className='flex row a-start jc-sb full-width full-height'>
                    <SmallInfoBox label={listing_type.label} color='orange-b' />
                    <div>
                        {promoted &&
                            <SmallInfoBox label={promoted_value.label} color='green-b' />
                        }
                        <SmallInfoBox label={`${pricePerM2}€/m2`} color='green-b' />
                        <SmallInfoBox label={user.user_type.label} color='green-b' />
                    </div>
                </div>
                {additionalInfo && 
                    <SmallInfoBox className='flex row a-end jc-end full-width' label={additionalInfo} color='blue-b' style={{ marginBottom: "70px" }} />
                }
            </div>
            <div className='listing-card-transparent-back flex row a-center jc-sb'>
                <div className='flex row a-center jc-start'>
                    <CameraAltOutlined />
                    <p className='pics-num'>{photos?.length || 0}</p>
                </div>
                {priceDateVisible &&
                    <div className='flex column a-end jc-center'>
                        <SmallInfoBox label={`${price}€`} color='orange-b big' />
                        <p className='small-name'>{t('date_published')} {new Date(date_created).toLocaleDateString('sr-SP')}</p>
                    </div>
                }
            </div>
        </div>
    )
};

import React from 'react';

export const ImageTitleButton = ({ className = "", title, backgroundImage, onClick }) => {
    const combinedClass = `image-title-button-wrapper ${className} flex row a-center jc-center clickable`;

    return (
        <div 
            className={combinedClass}
            style={{ backgroundImage: `url(${backgroundImage})` }}
            onClick={onClick}>
            <p className='bold uppercase big white'>{title}</p>
        </div>
    );
}
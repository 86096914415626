import "./App.css";
import React, { useContext, useState, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { userEventEmitter } from './eventEmitter';
import { MainPage } from "./pages/main-page/main-page";
import { Login } from "./pages/login/login";
import { Register } from "./pages/register/register";
import { ConfirmMail } from "./pages/confirm-mail/confirm-mail";
import { InformationPage } from "./pages/information-page/information-page";
import { ResetPassword } from "./pages/password/reset/reset";
import { ResetPasswordConfirm } from "./pages/password/reset_confirm/reset_confirm";

import { Footer } from "./components/shared-components/footer/footer";
import { MainHeader } from "./components/shared-components/main-header/main-header";
import { ConfigContext } from "./contexts/config-context";
import { Navigation } from "./components/navigation/navigation";
import { NewListing } from "./pages/new-listing/new-listing";
import { ListingsListPage } from "./pages/listings-list-page/listings-list-page";
import { NewListingSuccess } from "./pages/new-listing-success/new-listing-success";
import { MainFilterMenu } from "./components/shared-components/search/main-filter-menu/main-filter-menu";

import Background from "./assets/images/homebck.png";
import  MainSearchBox  from "./components/shared-components/search/main-search-box/main-search-box";
import { UserPanel } from "./pages/user-panel/user-panel";
import { UserNavigation } from "./components/user-navigation/user-navigation";
import { ContactSupport } from "./pages/user-panel/components/contact-support/contact-support";
import { Announcements } from "./components/shared-components/layout/announcements/announcements";
import { ListingPage } from "./pages/listing-page/listing-page";
import { ListingNotFound } from "./pages/listing-not-found/listing-not-found";
import { TransactionPage } from "./pages/transaction-page/transaction-page";
import { UserInbox } from "./pages/user-panel/components/user-inbox/user-inbox";
import { UserNotificationList } from "./pages/user-panel/components/user-notification-list/user-notification-list";
import { UserData } from "./pages/user-panel/components/user-data/user-data";
import { NewListingData } from "./pages/new-listing-data/new-listing-data";
import { gapi } from "gapi-script";
import {AdsList} from "./components/ads-list/ads-list";
import {ErrorBoundary} from "./components/error-boundary/error-boundary";
import { Statistics } from "./pages/statistics/statistics";
import { UserContext } from "./contexts/userContext";
import { useTranslation } from "react-i18next";
import  ResponsiveNavigation  from "./components/responsive-navigation/responsive-navigation";
import { ResponsiveFilterMenu } from "./components/responsive-filter-menu/responsive-filter-menu";
import { InterfaceContext } from "./contexts/interface-context";
import { AdvertismentOfferPage } from "./pages/advertisment-offer/advertisment-offer-page";
const img1 = require("./assets/images/home.jpg");
const img2 = require("./assets/images/saveti.jpg");
const img3 = require("./assets/images/dokumentacija.jpg");
const img4 = require("./assets/images/kredit.jpg");


export const App = () => {
  const { sideNav } = useContext(ConfigContext);
  const { userNavigation } = useContext(ConfigContext);
  const { listingTypeCode } = useContext(ConfigContext);
  const { t } = useTranslation(["home"]);
  const headerItems = sideNav.filter((item) => item.header);
  const location = useLocation();
  const hasImageHeader = location.pathname === "/";
  const userPaths = [
    ...userNavigation.map((obj, index) => obj.path),
    "/korisnik",
  ].filter((path) => path !== "/login");

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "196596446612-6e8g7gcde4n26v5n3ekjphi9qev9vnpk.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  // console.log(userPaths);

  // const [user, setUser] = useContext(UserContext);

  // const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const {responsiveMenuVisible,responsiveMenuOpen,filterMenuOpen, filterMenuVisible} = useContext(InterfaceContext);

  
  
  useEffect(() => {
    userEventEmitter.on('logout', () => {
        setUser(null);
    });

    // Cleanup to avoid memory leaks
    return () => {
        userEventEmitter.removeAllListeners('logout');
    };
}, [setUser]);


  return (
    <div className="App">
     
      <Suspense fallback={null}>
      <UserContext.Provider value={{ user, setUser }}>
      <ErrorBoundary>
      <MainHeader shadow={!hasImageHeader} />
      {responsiveMenuVisible && responsiveMenuOpen && <ResponsiveNavigation/>}
      {filterMenuOpen && filterMenuVisible &&  <ResponsiveFilterMenu/>}
      {hasImageHeader && (
        <div
          className="main-page-image-search"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="image-dark-overlay flex row a-center jc-center">
            <p className="white bold">
             {t('title_main')}
            </p>
            <MainSearchBox className="main-page-search-box" />
          </div>
        </div>
      )}
      <div className="app-content-wrap flex row jc-sb a-start">
        <div className={`filter-sidebar-wrapper ${hasImageHeader && "lower"}`}>
          {userPaths.includes(location.pathname) ? (
            <UserNavigation />
          ) : !filterMenuVisible ? (
            <MainFilterMenu />
          ) : <div></div>}
         <AdsList ></AdsList>
        </div>
        <div className="content-wrap">
          
            <Routes>
              <Route exact path="/" element={<MainPage />} />
              <Route path="/login" index element={<Login />} />
              <Route path="/register" index element={<Register />} />
              <Route path="/confirm_mail/:key" index element={<ConfirmMail />} />
              <Route path="/password/reset" index element={<ResetPassword />} />
              <Route path="/password/reset/confirm/:uid/:token" index element={<ResetPasswordConfirm />} />
              <Route path="/postavi-oglas" element={<NewListing />}>
                
                <Route path="/postavi-oglas/prodaja" element={ user? <NewListingData type={listingTypeCode.sale} /> : <Login />} />
                <Route path="/postavi-oglas/izdavanje" element={ user? <NewListingData type={listingTypeCode.rent}/> : <Login />} />
                <Route path="/postavi-oglas/stan-na-dan" element={ user? <NewListingData type={listingTypeCode.short_term} /> : <Login />} />
                <Route path="/postavi-oglas/azuriranje/:id" element={ user? <NewListingData /> : <Login /> } />
              </Route>
              
              <Route path="/korisnik" element={user? <UserPanel /> : <Login />}>
                <Route index path="/korisnik/podaci" element={<UserData />} />
                <Route
                  path="/korisnik/oglasi"
                  element={
                    <ListingsListPage source="active" title="active" editBar={true} key={window.location.pathname}/>
                  }
                />
                <Route
                  path="/korisnik/praceni"
                  element={
                    <ListingsListPage
                      source="favorite"
                      title="favorite"
                      editBar={true}
                      listingFollow={true}
                      key={window.location.pathname}
                    />
                  }
                  
                />
                <Route
                  path="/korisnik/neaktivni-oglasi"
                  element={
                    <ListingsListPage
                      source="inactive"
                      title="inactive"
                      inactiveListing={true}
                      editBar={true}
                      listingFollow={false}
                      key={window.location.pathname}
                    />
                  }
                />
                <Route path="/korisnik/poruke" element={<UserInbox />} />
                <Route
                  path="/korisnik/obavestenja"
                  element={<Announcements />}
                />
                <Route path="/korisnik/kontakt" element={<ContactSupport />} />
                <Route path="/korisnik/kredit" element={<TransactionPage />} />
                <Route
                  path="/korisnik/oglasavanje"
                  element={
                    <AdvertismentOfferPage/>
                  }
                />
                <Route path="/korisnik/statistika/:id" element={<Statistics />} />
                <Route path="*" element={<UserData />} />
              </Route>
              <Route
                path="/prodaja"
                element={
                  <ListingsListPage
                    title="sale"
                    editBar={false}
                    className="page-wrap"
                    key={window.location.pathname}
                    source="search"
                  />
                }
              />
              <Route
                path="/izdavanje"
                element={
                  <ListingsListPage
                    title="rent"
                    editBar={false}
                    key={window.location.pathname}
                    source="search"
                    className="page-wrap"
                  />
                }
              />
              <Route
                path="/stan-na-dan"
                element={
                  <ListingsListPage
                    title="short_term"
                    editBar={false}
                    key={window.location.pathname}
                    source="search"
                    className="page-wrap"
                  />
                }
              />
              <Route path="/postavljen-novi" element={user? <NewListingSuccess /> : <MainPage />} />
              <Route path="/nepostojeci-oglas" element={<ListingNotFound />} />

              <Route path="/oglas/:id" element={<ListingPage />} />

              <Route
                path="/uputstvo-prodavci"
                element={
                  <InformationPage
                    title="Informacije za prodavce"
                    backgroundImage={img2}
                  />
                }
              />
              <Route
                path="/uputstvo-zakupci"
                element={
                  <InformationPage
                    title="Informacije za zakupce"
                    backgroundImage={img3}
                  />
                }
              />
              <Route
                path="/privacy"
                element={
                  <InformationPage
                    title="Informacije za zakupce"
                    backgroundImage={img3}
                  />
                }
              />
              <Route
                path="/terms"
                element={
                  <InformationPage
                    title="Informacije za zakupce"
                    backgroundImage={img3}
                  />
                }
              />
              <Route
                path="/upustvo-oglasavanje"
                element={
                  <InformationPage
                    title="Uputstvo za oglasavanje"
                    backgroundImage={img4}
                  />
                }
              />
              <Route
                path="/uputstvo"
                element={
                  <InformationPage title="Uputstvo" backgroundImage={img1} />
                }
              />
              {/* <Route exact path="/prodaja" element={<MaiprodnPage />}/>
                      <Route exact path="/izdavanje" element={<MainPage />}/>
          <Route exact path="/stan-na-dan" element={<MainPage />}/>*/}
              <Route
                path="/trazis-nekretninu"
                element={
                  <InformationPage
                    title="Tražiš nekretninu"
                    backgroundImage={img4}
                  />
                }
              />
               <Route
                path="/saveti"
                element={
                  <InformationPage
                    title="Saveti"
                    backgroundImage={img4}
                  />
                }
              />
               <Route
                path="/dokumentacija"
                element={
                  <InformationPage
                    title="Dokumentacija"
                    backgroundImage={img4}
                  />
                }
              />
               <Route
                path="/kredit"
                element={
                  <InformationPage
                    title="Kupovina na kredit"
                    backgroundImage={img4}
                  />
                }
              />
              {/* 404 */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
         
        </div>
        <div className={`side-menu-ads-sidebar-wrapper`}>
          <Navigation navItems={headerItems} direction="column" />
          {/* Space for ads. Map existing ads
                <Ads/> */}

        <AdsList ></AdsList>
        </div>
      </div>
      <Footer />
      </ErrorBoundary>
      </UserContext.Provider>
      </Suspense>
     
    </div>
  );
};

export default App;

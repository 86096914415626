import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import Button from "../../../components/shared-components/buttons/button/button";
import InputField from '../../../components/shared-components/form/input-field/input-field';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UserAPI from "../../../axios/user";
import { useTranslation } from "react-i18next";

export const ResetPasswordConfirm = () => {
    const { uid, token } = useParams();
    const { t } = useTranslation(["login", "common", "profile"]);
    
    const [formDataPassword, updateFormDataPassword] = useState({
        uid,
        token,
        new_password1: "",
        new_password2: ""
    });

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        updateFormDataPassword(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const handleSubmit = () => {
        setSuccess(false);
        setError(false);

        UserAPI.passwordResetConfirmation(formDataPassword)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <div className="page-wrap">
            <InputField 
                name="new_password1"
                key="user-data-nps"
                type="text" 
                label={t("new_password", { ns: 'profile' })}
                position="column"
                handleChange={handleChangePassword}
            />

            <InputField 
                name="new_password2"
                key="user-data-nps2"
                type="text" 
                label={t("confirm_password", { ns: 'profile' })}
                position="column"
                handleChange={handleChangePassword}
            />

            <Button
                onClick={handleSubmit}
                color="primary"
                text={t("confirm_mail_button")}
                className="login-button"
            />

            {error && 
                <Alert severity="error">
                    <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                    {t("reset_password_err")}
                </Alert>
            }

            {success && 
                <Alert severity="success">
                    <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                    {t("reset_password_success_confirm")}
                </Alert>
            }
        </div>
    );
};


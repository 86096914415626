import React from 'react';
import { useTranslation } from "react-i18next";
import { PromotedListings } from '../../components/promoted-listings/promoted-listings';

export const ListingNotFound = ({className}) => {
    const { t } = useTranslation(["common"]);

    return(
        <div className={`page-wrap not-found-wrap flex column a-center jc-center ${className}`}>
           
           <h2>{t('listing_not_found')}</h2>

           <div className='not-found-message flex column a-center jc-center '>
                <p className='blue bold'>{t('search_other')}</p>
              
            </div>
             <PromotedListings />

        </div>
    )
}
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../config';

const baseURL = `${config.api.host}:${config.api.port}/`;

const googleLogin = (accesstoken) => {
	
	return axios
		.post(`${baseURL}api/auth/google/`, {
			access_token: accesstoken,

		})
		/*.then((res) => {
			localStorage.setItem('access_token', res.data.access_token);
			localStorage.setItem('refresh_token', res.data.refresh_token);
			localStorage.setItem("user", JSON.stringify(res.data.user));
			
		});*/
};

export default googleLogin;
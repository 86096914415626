import axiosInstance from './axios';

const MessageAPI = {

    
    //----------------------------------------------------------------
    getSentMessages : (page) => {
        return axiosInstance.get(`my_messages/sent?page=${page}`);
    },
    //----------------------------------------------------------------
    getReceivedMessages : (page) => {
        return axiosInstance.get(`my_messages/received?page=${page}`);
    },
    //----------------------------------------------------------------
    sendMessage : (data) => {
        return axiosInstance.post('message', data);
    },
    //----------------------------------------------------------------
    markReadMessage : (id) => {
        return axiosInstance.put(`message/${id}`, {});
    },
    //----------------------------------------------------------------
    deleteMessage : (id) => {
        return axiosInstance.delete(`message/${id}`);
    },
    //----------------------------------------------------------------
    countUnreadMessage : (id) => {
        return axiosInstance.get('messages/count');
    },
    //----------------------------------------------------------------
    blockUser : (id) => {
        return axiosInstance.post(`block/${id}`, {});
    },
    //----------------------------------------------------------------
    unblockUser : (id) => {
        return axiosInstance.delete(`block/${id}`);
    },
    //----------------------------------------------------------------
    getBlockedUsers : () => {
        return axiosInstance.get('blocked');
    },

}

export default MessageAPI;
import React, { useState, useCallback, useMemo } from 'react';
import Button from '../button/button';
import { useTranslation } from "react-i18next";

export const EditButtonsWrap = ({ onConfirm, onCancel }) => {
    const [editActive, setEditActive] = useState(false);
    const { t } = useTranslation(["common"]);

    const handleCancel = useCallback(() => {
        onCancel();
        setEditActive(false);
    }, [onCancel]);

    const handleConfirm = useCallback(() => {
        onConfirm();
        setEditActive(false);
    }, [onConfirm]);

    const changeText = useMemo(() => t("change"), [t]);
    const cancelText = useMemo(() => t("cancel"), [t]);
    const saveText = useMemo(() => t("save"), [t]);

    return (
        <div className='edit-buttons-wrap'>
            {editActive ? (
                <div className='flex row a-center jc-sb'>
                    <Button text={cancelText} onClick={handleCancel} color="blue-b" />
                    <Button text={saveText} onClick={handleConfirm} color="orange-b" />
                </div>
            ) : (
                <Button text={changeText} onClick={() => setEditActive(true)} color="orange-b" />
            )}
        </div>
    );
}

import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { OfferItem } from './components/offer-item/offer-item';
import { OfferPackage } from './components/offer-package/offer-package';
import { useTranslation } from "react-i18next";
import PackageAPI from '../../axios/package';
import i18n from "i18next";

export const AdvertismentOfferPage = () => {
    const { t } = useTranslation(["offers"]);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(1);
    const [packageList, setPackageList] = useState([]);
    const [selected, setSelected] = useState(1);

    const fetchGroups = useCallback(async () => {
        try {
            const response = await PackageAPI.getPackageGroups(i18n.language);
            setGroups(response.data);
        } catch (err) {
            console.log(err);
        }
    }, [t]);

    const fetchPackageList = useCallback(async () => {
        try {
            const response = await PackageAPI.getPackageList(selectedGroup, i18n.language);
            setPackageList(response.data);
        } catch (err) {
            console.log(err);
        }
    }, [selectedGroup, t]);

    useEffect(() => {
        fetchGroups();
        fetchPackageList();
    }, [fetchGroups, fetchPackageList]);

    const handlePackageClick = (index) => setSelected(index);

    return (
        <div className='advertisment-offer-page'>
            <Stack direction='row' alignItems='center' justifyContent={'center'} sx={{ width: '100%', marginBottom: "30px" }}>
                <Typography className='blue' sx={{ fontWeight: 'bold' }}>{t("title")}</Typography>
            </Stack>
            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Stack direction='row' alignItems='center' justifyContent={'center'}>
                    <Typography sx={{ fontWeight: 'bold' }}>{t("subtitle")}</Typography>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent={'flex-start'} gap={'10px'} sx={{ flexWrap: 'wrap', margin: "20px 0" }}>
                    {groups.map((item, index) => (
                        <OfferItem
                            onClick={() => setSelectedGroup(item.id)}
                            title={item.name}
                            selected={item.id === selectedGroup}
                            key={`offer-group-${index}`}
                        />
                    ))}
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent={'flex-start'} gap={'10px'} sx={{ flexWrap: 'wrap', margin: "20px 0" }}>
                    {packageList.map((item, index) => (
                        <OfferPackage key={`item-index-${index}`} item={item} index={index} selected={index === selected} onPackageClick={handlePackageClick} />
                    ))}
                </Stack>
            </Stack>
        </div>
    )
}

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../config';

const baseURL = `${config.api.host}:${config.api.port}/`;

const facebookLogin = (accesstoken) => {
	
	return axios
		.post(`${baseURL}api/auth/facebook/`, {
			access_token: accesstoken,
		
		})
	
};

export default facebookLogin;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import StatisticsAPI from '../../axios/statistics';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const Statistics = () => {
    const { id: listingID } = useParams();
    const { t } = useTranslation(["listing"]);

    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const response = await StatisticsAPI.getStatisticsData(listingID);
                const labels = response.data.map(item => item.date);
                const values = response.data.map(item => item.count);

                setChartData({
                    labels,
                    datasets: [{
                        data: values,
                        label: t('num_views'),
                        borderColor: '#3333ff',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        fill: true
                    }]
                });
            } catch (error) {
                console.log(error);
            }
        };

        fetchStatistics();
    }, [listingID, t]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: t('num_views_title'),
            },
        },
    };

    return (
        <div className='page-wrap new-listing-wrapper flex column a-center jc-center'>
            {chartData && <Bar options={options} data={chartData} />}
        </div>
    );
};

export default Statistics;

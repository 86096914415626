import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { Close, ExpandMore } from '@mui/icons-material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../contexts/config-context';
import { InterfaceContext } from '../../contexts/interface-context';
import { Navigation } from '../navigation/navigation';
import  Button  from '../shared-components/buttons/button/button';
import ReactFlagsSelect from "react-flags-select";
import { ProfileNavigation } from '../profile-navigation/profile-navigation';
import { UserNavigation } from '../user-navigation/user-navigation';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

const ResponsiveNavigation = ({ children }) => {
    const { openResponsiveMenu, closeResponsiveMenu } = useContext(InterfaceContext);
    const { i18n, t } = useTranslation(["common"]);
    const [selected, setSelected] = useState(() => { return i18n.language === "sr" ? "RS" : "GB" });

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18next.changeLanguage("sr");
        }
    }, []);

    const handleLanguageChange = useCallback((val) => {
        setSelected(val);
        if (val === "GB")
            i18n.changeLanguage("en");
        else if (val === "RS")
            i18n.changeLanguage('sr');
    }, [i18n]);

    const handleCloseMenu = useCallback(() => {
        closeResponsiveMenu();
    }, [closeResponsiveMenu]);

    const { sideNav } = useContext(ConfigContext);
    const sideItems = sideNav.filter((item) => item.header);
    const { navItems } = useContext(ConfigContext);
    const headerItems = navItems.filter(item => item.header);

    return (
        openResponsiveMenu &&
        <div className={`responsive-navigation-wrap ${openResponsiveMenu && 'open'}`}>
            <div className='responsive-navigation-top'>
                <div className="responsive-navigation">
                    <Close onClick={handleCloseMenu} sx={{ cursor: 'pointer' }} />
                </div>

                <ReactFlagsSelect
                    className={'language-select'}
                    selected={selected}
                    countries={["RS", "GB"]}
                    customLabels={{ GB: "English", RS: "Srpski" }}
                    onSelect={(code) => handleLanguageChange(code)}
                />
                <Navigation
                    navItems={headerItems}
                    direction='column' />
                <Link to={'postavi-oglas'}>
                    <Button text='Postavi oglas' color='secondary' className={'resp-menu-btn'} />
                </Link>
                <Accordion sx={{ backgroundColor: "transparent", width: "200px", color: "#0073F0", border: "1px solid #bebebe", borderRadius: '5px', boxShadow: 'unset', marginTop: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ border: 'unset', margin: 'unset', minHeight: 'unset', height: "39px" }}
                    >
                        <Typography>Moj Panel</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '5px 0px', fontSize: '14px' }}>
                        <UserNavigation />
                    </AccordionDetails>
                </Accordion>
                <Navigation navItems={sideItems} direction="column" />
            </div>
        </div>
    )
}

export default memo(ResponsiveNavigation);

import React, { useContext } from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { ConfigContext } from '../../../contexts/config-context';
import {
    FacebookShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton
} from "react-share";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
} from "react-share";

export function SharePopper({ title = "", id = "", open, anchorEl }) {
    const { website } = useContext(ConfigContext);
    const shareURL = `${website}/oglas/${id}`;

    const shareButtonsData = [
        { ButtonComponent: FacebookShareButton, IconComponent: FacebookIcon },
        { ButtonComponent: WhatsappShareButton, IconComponent: WhatsappIcon },
        { ButtonComponent: ViberShareButton, IconComponent: ViberIcon },
        { ButtonComponent: TwitterShareButton, IconComponent: TwitterIcon },
        { ButtonComponent: FacebookMessengerShareButton, IconComponent: FacebookMessengerIcon, extraProps: { appId: "521270401588372" } }
    ];

    return (
        <div>
            <Popper open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            {shareButtonsData.map((data, idx) => (
                                <div key={idx}>
                                    <data.ButtonComponent url={shareURL} title={title} {...data.extraProps}>
                                        <data.IconComponent size={32} round />
                                    </data.ButtonComponent>
                                </div>
                            ))}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

import axiosInstance from './axios';

const LocationAPI = {

    getListOfPlaces : () => {
        return axiosInstance.get('places', { });
    },

    //----------------------------------------------------------------
    getListOfSubplaces : (place_id) => {
        return axiosInstance.get(`subplaces/${place_id}`, { });
    },
    //----------------------------------------------------------------
    getListOfLocations : (subplace_id) => {
        return axiosInstance.get(`locations/${subplace_id}`, { });
    },
    //----------------------------------------------------------------
    getLocationCoordinates : (location_id) => {
        return axiosInstance.get(`location/coordinates/${location_id}`, { });
    },
    //----------------------------------------------------------------
    getAmenities : (type, lat, lon, distance) => {
        return axiosInstance.get(`${type}/${lat}/${lon}/${distance}`, { });
    },
    //----------------------------------------------------------------
    getTreeLocations : (place_id) => {
        return axiosInstance.get(`tree/locations/${place_id}`, { });
    }


}

export default LocationAPI;
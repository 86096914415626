import React, { memo, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  title = "",
  message = "",
  open,
  setOpen,
  onConfirm = () => {},
  onCancel = () => {}
}) => {
  const { t } = useTranslation(["common"]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    onCancel();
  }, [setOpen, onCancel]);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    onConfirm();
  }, [setOpen, onConfirm]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t("cancel")}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(ConfirmDialog);

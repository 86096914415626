import React from 'react';

import { BedOutlined, DeviceThermostatOutlined, RoundedCornerOutlined, ShowerOutlined, StairsOutlined,  } from '@mui/icons-material';
import { IconText } from '../../../shared-components/layout/icon-text/icon-text';

export const ListingCardAmenities = ({listingCardData, fullWidth=true, jc='jc-sb'}) => {

  
    return(
        <div className={`listing-card-amenities flex row a-center ${jc} ${fullWidth && 'full-width'}`}>
            <IconText className='small bold' icon={ <RoundedCornerOutlined/>} text={`${listingCardData.area} m2`}/>
            {/* <IconText className='small bold' icon={<DeviceThermostatOutlined/>} text={listingCardData.heating}/> */}
            {/* <IconText className='small bold' icon={<RoundedCornerOutlined/>} text="52 m2"/> */}
            <IconText className='small bold' icon={<BedOutlined/>} text={listingCardData.num_rooms}/>
            <IconText className='small bold' icon={<ShowerOutlined/>} text={listingCardData.num_bathrooms}/>
            <IconText className='small bold' icon={ <StairsOutlined/>} text={`${listingCardData.floor} / ${listingCardData.max_floor}`}/>
        </div>
    );
}
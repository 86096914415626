import React from "react";
//import { ConfigContext } from '../../contexts/config-context';

export const AdItem = ({data}) => {
    
    //const { api } = useContext(ConfigContext);

   

  
    return(
        <div className="mock-ad">
            <a href={data.link}>
             <img src={data.photo} />
             </a>
        </div>
    )
}
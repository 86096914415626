import React, { memo } from 'react';
import { BigSmallTitle } from '../shared-components/layout/big-small-title/big-small-title';
import { ListingImageData } from '../shared-components/layout/listing-image-data/listing-image-data';
import { SmallInfoBox } from '../shared-components/layout/small-info-box/small-info-box';
import { ListingCardAmenities } from './components/listing-card-amenities/listing-card-amenities';
import { ListingLovedBy } from './components/listing-loved-by/listing-loved-by';
import { useNavigate } from 'react-router-dom';

const ListingCard = ({ listingCardData }) => {
    const { id, premium, place, location, luxurious } = listingCardData;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/oglas/${id}`, {});
    }

    const cardClass = `listing-card-wrapper clickable ${premium ? 'premium' : ''}`;

    return (
        <div onClick={handleClick} className={cardClass}>
            <ListingImageData listingCardData={listingCardData} />
            <div className='listing-card-info'>
                <div className="flex row a-start jc-sb">
                    <BigSmallTitle big={place.label} small={location.label} />
                    <div className="flex column a-center jc-start">
                        <ListingLovedBy listing={id} key={`listing-loved-by-${id}`} />
                        {luxurious && <SmallInfoBox label='lux' color='purple-b' />}
                    </div>
                </div>
                <ListingCardAmenities listingCardData={listingCardData} />
            </div>
        </div>
    );
}

export default memo(ListingCard);
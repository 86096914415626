import React, { useContext, useState, useEffect, useCallback, memo } from 'react';
import { Search } from '@mui/icons-material';
import Button from '../../buttons/button/button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Autocomplete, Input, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ConfigContext } from '../../../../contexts/config-context';
import ListingAPI from '../../../../axios/listing';
import MiscAPI from '../../../../axios/misc';
import LocationAPI from '../../../../axios/locations';
import config from '../../../../config.js';
import  SelectField  from '../../form/select-field/select-field';
import i18n from "i18next";


const MainSearchBox = ({ className, formSearchData = config.searchData, updateFormSearchData, handleSearch }) => {
    const { t } = useTranslation(["search", "common"]);
    const { floor_options, num_rooms_options } = useContext(ConfigContext);

    const [numOfListings, setNumOfListings] = useState(0);
    const [places, setPlaces] = useState([]);
    const [floorOptions, setFloorOptions] = useState([]);
    const [numRoomsOptions, setNumRoomsOptions] = useState([]);
    const [listingTypeOptions, setListingTypeOptions] = useState([]);
    const [floorOptionsMax, setFloorOptionsMax] = useState([]);
    const [numRoomsOptionsMax, setNumRoomsOptionsMax] = useState([]);

   

    useEffect(() => {
        const f = [{ id: null, label: t("floor_from", { "ns": "search" }) }, ...floor_options];
        const n = [{ id: null, label: t("room_from", { "ns": "search" }) }, ...num_rooms_options];
        const fm = [{ id: null, label: t("floor_to", { "ns": "search" }) }, ...floor_options];
        const nm = [{ id: null, label: t("room_to", { "ns": "search" }) }, ...num_rooms_options];

        setFloorOptions(f);
        setNumRoomsOptions(n);
        setFloorOptionsMax(fm);
        setNumRoomsOptionsMax(nm);

        const fetchData = async () => {
            const [places, listingTypes] = await Promise.all([
              
                LocationAPI.getListOfPlaces(),
                MiscAPI.getListingTypeOptions(i18n.language)
            ]);

            
            setPlaces(places.data);
            setListingTypeOptions(listingTypes.data);
        }

        fetchData();

    }, [t, floor_options, num_rooms_options]);

    const changePlace = (e, newValue) => {
        updateFormSearchData({
			...formSearchData,
			"place": newValue,
		});
        
       
    }

    const handleChange = useCallback((e) => {
        updateFormSearchData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }, [updateFormSearchData]);

    const handleChangeNumber = useCallback((e) => {
        const result = e.target.value.trim().replace(/[a-zA-Z_?!$%@#&*\/\-+<>\[\]{}()]*/g, '');
        updateFormSearchData(prevState => ({
            ...prevState,
            [e.target.name]: result
        }));
    }, [updateFormSearchData]);



    useEffect(() => {
        ListingAPI.getListingsSearchCount(formSearchData).then(
            function (response){
                setNumOfListings(response.data.total_listings);
              
            }
        ).catch(err => {
            console.log(err);
        }); 
    }, [formSearchData]);

    return (
        <div className={`main-search-box-wrap ${className}`}>
            <p className='orange bold'>{t('search')}</p>
            <Autocomplete
                    disablePortal
                    value={formSearchData.place}
                    key="main-search-box-places"
                    onChange={(e, newValue) => changePlace(e, newValue)}
                    id="combo-box-locations"
                    options={places}
                    sx={{width:"250px",marginBottom:"10px"}}
                    renderInput={(params) => <TextField {...params} label={t("city")} />}
                    />        

<div className='main-search-box-type-wrap'>
            <SelectField 
                    
                    name="listing_type"
                    key="main-search-box-min-rooms"
                    className='row type-select'
                        value={formSearchData.listing_type}
                        handleChange={handleChange}
                        options={listingTypeOptions}
                        sx={{width:"250px",marginBottom:"10px"}}
                        />   

</div>
           <div className='main-search-box-selects-wrap'>

               {/* 
                <RadioGroup
                key="main-search-box-type"
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="listing_type"
                value={formSearchData.listing_type}
                onChange={handleChange}
                
                className='radio-group-wrap'
                >


                    {listingTypeOptions.map((item, index) => (
                    <FormControlLabel sx={{width:"100px"}} value={item.id} control={<Radio />} label={item.label} key={`main-search-box-type-option-${index}`}/>
                ))}

            
            
            
            </RadioGroup>
*/}
           
         </div>
           

            
            <div className='main-search-box-selects-wrap'>
                        <Input 
                        sx={{width:"120px", border: "1px solid #bebebe", borderRadius:"5px", padding: '2px', marginBottom: "5px"}}
                        name="min_price"
                        key="main-search-box-min-price"
                         value={formSearchData.min_price}
                         onChange={handleChangeNumber}
                         placeholder={t('price_from')}/>

                <Input 
                 sx={{width:"120px", border: "1px solid #bebebe", borderRadius:"5px", padding: '2px', marginBottom: "5px"}}
                key="main-search-box-max-price"
                name="max_price"
                value={formSearchData.max_price}
                onChange={handleChangeNumber}
                placeholder={t('price_to')}/>
           
            </div>
            <div className='main-search-box-selects-wrap'>
               
                 <Input 
                 sx={{width:"120px", border: "1px solid #bebebe", borderRadius:"5px", padding: '2px', marginBottom: "5px"}}
                 type='outlined'
                 name="min_area"
                 key="main-search-box-min-area"
                  value={formSearchData.min_area}
                  onChange={handleChangeNumber}
                  placeholder={t('area_from')}/>
                <Input 
                 sx={{width:"120px", border: "1px solid #bebebe", borderRadius:"5px", padding: '2px', marginBottom: "5px"}}
                 type='outlined'
                 name="max_area"
                 key="main-search-box-max-area"
                  value={formSearchData.max_area}
                  onChange={handleChangeNumber}
                  placeholder={t('area_to')}/>
                
            </div>
            <div className='main-search-box-selects-wrap'>
                <SelectField 
                    translate_namespace={"common"} placeholder={t('room_from')} 
                    name="min_rooms"
                    key="main-search-box-min-rooms"
                    className='row main-search-select'
                        value={formSearchData.min_rooms}
                        handleChange={handleChange}
                        options={numRoomsOptions}/>
                <SelectField placeholder={t('room_to')} 
                name="max_rooms"
                className='row main-search-select'
                key="main-search-box-max-rooms"
                translate_namespace={"common"}
                value={formSearchData.max_rooms}
                handleChange={handleChange}
                options={numRoomsOptionsMax}/>
            </div>
            <div className='main-search-box-selects-wrap'>
               
                <SelectField placeholder={t('floor_from')} 
                        name="min_floor"
                        className='row main-search-select'
                        key="main-search-box-min-floor"
                        translate_namespace={"listing"}
                         value={formSearchData.min_floor}
                         handleChange={handleChange}
                         options={floorOptions}/>
                <SelectField placeholder={t('floor_to')} 
                
                translate_namespace={"listing"}
                        name="max_floor"
                        className='row main-search-select'
                        key="main-search-box-max-floor"
                         value={formSearchData.max_floor}
                         handleChange={handleChange}
                         options={floorOptionsMax}/>
            </div>
            <Button className='search-button' key="main-search-box-button" onClick={handleSearch} color='secondary' icon={<Search/>} text={t('search_text_1') + numOfListings + t('search_text_2')}/>
       
        </div>
    )
}

export default memo(MainSearchBox);

import React, { useContext, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from '@mui/material';
import SelectField from '../../../../components/shared-components/form/select-field/select-field';
import InputField from '../../../../components/shared-components/form/input-field/input-field';
import SelectMultipleField from '../../../../components/shared-components/form/select-multiple-field/select-multiple-field';
import { ConfigContext } from '../../../../contexts/config-context';
import MiscAPI from '../../../../axios/misc';
import i18n from "i18next";

export const AdditionalData = ({ 
    formDataListing, 
    handleChangeNumber, 
    handleAutocomplete, 
    handleChangeType, 
    handleChangeState, 
    heating, 
    heatingChange 
}) => {
    const { t } = useTranslation(["listing", "common", "profile"]);
    const { floor_options, max_floor_options, num_rooms_options, num_bathrooms_options } = useContext(ConfigContext);
    
    const [heatingOptions, setHeatingOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [floorOptionsData, setFloorOptionsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [heatingRes, typeRes, stateRes] = await Promise.all([
                    MiscAPI.getHeatingOptions(i18n.language),
                    MiscAPI.getPropertyTypeOptions(i18n.language),
                    MiscAPI.getPropertyStateOptions(i18n.language)
                ]);

                setHeatingOptions(heatingRes.data);

                const typeData = [{ id: 0, label: t("choose") }, ...typeRes.data];
                setTypeOptions(typeData);

                const stateData = [{ id: 0, label: t("choose") }, ...stateRes.data];
                setStateOptions(stateData);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };
        fetchData();

        const tempFloors = floor_options.map(option => ({
            ...option,
            label: t(option.label)
        }));
        setFloorOptionsData(prevState => [...prevState, ...tempFloors]);
    }, [i18n.language, t, floor_options]);

    // Memoized version to avoid unnecessary computations
    const floorOptionsForAutocomplete = useMemo(() => {
        return formDataListing.floor 
            ? floorOptionsData.filter(r => r.id === formDataListing.floor)
            : [];
    }, [floorOptionsData, formDataListing.floor]);

    return (
        <div>
            <p className='listing-general-title'>{t("data")}</p>
            <div className='new-listing-item-box'>
                <InputField 
                    placeholder={t("area") + " *"}
                    name="area"
                    handleChange={handleChangeNumber}
                    value={formDataListing.area}
                    additionalInformation="m2"
                />
                <Autocomplete
                    name="num_rooms"
                    disablePortal
                    id="combo-box-locations"
                    options={num_rooms_options}
                    sx={{ width: 300 }}
                    value={formDataListing.num_rooms ? num_rooms_options.find(r => r.id === formDataListing.num_rooms) : null}
                    onChange={(e, newValue) => handleAutocomplete(newValue.id, "num_rooms")}
                    renderInput={(params) => <TextField {...params} label={t("num_rooms") + " *"} />}
                />
                <Autocomplete
                    disablePortal
                    id="combo-box-locations"
                    options={num_bathrooms_options}
                    sx={{ width: 300 }}
                    value={formDataListing.num_bathrooms ? num_bathrooms_options.find(r => r.id === formDataListing.num_bathrooms) : null}
                    onChange={(e, newValue) => handleAutocomplete(newValue.id, "num_bathrooms")}
                    renderInput={(params) => <TextField {...params} label={t("num_bathrooms")+ " *"} />}
                />
            </div>
    
            <div className='new-listing-item-box'>
                <SelectField 
                    name="type"
                    translate={false}
                    label={t("type") + " *"}
                    options={typeOptions}
                    sx={{ width: 300 }}
                    value={formDataListing.property_type}
                    handleChange={handleChangeType}
                />
                <SelectField 
                    name="state"
                    label={t("state") + " *"}
                    translate={false}
                    options={stateOptions}
                    sx={{ width: 300 }}
                    value={formDataListing.property_state}
                    handleChange={handleChangeState}
                />
                <SelectMultipleField 
                    sx={{ width: 300 }}
                    options={heatingOptions}
                    value={heating}
                    label={t("heating")}
                    handleChange={heatingChange}
                />
            </div>
    
            <div className='new-listing-item-box'>
                <Autocomplete
                    disablePortal
                    id="combo-box-locations"
                    options={floorOptionsData}
                    sx={{ width: 300 }}
                    value={floorOptionsForAutocomplete[0]}
                    onChange={(e, newValue) => handleAutocomplete(newValue.id, "floor")}
                    renderInput={(params) => <TextField {...params} label={t("floor")+ " *"} />}
                />
                <Autocomplete
                    disablePortal
                    id="combo-box-locations"
                    options={max_floor_options}
                    sx={{ width: 300 }}
                    value={formDataListing.max_floor ? max_floor_options.find(r => r.id === formDataListing.max_floor) : null}
                    onChange={(e, newValue) => handleAutocomplete(newValue.id, "max_floor")}
                    renderInput={(params) => <TextField {...params} label={t("num_floors")+ " *"} />}
                />
                <InputField 
                    name="bills"
                    value={formDataListing.bills}
                    handleChange={handleChangeNumber}
                    placeholder={t("bills")}
                    additionalInformation="RSD"
                />
                <InputField 
                    name="deposit"
                    value={formDataListing.deposit}
                    handleChange={handleChangeNumber}
                    placeholder={t("deposit")}
                    additionalInformation="RSD"
                />
            </div>
        </div>
    );
    
};

import React from 'react';
import { useLocation } from 'react-router-dom';
import { UserData } from './components/user-data/user-data';
import {Outlet } from "react-router-dom"
export const UserPanel = () =>{
    const location = useLocation();
   
   
    return(
        <div className='page-wrap user-panel-wrap'>
            <div className='user-panel-content'>
            <Outlet/>
             {(location.pathname === '/korisnik') && (location.pathname !== '/korisnik/oglasavanje') &&
                <UserData/>
            }
           
            </div>
            
        </div>
    )
}
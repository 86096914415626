import React, { memo } from 'react';

const Button = ({ text, onClick, color, className, icon }) => {
    const buttonClass = `button-wrapper flex row a-center jc-center ${color || ''} ${className || ''}`;

    return (
        <div className={buttonClass} onClick={onClick}>
            {icon && <div className='button-icon'>{icon}</div>}
            <span>{text}</span>
        </div>
    );
}

export default memo(Button);
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import InputField from '../../components/shared-components/form/input-field/input-field';
import Button from '../../components/shared-components/buttons/button/button';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UserAPI from '../../axios/user';

export const Register = () => {
    const { t } = useTranslation(["register", "common"]);

    const [formData, setFormData] = useState({
        email: "",
        username: "",
        password: "",
        password_confirm: "",
        first_name: "",
        last_name: "",
    });

    const { email, username, password, password_confirm, first_name, last_name } = formData;

    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const resend = () => {
        setSuccess(false);
        setError(false);

        UserAPI.resendVerificationMail( {
          email: formData.email,
              })
        .then((res) => {
            setMessage(t("resend_success"));
            setSuccess(true);
        }).catch((err) => {
            setMessage(t("resend_error"));
            setError(true);
        });
	};

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== password_confirm) {
            setMessage(t("password_error_match"));
            setError(true);
            return;
        }

        setSuccess(false);
        setError(false);

        UserAPI.register({
            email,
            username,
            password1: password,
            password2: password_confirm,
            first_name,
            last_name
        })
        .then(() => {
            setMessage(t("registration_success"));
            setSuccess(true);
        })
        .catch(error => {
            if (error.response && error.response.status === 400) {
                var e = error.response.data;
                   
                if (e.hasOwnProperty('password1')) {
                  
                    if (Array.isArray(e.password1)){
                        var msg = "";
                        e.password1.forEach( item => {
                            msg += t(item);
                            msg += " \n";
                        });
                       
                        setMessage(msg);
                        setError(true);

                    }
                    else
                        {
                            setMessage(t(e.password1));
                            setError(true);
                        }


                  }
                if (e.hasOwnProperty('email')) {
                    if (e.email === "Enter a valid email address."){
                        setMessage(t("email_not_valid"));
                        setError(true);
                        }
                    else{
                        setMessage(t("email_exists"));
                        setError(true);
                     }
                    
                  }
                if (e.hasOwnProperty('username')) {
                    setMessage(t("username_exists"));
                    setError(true);
                   
                  }
            } else {
                setMessage(t("registration_error"));
                setError(true);
            }
        });
    };

    return (
        <div className='page-wrap login-wrapper'>
            <div className='login'>
                <h2>{t("registration_title")}</h2>

                <InputField value={formData.email} name="email" handleChange={handleChange} placeholder="Unesite Vaš e-mail" icon={<AlternateEmailIcon />} />
                <InputField value={formData.username} name="username" handleChange={handleChange} placeholder="Unesite Vaše korisničko ime" icon={<PersonIcon />} />
                <InputField value={formData.first_name} name="first_name" handleChange={handleChange} placeholder="Unesite Vaše ime" icon={<PersonIcon />} />
                <InputField value={formData.last_name} name="last_name" handleChange={handleChange} placeholder="Unesite Vaše prezime" icon={<PersonIcon />} />
                <InputField value={formData.password} type="password" name="password" handleChange={handleChange} placeholder="Unesite Vašu lozinku" icon={<KeyIcon />} />
                <InputField value={formData.password_confirm} type="password" name="password_confirm" handleChange={handleChange} placeholder="Ponovite Vašu lozinku" icon={<KeyIcon />} />

                {success && (
                    <Alert severity="success">
                        <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                        {message}
                        {/* Assuming you want to provide the 'resend' functionality here */}
                        <a href="#" onClick={resend}>
                            {t("resend_mail")}
                        </a>
                    </Alert>
                )}

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                        {message}
                    </Alert>
                )}

                <Button onClick={handleSubmit} color='primary' text='Registracija' className='login-button' />
            </div>
        </div>
    );
};


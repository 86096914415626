import React, { useEffect, useState } from 'react';
import { UserMessage } from '../user-message/user-message';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from "react-i18next";
import MessageAPI from '../../../../axios/message';
import Alert from '@mui/material/Alert';
import { Pagination } from '@mui/material';

export const UserMessageList = ({ type }) => {
    const { t } = useTranslation(["message"]);
    const [messages, setMessages] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pageNum, setPageNum] = useState(1);

    const fetchMessages = async (page) => {
        let response;
        if (type === "received_messages") {
            response = await MessageAPI.getReceivedMessages(page);
        } else if (type === "sent_messages") {
            response = await MessageAPI.getSentMessages(page);
        }
        if (response) {
            setMessages(response.data.results);
            setPageCount(response.data.page_count);
        }
    };

    useEffect(() => {
        fetchMessages(pageNum);
    }, [pageNum, type]);

    const handleChange = (event, value) => {
        setPageNum(value);
    };

    return (
        <PerfectScrollbar>
            <h3>{t(type)}</h3>

            {messages.map(message => (
                <UserMessage data={message} type={type} key={`user-message-${message.id}`} />
            ))}

            {messages.length === 0 &&
                <Alert severity="info">{t("no_messages")}</Alert>
            }

            {messages.length > 0 &&
                <Pagination count={pageCount} page={pageNum} onChange={handleChange} />
            }
        </PerfectScrollbar>
    );
};

import React, { useContext } from 'react';
import { IconText } from '../../components/shared-components/layout/icon-text/icon-text';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../contexts/config-context';
import { Info } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

export const NewListing = ({ option }) => {
    const { t } = useTranslation(["new_listing"]);
    const { listingOption, listingType } = useContext(ConfigContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleIconClick = (link) => {
        navigate(link);
    };

    const renderOptions = (options, optionType) => {
        return options.map((lo, index) => (
            <IconText
                className='new-listing-icon-text'
                key={`listing-option-${optionType}-${index}`}
                icon={lo.icon}
                text={optionType === '1' ? lo.name : t(lo.name)}
                onClick={() => handleIconClick(optionType === '1' ? '/postavljen-novi' : lo.link)}
            />
        ));
    };

    const MainContent = () => (
        <div>
            <p className='bold blue'>{t("form")}</p>
            <p>{t("choose")}</p>
            <div className='new-listing-icons-wrap'>
                <p className='bold'>{t("publish")}</p>
                <div className='flex row a-center jc-evenly'>
                    {option === '1' ? renderOptions(listingOption, '1') : renderOptions(listingType, '2')}
                </div>
                <p className='new-listing-instructions flex row a-center' onClick={() => navigate('/uputstvo-oglas')}>
                    <Info /><span className='white'>{t("info")}</span>
                </p>
            </div>
        </div>
    );

    return (
        <div className='page-wrap new-listing-wrapper flex column a-center jc-center'>
            <Outlet />
            {location.pathname === '/postavi-oglas' && <MainContent />}
        </div>
    );
};

import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';
import { ConfigContext } from '../../../../contexts/config-context';
import i18n from "i18next";
import MiscAPI from '../../../../axios/misc';

const CheckboxItem = React.memo(({ label, name, checked, onChange }) => (
    <div className='checkbox-wrap clickable' key={`checkbox-${name}`}>
        <Checkbox
            name={name}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
        <p>{label}</p>
    </div>
));

const CheckboxFields = ({ formDataListing, handleChangeCheckbox }) => {
    const { t } = useTranslation(["listing", "common", "profile"]);
    const { checkboxItems, checkboxItemsRestData } = useContext(ConfigContext);

    return (
        <div>
            <div className='new-listing-additional-data new-listing-section'>
                <p className='listing-general-title'>{t("additional_information")}</p>
                <div className='new-listing-checkboxes-box'>
                    {checkboxItems.map((item, index) => (
                        <CheckboxItem 
                            key={`checkbox-${item.name}-${index}`}
                            name={item.name}
                            label={t(item.name)}
                            checked={formDataListing[item.name]}
                            onChange={handleChangeCheckbox}
                        />
                    ))}
                </div>
                <p className='listing-general-title new-listing-section'>{t("other_information")}</p>
                <div className='new-listing-checkboxes-box'>
                    {checkboxItemsRestData.map((item, index) => (
                        <CheckboxItem 
                            key={`checkbox-${item.name}-${index}`}
                            name={item.name}
                            label={t(item.name)}
                            checked={formDataListing[item.name]}
                            onChange={handleChangeCheckbox}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(CheckboxFields);


import { Analytics, Chat, Delete, Edit, Replay, ToggleOff, ToggleOn } from '@mui/icons-material';
import React, { useContext, useState , useEffect, useCallback} from 'react';
import {ListingImageData} from '../../components/shared-components/layout/listing-image-data/listing-image-data';
import { ListingCardAmenities } from '../listing-card/components/listing-card-amenities/listing-card-amenities';
import { ListingLovedBy } from '../listing-card/components/listing-loved-by/listing-loved-by';
import { BigSmallTitle } from '../shared-components/layout/big-small-title/big-small-title';
import { SmallInfoBox } from '../shared-components/layout/small-info-box/small-info-box';
import { useNavigate } from 'react-router-dom';
import  ConfirmDialog  from '../../components/shared-components/form/confirm-dialog/confirm-dialog';
import  AlertDialog  from '../../components/shared-components/form/alert-dialog/alert-dialog';
import ListingAPI from '../../axios/listing';
import { useTranslation } from "react-i18next";
import { ConfigContext } from '../../contexts/config-context';
import { NewMessageDialog } from '../../components/new-message-dialog/new-message-dialog';
import i18n from "i18next";

export const ListingOverviewItem = ({listing, activeBottomPanel=false, listingFollow=false, inactiveListing, promotionPriceAndDuration}) => {
    const { t } = useTranslation(["listing", "common"]);
    const { api, LISTING_GROUP } = useContext(ConfigContext);
    const navigate = useNavigate();

    const [dialog, setDialog] = useState({
        type: null, 
        open: false, 
        message: '',
        title: '',
        color: '',
        callback : null
    });
    
    useEffect(() => {
        
        var date_in_db = new Date(listing.date_active);
        var date_now =  new Date();

        setIsExpired(date_in_db < date_now);

    }, []);

    const [isExpired, setIsExpired] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [deactivateListing, setDeactivateListing] = useState(inactiveListing);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    useEffect(() => {
        const dateInDB = new Date(listing.date_active);
        setIsExpired(dateInDB < new Date());
    }, [listing.date_active]);

    const handleDialogClose = useCallback((value) => {
        setDialog(prev => ({ ...prev, open: value }));
    }, []);

    
    const handlePromoteListing = useCallback(() => {
       
        setDialog({
            type: 'confirmPromote',
            open: true,
            message: `${t('promotion_price_message_1')}${promotionPriceAndDuration.duration}${t('promotion_price_message_2')}${promotionPriceAndDuration.price}${t('promotion_price_message_3')}`,
            title: t('attention', { 'ns': 'common' }),
            color: '' ,
            callback : confirmPromote
        });
    }, [t, promotionPriceAndDuration]);

    const handleDeleteListing = useCallback(() => {
       
        setDialog({
            type: 'confirmDelete',
            open: true,
            message: t('delete_confirm_message'),
            title: t('attention', { 'ns': 'common' }),
            color: '' ,
            callback : confirmDelete
        });
    }, [t]);


    const promoteListing = () => {

        handlePromoteListing();      
    }

    const renewListing = () => {
      
        
        ListingAPI.renewListing(listing.id).then(() => {
            ListingAPI.activateListing(listing.id).then(res => {
                setDeactivateListing(false);
    
            }).catch(e => {
                var msg = '';
                if (e.response.status === 402){
                    msg = t('low_funds');
                }else{
                    msg = t('promotion_error');
                 }

                 setDialog({
                    type: 'alert',
                    open: true,
                    message: msg,
                    title: t("error", {"ns" : "common"}),
                    color: 'red' ,
                    callback : null
                });
            })
            
        }).catch(err => {

            setDialog({
                type: 'alert',
                open: true,
                message:t('renewal_error'),
                title: t("error", {"ns" : "common"}),
                color: 'red' ,
                callback : null
            });

           
        })
        
    }

    const deleteListingHandler = () => {
        handleDeleteListing();
        
    }

    const confirmPromote = useCallback(() => {
        

        var data = {
            listing : listing.id,
            listing_group : LISTING_GROUP.PROMOTED
        }

        ListingAPI.promoteListing(data).then(res => {
            setDialog({
                type: 'alert',
                open: true,
                message: t('promotion_success'),
                title: t("success", {"ns" : "common"}),
                color: 'green' ,
                callback : null
            });

            listing.promoted = true;
            listing['promoted_value'] = Object();
            listing.promoted_value['label'] = res.data[i18n.language];
        }).catch(e => {
            var msg = '';
            if (e.response.status === 402){
                msg = t('low_funds');
            }else{
                msg = t('promotion_error');
             }

            setDialog({
                type: 'alert',
                open: true,
                message: msg,
                title: t("error", {"ns" : "common"}),
                color: 'red' ,
                callback : null
            });
        })

    });

    const activateListingHandler = () => {

        ListingAPI.activateListing(listing.id).then(res => {
            setDeactivateListing(false);

        }).catch(e => {
            var msg = '';
            if (e.response.status === 402){
                msg = t('low_funds');
            }else{
                msg = t('activate_error');
             }
            setDialog({
                type: 'alert',
                open: true,
                message: msg,
                title: t("error", {"ns" : "common"}),
                color: 'red' ,
                callback : null
            });
            
        })
    }

    const deactivateListingHandler = () => {
        ListingAPI.deactivateListing(listing.id).then(res => {
            setDeactivateListing(true);

        }).catch(e => {
            setDialog({
                type: 'alert',
                open: true,
                message: t('deactivate_error'),
                title: t("error", {"ns" : "common"}),
                color: 'red' ,
                callback : null
            });

        })

    }

    const confirmDelete = useCallback(() => {
        ListingAPI.deleteListing(listing.id).then(res => {

            setDialog({
                type: 'alert',
                open: true,
                message: t('delete_success'),
                title: t("success", {"ns" : "common"}),
                color: 'green' ,
                callback : null
            });
        }).catch(e => {

            setDialog({
                type: 'alert',
                open: true,
                message: t('delete_error'),
                title: t("error", {"ns" : "common"}),
                color: 'red' ,
                callback : null
            });

        });
    });

    const sendMessage = () => {
        setMessageDialogOpen(true);
    }

    const goToLocation = () => {
        if (listing.is_active){
        navigate(`/oglas/${listing.id}`)
        }
        else{
            setDialog({
                type: 'alert',
                open: true,
                message: t("not_active"),
                title: t('attention', {'ns' : "common"}),
                color: 'gray' ,
                callback : null
            });

        }
    }
    
   

    return(
        <div className={`listing-overview-item-panel-wrap ${isDeleted?'hide':''}`}>
        
        <div className={`listing-overview-item-wrap ${(deactivateListing || inactiveListing) && 'blured'}`}>
             <ListingImageData listingCardData={listing} priceDateVisible={false} />
             <div className='listing-overview'>
                <div className='flex row a-start jc-sb clickable' onClick={goToLocation}>
                    <BigSmallTitle big={listing.title} small={listing.location.label}/>
                    <div className='flex column a-end jc-start'>
                        <SmallInfoBox className='listing-overview-price' label={parseFloat(listing.price) === 0 ? t('price_placeholder') : listing.price + ` €`} color='orange-b'/>
                        
                        <p className='small'>{t('date_published')}: {new Date(listing.date_created).toLocaleDateString('sr-SP')}</p> 
                    </div>
                </div>
                <div className='listing-overview-box flex column a-start jc-sb'>
                    <ListingCardAmenities listingCardData={listing} jc="jc-start"/>
                    <div className='listing-overview-description-loved-wrap flex row a-start jc-sb'>
                            <div className='listing-overview-description'>
                                <p>{listing.description}</p>
                            </div>
                            
                            <ListingLovedBy listing={listing.id} className='loved-by-wrap'/>
                            
                    </div>
                    <div className='listing-overview-tags flex row a-center jc-start'>
                     {/*   {listing.additionalData.tags.map((item, index) => (
                             <SmallInfoBox label={`${item}`} color='orange-b' key={`list-overview-tag-${index}`}/>
                        ))}
                    */}
                    </div>
                </div>
            </div>
        </div>
        {activeBottomPanel && !listingFollow && 
            <div className='listing-overview-item-bottom-panel flex row a-center jc-sb'>
                <div className='listing-overview-item-deactivate flex row a-center jc-start clickable'>
                    {deactivateListing ? <ToggleOn className='green' onClick={activateListingHandler}/> : <ToggleOff  onClick={deactivateListingHandler}/>}
                    {deactivateListing ? <p>{t('activate_listing')}</p>:<p>{t('deactivate_listing')}</p>}
                </div>
                <div className='listing-overview-item-options-wrap flex row a-center jc-end'>
                    {!listing.promoted && 
                    <SmallInfoBox onClick={promoteListing} className='listing-overview-option-item' label={t('promote_listing')} color='orange-b'/>
                }
                { isExpired &&
                    <div onClick={renewListing} className='listing-overview-option-item'>
                        <p>{t('renew_listing')}</p>
                        <Replay/>
                    </div>
                    }
                    <div onClick={()=>{navigate("/postavi-oglas/azuriranje/"+listing.id)}} className='listing-overview-option-item'>
                        <p>{t('edit_listing')}</p>
                        <Edit/>
                    </div>
                    <div onClick={()=>{navigate("/korisnik/statistika/"+listing.id)}} className='listing-overview-option-item'>
                        <p>{t('statistics')}</p>
                        <Analytics/>
                    </div>
                    <div onClick={deleteListingHandler} className='listing-overview-option-item'>
                        <p>{t('delete_listing')}</p>
                        <Delete/>
                    </div>
                </div>
            </div>
        }
        {activeBottomPanel && listingFollow &&
            <div className='listing-overview-item-bottom-panel flex row a-center jc-sb'>
                <div onClick={sendMessage} className='listing-overview-item-deactivate flex row a-center jc-start clickable'>
                    <Chat/>
                    <p>{t('sent_user_message')}</p>
                </div>
                <div className='listing-overview-item-options-wrap flex row a-center jc-end clickable'>
                    <div className='listing-overview-option-item'>
                        <p>{t('delete_listing')}</p>
                        <Delete/>
                    </div>
                </div>
            </div>
        }

        <NewMessageDialog open={messageDialogOpen} setOpen={setMessageDialogOpen} user={`${listing.user.first_name} ${listing.user.last_name}`} listing={listing.id} />
         {(dialog.type === 'confirmPromote'|| dialog.type === 'confirmDelete') && (
                <ConfirmDialog open={dialog.open} message={dialog.message} title={dialog.title} setOpen={handleDialogClose} onConfirm={dialog.callback}  />
            )}
            <AlertDialog open={dialog.type === 'alert' && dialog.open} onClose={handleDialogClose} setOpen={handleDialogClose} title_color={dialog.color} title={dialog.title} message={dialog.message} />
           
        </div>
    )
}
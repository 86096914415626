import React, { memo } from 'react';
import { useTranslation } from "react-i18next";

const SelectField = ({
  name, 
  className = 'row', 
  label, 
  options, 
  handleChange, 
  placeholder, 
  value, 
  disabled = false, 
  translate_namespace = ""
}) => {
    
    const { t } = useTranslation([translate_namespace]);

    return (
        <div className={`select-field-wrap ${className}`}>
            {label && <label>{label}</label>}
            <select name={name} className='select-field' onChange={handleChange} value={value} disabled={disabled}>
                {options.map(option => (
                    <option className='select-option' value={`${option.id}`} key={`${option.id}`}>
                      {translate_namespace !== "" && isNaN(option.label) ? t(option.label) : option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default memo(SelectField);

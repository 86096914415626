import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useMap, useMapEvents } from 'react-leaflet/hooks';

function ChangeCenter({ center }) {
  const map = useMap();

  useEffect(() => {
    const timer = setTimeout(() => { map.invalidateSize() }, 500);

    return () => clearTimeout(timer);  // Clearing timeout on unmount
  }, []);

  useEffect(() => { 
    map.flyTo(center, map.getZoom());
  }, [center, map]);

  return null;
}

function ProcessClickEvent({ setPos }) {
  useMapEvents({
    click: (e) => {
      setPos([e.latlng.lat, e.latlng.lng]);
    }
  });

  return null;
}

const DEFAULT_POSITION = [44.8125, 20.4612];

export const Map = ({ position, updatePosition, focus }) => {
  return(
    <div>
      <MapContainer className="map-size" center={focus} zoom={13} scrollWheelZoom={true}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {position[0] !== DEFAULT_POSITION[0] || position[1] !== DEFAULT_POSITION[1] ? 
          <Marker position={position} /> 
          : null}
        <ProcessClickEvent setPos={updatePosition} />
        <ChangeCenter center={focus} />
      </MapContainer>
    </div>
  );
}
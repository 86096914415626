import { Chat, Info, Share } from '@mui/icons-material';
import React, { useEffect, useState, useContext } from 'react';
import { ListingCardAmenities } from '../../components/listing-card/components/listing-card-amenities/listing-card-amenities';
import { ListingLovedBy } from '../../components/listing-card/components/listing-loved-by/listing-loved-by';
import  Button  from '../../components/shared-components/buttons/button/button';
import { BigSmallTitle } from '../../components/shared-components/layout/big-small-title/big-small-title';
import { SectionHeading } from '../../components/shared-components/layout/section-heading/section-heading';
import { SmallInfoBox } from '../../components/shared-components/layout/small-info-box/small-info-box';
import { useParams } from 'react-router-dom';
import { UserContext } from "../../contexts/userContext";
import { SharePopper } from '../../components/shared-components/share-popper/share-popper';

import ListingAPI from '../../axios/listing';
import LocationAPI from '../../axios/locations';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ConfigContext } from '../../contexts/config-context';
import { MapViewer } from '../../components/map-viewer/map-viewer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { NewMessageDialog } from '../../components/new-message-dialog/new-message-dialog';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

import  ImageViewer  from '../../components/image-viewer/image-viewer';
import { Stack } from '@mui/material';

export const ListingPage = ({}) => {

    const { user, setUser } = useContext(UserContext);
    const params = useParams();
    const listingID = params.id;
    const { api, locationItemsLimit, locationLegend } = useContext(ConfigContext);
    const { t } = useTranslation(["listing", "profile", "common", "location"]);
    const [selectedAmenity, setSelectedAmenity] = useState([]); 
    const navigate = useNavigate();


    const listingInit = Object.freeze({
        
            "id": 20,
            "place": {
                "label": ""
            },
            "subplace": {
                "label": ""
            },
            "location": {
                "label": ""
            },
            "photos": [ ],
            "videos" : [],
            "user": "",
            "heating": [],
            "property_type": {
                "name": ""
            },
            "property_state": {
                "name": ""
            },
            "listing_type": {
                "name": ""
            },
            "listing_group": {
                "name": ""
            },
            "user_type": {
                "name": ""
            },
            "title": "",
            "price": 1,
            "address": "",
            "lng": "0.0000000000000000",
            "lat": "0.0000000000000000",
            "area": 1,
            "num_rooms": "",
            "num_bathrooms": "",
            "floor": "",
            "max_floor": "",
            "bills": "",
            "description": "",
            "phone": "",
            "mobile": "",
            "email": "",
            "date_created": "",
            "date_active": "",
            "is_active": true,
            "ammenities": [],
            "properties": []
        
    });

    const [listing, setListing] = useState(listingInit);
    const [amenities, setAmenities] = useState(() => {return []});

    useEffect(() => { 

        ListingAPI.getListingDetails(listingID, i18n.language).then(
            function (response){
               setListing(response.data);
               processData(response.data);
            }
        ).catch(err => {
            console.log(err);
            if (err.response.status === 404){
                navigate('/nepostojeci-oglas');
            }
        });   
          
    },[]);

    const [pricePerM2, setPricePerM2] = useState("");
    const [heating, setHeating] = useState([]);
    const [vat, setVAT] = useState("no");
    const [mortgage, setMortgage] = useState("no");
    const [registered, setRegistered] = useState("no");
    const [position, setPosition] = useState([44.8125, 20.4612]);


    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    const processData = (data) => {
        setPricePerM2(Math.ceil(listing.price / listing.area));

        var H = [];
        data.heating.map((h, index) => (
            H.push(h['name'])
       ));
       setHeating(H);

       data.properties.forEach((prop) => {
        switch (prop) {
            case 'registered':
                setRegistered("yes");
              break;
            case 'mortgage':
                setMortgage("yes");
                break;
            case 'vat':
                setVAT("yes");
              break;
            default:
              
          }
    });

     var pos = [];
     pos.push(data.lat) ;
     pos.push(data.lng) ;
    setPosition(pos);

    }


    const amenity = Object.freeze({
        kindergartens : false,
        schools : false,
        universities : false,
        health_center : false,    
        hospitals : false,
        banks : false,
        atms : false,
        post_office : false,
        gas_stations : false,
        supermarkets : false,
        shopping_malls : false,
        farmers_markets : false,
        gyms : false,
        restaurants : false,
        bus_stations : false,
        pharmacies : false,
        airport : false,
        parks : false,
        stadiums : false,
        museums : false,
        main_bus_station : false,
        train_station : false

    });

    const [showAmenity, setShowAmenity] = useState(amenity);

    const getAmenity = (type, distance) => {
        var dist = distance * 1000;
        LocationAPI.getAmenities(type, listing.lat, listing.lng, dist).then(
            function (response){
               setAmenities(response.data);

               setShowAmenity({ ...amenity });
                
               setShowAmenity(prev => ({
                ...prev,
                [type] : true,
                }));
            }
        );   
    }

   
    const [photoIndex, setPhotoIndex] = useState(-1);
  

    const onPhotoClicked = (index, item) => {
        setPhotoIndex(index);
    }

    const [share, setShare] = React.useState(false);    
    const openShare =    (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
     
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [selectedItem, setSelectedItem] = useState([]);
    const onSelectItem = (item) => {
        setSelectedItem(item);
    } 
    useEffect(() => {}, [selectedItem]);
    return(
        <div className='page-wrap listing-page-wrap'>

            <SharePopper open={open} anchorEl={anchorEl} title={listing.title} id={listing.id}/>
           
            <div className=' page-wrap-header flex row a-center jc-sb'>
                <BigSmallTitle big={listing.title} small={listing.place.label + ", " + listing.subplace.label + ", " +listing.location.label}/>
                <div className='flex column a-end jc-end'>
                    <div className='page-wrap-icons flex row a-center jc-sb'>
                   
                        
                            <Share className='page-wrap-icon' onClick={openShare} />
      
           
          
           
                       
                        <Info className='page-wrap-icon'/>
                        <ListingLovedBy className='page-wrap-icon' listing={listingID}/>
                    </div>
                    <p>{t("listing_id")}: <b>{listing.id}</b> </p>
                </div>
            </div>
            <div className='listing-page-gallery-basic-info-wrap '>
                <div className='listing-page-gallery'>
                    <ImageViewer photos={listing.photos} videos={listing.videos}/>
              
               
               
        
               {/* 
                <Lightbox
                     slides={photoData}
                     index={photoIndex}
                     open={photoIndex >= 0}
                     close={() => setPhotoIndex(-1)}
                     plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                    />
                     
                    <Carousel showArrows={true} showThumbs={true} onClickItem={onPhotoClicked} >
                    {photoData.map((photo, index) => (
                        <div>
                        <img src={photo.src} alt="no_photo"/>
                      
                    </div>
                    ))}

                        
                    </Carousel>
                    */}
                </div>
                <div className='listing-page-basic-info'>
                    <div className='flex column a-end jc-end'>
                        <div className='flex row a-center jc-end'>
                            <p className='font-light-gray'>{t("date_published")}</p>
                            <p className='bold'>{new Date(listing.date_created).toLocaleDateString('sr-SP')}</p>
                        </div>
                        <SmallInfoBox className='listing-page-price' label={ parseFloat(listing.price) === 0 ? t('price_placeholder') : listing.price + ` €`} color='orange-b' size={'big'}/>
                        <div className='flex row a-center jc-end'>
                            <p className='font-light-gray'>{t("price_per_m2")}</p>
                            <p className='bold'>{pricePerM2} €</p>
                        </div>

                        {/*
                        <div className='flex row a-center jc-end'>
                            <p className='font-light-gray'>Prosecna cena</p>
                            <p className='bold'>22000e/m2</p>
                        </div>
                        */}
                    </div>
                    <ListingCardAmenities listingCardData={listing} jc="jc-start"/>
                    
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("publisher")}</p>
                        <p className='bold'>{listing.user_type.label}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("type")}</p>
                        <p className='bold'>{listing.property_type.label}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("state")}</p>
                        <p className='bold'>{listing.property_state.label}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("registered")}</p>
                        <p className='bold'>{t(registered, {"ns":"common"})}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("heating")}</p>
                        <p className='bold'>{heating.join(', ')}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("vat")}</p>
                        <p className='bold'>{t(vat, {"ns":"common"})}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("mortgage")}</p>
                        <p className='bold'>{t(mortgage, {"ns":"common"})}</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("bills")}</p>
                        <p className='bold'>{listing.bills} RSD</p>
                    </div>
                    <div className='listing-page-contact-publisher flex row a-center jc-sb'>
                        <p className='font-light-gray'>{t("deposit")}</p>
                        <p className='bold'>{listing.deposit} RSD</p>
                    </div>
                </div>
            </div>
            <SectionHeading title={t("additional_information")} >
                <div className='flex row a-center jc-start' style={{gap: "10px"}}>
                    {listing.properties.map((property, index) => (
                        <p className='font-light-gray' key={`listing-page-property-${index}`}>{t(property)}</p>
                    ))}
                </div>
            </SectionHeading>
            <SectionHeading title={t("equipment")}>
                <div className='flex row a-center jc-start' style={{gap: "10px"}}>
                    {listing.ammenities.map((ammenity, index) => (
                        <p className='font-light-gray' key={`listing-page-ammenity-${index}`}>{t(ammenity)}</p>
                    ))}
                </div>
            </SectionHeading>
            <SectionHeading title={t("description")} >
                <p className='font-light-gray'>{listing.description}</p>
            </SectionHeading>

            <SectionHeading title={t("location")} >
                <div className='listing-page-location-legend-wrap'>
                    <div id="map" className='listing-page-map-wrap'>
                        <MapViewer position={position} amenities={amenities} selected={selectedAmenity}> </MapViewer>
                    </div> 
                    <div className='listing-page-location-legend flex column a-center jc-start'>
                        {locationLegend.map((item, index) => (
                            <div>
                            <div onClick={ () =>{ getAmenity(item.name, item.distance); onSelectItem(item)}} className='icon-text-info flex row a-center jc-sb' key={`location-legend-${index}`}>
                                <div className='flex row a-center jc-start'>
                                    <p className='icon-text-icon'>{item.icon}</p>
                                    <p style={{marginRight: '10px'}}>{t(item.name, {"ns" : "location"})}</p>
                                </div>
                                <div className='blue-b'>
                                    <p>{item.distance} Km</p>
                                </div>
                            </div>
                           

                            </div>

                            
                        ))}
                    </div>
                </div>
               
            </SectionHeading>
            <div>
                    <p className='bold blue'>U blizini: </p>
                    {   selectedItem && amenities.length > 0 && showAmenity[selectedItem.name] &&
                            <List>
                                {
                                    amenities.map( (item, index) => (
                                        index < locationItemsLimit ?
                                        <ListItem onClick={() => {setSelectedAmenity(item.coordinates)}} disablePadding>
            
                                            <ListItemText primary={item.name} className='clickable' />
               
                                         </ListItem>
                                         : ""
                                        
                                    ))
                                }
                               
                                
                            </List>
                    }
                    {selectedItem && amenities.length == 0  && <span style={{paddingTop: "18px"}}>Nema objekata u blizini</span>}
                </div>
            <SectionHeading title={t("contact_publisher")} >
                <Stack direction='row' alignItems={'flex-start'} justifyContent={'space-between'} className='contact-publisher'>
                    <Stack direction='column' alignItems={'space-between'} justifyContent={'space-between'}>
                    

                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-publisher-info-${1}`}>
                            <p className='font-light-gray'>{t("user")}</p>
                            <p className='bold'>{listing.user}</p>
                        </div>

                            {/* 
                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-publisher-info-${1}`}>
                            <p className='font-light-gray'>Oglas postavljen</p>
                            <p className='bold'>{new Date(listing.date_created).toLocaleDateString()}</p>
                        </div>
                            */}
                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-expired-info-${1}`}>
                            <p className='font-light-gray'>{t("expires")}</p>
                            <p className='bold'>{new Date(listing.date_active).toLocaleDateString('sr-SP')}</p>
                        </div>

                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-phone-info-${1}`}>
                            <p className='font-light-gray'>{t("phone", {"ns" : "profile"})}</p>
                            <p className='bold'>{listing.phone}</p>
                           
                        </div>    

                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-mobile-info-${1}`}>
                            <p className='font-light-gray'>{t("mobile", {"ns" : "profile"})}</p>
                            <p className='bold'>{listing.mobile}</p>
                           
                        </div>  

                        <div className='listing-page-contact-publisher flex row a-center jc-sb' key={`listing-page-mail-info-${1}`}>
                            <p className='font-light-gray'>{t("mail", {"ns" : "profile"})}</p>
                            <p className='bold'>{listing.email}</p>
                        </div>    


                    </Stack>
                    {user != null && 
                    <div>
                       
                        <Button icon={<Chat/>} onClick={() => {setMessageDialogOpen(true)}} text={t("send_message")} color='orange-b'/>
                        <NewMessageDialog open={messageDialogOpen} setOpen={setMessageDialogOpen} user={listing.user} listing={listingID}/>
                    </div>
}
                </Stack>

            </SectionHeading>
        </div>
    )
}
import { createContext, useEffect, useRef, useState } from "react"


export const InterfaceContext = createContext();

const InterfaceContextProvider = ({children}) => {
    const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
    const [responsiveMenuOpen, setResponsiveMenuOpen] = useState(false);
    const [filterMenuVisible, setFilterMenuVisible] = useState(false);
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const windowWidth = useRef(window.innerWidth);

    useEffect(() => {
        if(window.innerWidth < 1200) {
            setResponsiveMenuVisible(true);
            setFilterMenuVisible(true);
        }
        if(window.innerWidth >= 1200) {
            setResponsiveMenuVisible(false);
            setFilterMenuVisible(false);
        }
        
            window.addEventListener('resize', () => {
                console.log('resize')
                if(window.innerWidth < 1200) {
                    setResponsiveMenuVisible(true);
                    setFilterMenuVisible(true);
                }
                if(window.innerWidth >= 1200) {
                    setResponsiveMenuVisible(false);
                    setFilterMenuVisible(false);
                }
            })
        }
    ,[]);
    
        const openResponsiveMenu = () => {
        setResponsiveMenuOpen(true);
    }
    const closeResponsiveMenu = () => {
        setResponsiveMenuOpen(false);
    }
    const openFilterMenu = () => {
        setFilterMenuOpen(true);
    }
    const closeFilterMenu = () => {
        setFilterMenuOpen(false);
    }
    return (
        <InterfaceContext.Provider value={{
            responsiveMenuVisible, 
            responsiveMenuOpen,
            openResponsiveMenu, 
            closeResponsiveMenu,
            filterMenuOpen,
            filterMenuVisible,
            openFilterMenu,
            closeFilterMenu}} >
            {children}
        </InterfaceContext.Provider>
    )
}

export default InterfaceContextProvider;
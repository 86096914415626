import React, { useContext } from 'react';
import { ConfigContext } from '../../../contexts/config-context';
import { Navigation } from '../../navigation/navigation';
import { Logo } from '../logo/logo';
import { Stack, Typography } from '@mui/material';

export const Footer = () => {
    const { navItems } = useContext(ConfigContext);
    const footerNavItems = navItems.filter(item => item.footer);

    return (
        <footer className='footer-wrapper'>
            <Navigation navItems={footerNavItems} direction='column' title='Meni'/>
            
            <Stack className='footer-details row' alignItems='center' justifyContent='flex-end' gap='20px'>
                <Logo />
                <Stack className='footer-address column' alignItems='flex-start' justifyContent='flex-start'>
                    <Typography sx={{ fontWeight: 'bold' }}>Halo Stan Nekretnine Beograd</Typography>
                    <Typography>Adresa: Jurija Gagarina 22</Typography>
                    <Typography>Novi Beograd 11070</Typography>
                    <Typography>Kontakt telefon: 062048423</Typography>
                </Stack>
            </Stack>
        </footer>
    );
}

import { Close, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

export const OfferPackage = ({item, selected, index, onPackageClick}) => {

    const { t } = useTranslation(["offers"]);

    const [toggleOptions, setToggleOptions] = useState(true);
    const handleChange = () => {
        setToggleOptions(!toggleOptions);
    }
    const [openModal, setModalOpen] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
    }
    const handleCloseModal = () => {
        setModalOpen(false);
    }
   
    return(
        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} 
            sx={{border: selected ? "2px solid orange" : '2px solid #3E3E3E', 
                backgroundColor: selected ? "orange" : '#3E3E3E',
                padding: "5px 10px",
                borderRadius: '5px', 
                maxWidth: "330px",
                cursor: 'pointer',
                "&:hover": {opacity: '0.8'},
                }} 
                onClick={() => onPackageClick(index)}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} sx={{color: "white", backgroundColor: selected ? "orange" : '#3E3E3E', minHeight:'50px'}}>
                    <Typography sx={{fontWeight:'bold' }}>{item.name}</Typography>
                </Stack>
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} sx={{color: "#3E3E3E", backgroundColor: 'white'}}>
                    <Stack direction={'column'} 
                            alignItems={'flex-start'} 
                            justifyContent={'flex-start'} 
                            sx={{ padding: '10px 10px'}}>
                        <Typography sx={{fontWeight:'bold'}}>{t("num_listings")}:  <span className='orange bold'>{item.num_listings}</span></Typography>
                        <Typography sx={{fontWeight:'bold'}}>{t("num_photos")}:  <span className='orange bold'>{item.num_photos}</span></Typography>
                        <Typography sx={{fontWeight:'bold'}}>{t("num_agents")}:  <span className='orange bold'>{item.num_agents}</span></Typography>
                    </Stack>
                    <Accordion sx={{ borderTop: selected ? "2px solid orange" : '2px solid #3E3E3E',
                                    borderBottom: selected ? "2px solid orange" : '2px solid #3E3E3E',
                                    padding: "0",
                                    margin: 0}}
                                    expanded={toggleOptions} 
                                    onChange={() => handleChange()} 
                                    className='full-width'>
                        <AccordionSummary  expandIcon={<ExpandMore />}  aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{fontWeight:'bold'}}>{t("page")}: <span className='orange bold'>{item.name}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction='column' alignItems="flex-start" justifyContent={"flex-start"} gap="5px">
                                {item.packageIncludes.map((i, index) => (
                                    <span key={`index-${index}-packageitem`}>{i.title} <span className='orange bold'>{i.value && i.value}</span></span>
                                ))}
                            </Stack>
                           
                        </AccordionDetails>
                    
                    </Accordion>
                    <Stack direction={'column'} 
                            alignItems={'flex-start'} 
                            justifyContent={'flex-start'} 
                            sx={{borderBottom: selected ? "2px solid orange" : '2px solid #3E3E3E', padding: '10px 10px'}}>
                        <Typography sx={{fontWeight:'bold'}}>{t("gift_1")} <span className='orange bold'>{item.numOfGift}</span> {t("gift_2")} </Typography>
                        <Typography>{t("next")}</Typography>
                        
                    </Stack>
                    <Stack direction={'column'} 
                            alignItems={'center'} 
                            justifyContent={'center'} 
                            gap="20px"
                            sx={{padding: '10px 10px', width: "100%"}}>
                        <Typography sx={{fontWeight:'bold'}}>{t("monthly")} <span className='orange bold'> {item.price}</span> RSD</Typography>
                       <Button sx={{backgroundColor:'lime', color: "white", fontWeight:'bold', textTransform:"unset"}}>{t("order")}</Button>
                        
                    </Stack>

                </Stack>
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} sx={{padding: "10px 0",color: "white", backgroundColor: selected ? "orange" : '#3E3E3E'}}>
                    <Typography sx={{fontWeight:'bold' }}>{t("vat")}</Typography>
                    <Typography onClick={() => handleOpenModal()} sx={{"&:hover": {cursor: "pointer", fontWeight:"bold"}}} >{t("information")}</Typography>
                </Stack>
                
                <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
               
                >
                <Box  sx={{position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 350,
                minHeight:'500px',
                backgroundColor: "white!important",
                border: '1px solid gray',
                borderRadius: "10px",
                padding: "20px"
                }}>
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-end'}>
                        <Close sx={{"&:hover": {cursor: "pointer"}}} onClick={() => handleCloseModal()}/>
                    </Stack>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t("information")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {item.details}
                    </Typography>
                </Box>
                </Modal>
        </Stack>
    )
}
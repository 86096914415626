import React, { useState, useEffect } from 'react';
import { CheckBox, CheckBoxOutlineBlank, Delete, DoNotDisturbAlt, Person, Reply as ReplyIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import Link from '@mui/material/Link';

import { NewMessageDialog } from '../../../../components/new-message-dialog/new-message-dialog';
import MessageAPI from '../../../../axios/message';
import ConfirmDialog from '../../../../components/shared-components/form/confirm-dialog/confirm-dialog';
import AlertDialog from '../../../../components/shared-components/form/alert-dialog/alert-dialog';

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export const UserMessage = ({ data, type }) => {
    const { t } = useTranslation(["message", "common"]);

    const [selectMessage, setSelectMessage] = useState(false);
    const [showBody, setShowBody] = useState(false);
    const [datetime, setDatetime] = useState("");
    const [isBolded, setIsBolded] = useState(type === "received_messages" && !data.is_read ? "bolded" : "");
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [alertDialogTitle, setAlertDialogTitle] = useState("");
    const [alertColor, setAlertColor] = useState("");
    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        if (data.date_created) {
            const d = new Date(data.date_created);
            setDatetime(datesAreOnSameDay(d, new Date()) ? d.toLocaleTimeString('sr-SP') : d.toLocaleDateString('sr-SP'));
        }
    }, [data.date_created]);

    const blockUser = () => setShowConfirmDialog(true);

    const confirmBlock = async () => {
        try {
            await MessageAPI.blockUser(data.sender.id);
            setAlertValues("success", t("block_success"), "green");
        } catch {
            setAlertValues("error", t("block_error"), "red");
        }
    };

    const handleClick = async () => {
        setShowBody(!showBody);
        if (!showBody) {
            try {
                await MessageAPI.markReadMessage(data.id);
                setIsBolded("");
            } catch (e) {
                console.error(e);
            }
        }
    };

    const deleteMessage = () => setShowDeleteConfirmDialog(true);

    const confirmDelete = async () => {
        try {
            await MessageAPI.deleteMessage(data.id);
            setShowMessage(false);
        } catch {
            setAlertValues("error", t("delete_error"), "red");
        }
    };

    const setAlertValues = (title, message, color) => {
        setAlertDialogTitle(t(title, { "ns": "common" }));
        setAlertDialogMessage(message);
        setAlertColor(color);
        setShowAlertDialog(true);
    };

    return (
        showMessage && (
            <div className='user-message-wrap flex row a-center jc-sb'>
                <div className='user-message-checkbox-wrap flex row a-center jc-center clickable'>
                    {selectMessage ? 
                    <CheckBox onClick={() => setSelectMessage(false)}/> : 
                    <CheckBoxOutlineBlank onClick={() => setSelectMessage(true)}/>}
                </div>
                
                <div className='user-message-message-wrap flex column a-start jc-sb'>
                    <div className='user-message-message flex column a-start jc-start'>
                        <p className='gray flex row a-center jc-start'><Person/> {type==="sent_messages" ? `${data.recipient.first_name} ${data.recipient.last_name}` : `${data.sender.first_name} ${data.sender.last_name}`}</p>
                        <p className={isBolded} onClick={handleClick}>{data.subject}</p>
                        {showBody &&
                            <p className="new_lines">{data.body}</p>
                        }
                    </div>
                    <div className='flex row a-center jc-sb orange'>
                        <p>{`${t('concerning')}`} {data.listing? <Link href={'/oglas/'+data.listing}>{data.listing}</Link> : ""}</p> 
                        <p>{datetime}</p>
                    </div>
                </div>
                <div className='user-message-buttons-wrap flex row a-start jc-sb'>
                    {type==="received_messages" &&
                        <div onClick={() => {setMessageDialogOpen(true)}} className=' flex column a-center jc-sb clickable'>
                            <ReplyIcon/>
                            <p className='orange'>{t('reply')}</p>
                        </div>
                    }
                    <div onClick={blockUser} className=' flex column a-center jc-sb clickable'>
                        <DoNotDisturbAlt />
                        <p className='orange'>{t('block')}</p>
                    </div>
                    <div onClick={deleteMessage} className=' flex column a-center jc-sb clickable'>
                        <Delete/>
                        <p className='orange'>{t('delete')}</p>
                    </div>
                    <NewMessageDialog open={messageDialogOpen} setOpen={setMessageDialogOpen} user={`${data.recipient.first_name} ${data.recipient.last_name}`} listing={data.listing} reply_to={data.id} old_subject={data.subject} old_message={data}/>
                    <ConfirmDialog open={showConfirmDialog} setOpen={setShowConfirmDialog} onConfirm={confirmBlock} title={t("block_confirm_title")} message={t("block_confirm_message")}></ConfirmDialog>
                    <ConfirmDialog open={showDeleteConfirmDialog} setOpen={setShowDeleteConfirmDialog} onConfirm={confirmDelete} title={t("block_confirm_title")} message={t("delete_confirm_message")}></ConfirmDialog>
                    <AlertDialog open={showAlertDialog} setOpen={setShowAlertDialog} title_color={alertColor} title={alertDialogTitle} message={alertDialogMessage} ></AlertDialog>
                </div>
            </div>
        )
    );
    
}


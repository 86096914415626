import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from '@mui/material';
import SelectField from '../../../../components/shared-components/form/select-field/select-field';
import InputField from '../../../../components/shared-components/form/input-field/input-field';
import { Info } from '@mui/icons-material';
import LocationAPI from '../../../../axios/locations';
import MiscAPI from '../../../../axios/misc';
import i18n from "i18next";

export const BasicInfo = ({ 
    currentUserType, handleChangeListing, formDataListing,
    handleChangeNumber, selectedPlace, setSelectedPlace, selectedSubplace, 
    setSelectedSubplace, selectedLocation, setSelectedLocation, 
    updateFormDataListing, setMapFocus 
}) => {
    const { t } = useTranslation(["listing", "common"]);
    const [places, setPlaces] = useState([]);
    const [subplaces, setSubplaces] = useState([]);
    const [locations, setLocations] = useState([]);
    const [userTypes, setUserTypes] = useState([]);

    useEffect(() => {
        LocationAPI.getListOfPlaces().then(response => setPlaces(response.data));
        fetchUserTypes();
    }, []);

    useEffect(() => {
        if (selectedPlace) {
            LocationAPI.getListOfSubplaces(selectedPlace.id).then(response => setSubplaces(response.data));
            updateFormDataListing({ ...formDataListing, "place": selectedPlace.id });
        }
    }, [selectedPlace]);

    useEffect(() => {
        if (selectedSubplace) {
            LocationAPI.getListOfLocations(selectedSubplace.id).then(response => setLocations(response.data));
            updateFormDataListing({ ...formDataListing, "subplace": selectedSubplace.id });
        }
    }, [selectedSubplace]);

    useEffect(() => {
        if (selectedLocation) {
            updateFormDataListing({ ...formDataListing, "location": selectedLocation.id });
            LocationAPI.getLocationCoordinates(selectedLocation.id).then(response => {
                setMapFocus([response.data.lat, response.data.lng]);
            });
        }
    }, [selectedLocation]);

    const fetchUserTypes = () => {
        MiscAPI.getUserTypeOptions(i18n.language).then(response => setUserTypes(response.data));
    }

    return (
        <div>
            <p className='listing-general-title'>{t("basic")}</p>
            <div className='listing-general-data '>
                <SelectField translate={false} options={userTypes} value={currentUserType} disabled />
                <InputField 
                    name="title"
                    handleChange={handleChangeListing}
                    value={formDataListing.title}
                    placeholder={t("title_placeholder") + " *"}
                />
                <div className='listing-general-item'>
                    <span><b>{t("price")}</b></span>
                    <InputField
                        name="price"
                        handleChange={handleChangeNumber}
                        value={formDataListing.price}
                        placeholder={t('price_placeholder')}
                        additionalInformation="€"
                    />
                </div>
            </div>
            {/* 
            <div className='general-info-box-wrap'>
                <div className='general-info-box'>
                    <Info/>
                    <p>{t("average")}</p>
                </div>
            </div>
            */}
            <div className='new-listing-location new-listing-section'>
                <Autocomplete
                    disablePortal
                    value={selectedPlace}
                    onChange={(e, newValue) => setSelectedPlace(newValue)}
                    id="combo-box-locations"
                    options={places}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={t("city") + " *"} />}
                />
                <Autocomplete
                    disablePortal
                    value={selectedSubplace}
                    onChange={(e, newValue) => setSelectedSubplace(newValue)}
                    id="combo-box-locations"
                    options={subplaces}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={t("city_part") + " *"} />}
                />
                <Autocomplete
                    disablePortal
                    value={selectedLocation}
                    onChange={(e, newValue) => setSelectedLocation(newValue)}
                    id="combo-box-locations"
                    options={locations}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={t("location") + " *"} />}
                />
            </div>
            <div className='new-listing-location new-listing-section'>
                <div className='listing-general-item'>
                    <span><b>{t("address")}</b></span>
                    <InputField 
                        name="address"
                        handleChange={handleChangeListing}
                        value={formDataListing.address}
                        placeholder={t("address")}
                    />
                </div>
            </div>
        </div>
    );
}

import React from "react";

import { ImageTitleButton } from "../../components/shared-components/buttons/image-title-button/image-title-button";
import { useTranslation } from "react-i18next";
import { PromotedListings } from '../../components/promoted-listings/promoted-listings';
import { useNavigate } from 'react-router-dom';


const img1 = require("../../assets/images/trazis.jpg");
const img2 = require("../../assets/images/saveti.jpg");
const img3 = require("../../assets/images/dokumentacija.jpg");
const img4 = require("../../assets/images/kredit.jpg");



export const MainPage = () => {
 
  const navigate = useNavigate();
  const { t } = useTranslation(["home"]);
  


  

  return (
    <div className="main-page-wrapper">
    
      <div className="main-page-content-wrapper flex row a-center jc-sb">
        
        <PromotedListings />

        <div className="blog-shotcuts">
          <ImageTitleButton title={t('title_1')} backgroundImage={img1} onClick={() => navigate('/trazis-nekretninu')}/>
          <ImageTitleButton title={t('title_2')} backgroundImage={img2} onClick={() => navigate('/saveti')}/>
          <ImageTitleButton title={t('title_3')} backgroundImage={img3} onClick={() => navigate('/dokumentacija')}/>
          <ImageTitleButton title={t('title_4')} backgroundImage={img4} onClick={() => navigate('/kredit')}/>
        </div>
      </div>
    </div>
  );
};

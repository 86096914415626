import React, { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';

const CheckBoxField = ({ direction = "", text, value, onChange, name, field }) => {
    return (
        <div className={`check-box-field-wrapper ${direction}`}>
            <div className='container'>
                <Checkbox
                    name={name}
                    checked={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled', 'data-field': field }}
                />
                <label>{text}</label>
            </div>
        </div>
    );
}

export default memo(CheckBoxField);
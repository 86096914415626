import React, { useState } from "react";
import Button from "../../../components/shared-components/buttons/button/button";
import InputField from '../../../components/shared-components/form/input-field/input-field';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UserAPI from "../../../axios/user";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
    const { t } = useTranslation(["login", "common"]);
    
    const [mail, setMail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setMail(e.target.value.trim());
    };

    const handleSubmit = () => {
        setSuccess(false);
        setError(false);

        UserAPI.passwordReset({ email: mail })
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <div className="page-wrap">
            <div>{t("reset_password_text")}</div>

            <InputField 
                name="email"
                type="text"
                placeholder=""
                label={t("email")}
                position="column"
                handleChange={handleChange}
            />

            <Button
                onClick={handleSubmit}
                color="primary"
                text={t("confirm_mail_button")}
                className="login-button"
            />

            {error && 
                <Alert severity="error">
                    <AlertTitle>{t("error", { ns: 'common' })}</AlertTitle>
                    {t("reset_password_err")}
                </Alert>
            }

            {success && 
                <Alert severity="success">
                    <AlertTitle>{t("success", { ns: 'common' })}</AlertTitle>
                    {t("reset_password_success")}
                </Alert>
            }
        </div>
    );
};


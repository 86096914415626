import React, {useContext, useState, useEffect, useCallback} from "react";
import { ConfigContext } from '../../contexts/config-context';

// import styles
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ImageViewer = ({ photos, videos }) => {
    
    const { api } = useContext(ConfigContext);
    const [photoData, setPhotoData] = useState([]);
    const [videoData, setVideoData] = useState([]);
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        const processedPhotos = photos.map(photo => {
            const keysArray = Object.keys(photo.photo.ratios.null.sources['image/webp']).map(Number).sort((a, b) => a - b);
            const lastKey = keysArray[keysArray.length - 1];
            return {
                src: `${api.host}:${api.port}${photo.photo.ratios.null.sources['image/webp'][lastKey]}`
            };
        });
        setPhotoData(processedPhotos);

        const processedVideos = videos.map(video => ({
            type: 'video',
            poster: `${api.host}:${api.port}${video.thumbnail}`,
            sources: [{ 'src': `${api.host}:${api.port}${video.video}`, 'type': "video/mp4" }]
        }));
        setVideoData(processedVideos);
    }, [photos, videos, api]);

    const handleItemClick = useCallback((index, item) => {
        setIndex(index);
    }, []);

    return (
        <div style={{ maxWidth: "750px", padding: "20px 0" }}>
            <Carousel onClickItem={handleItemClick}>
                {photoData.map(item => (
                    <div key={item.src}>
                        <img src={item.src} alt="Image viewer item" />
                    </div>
                ))}
                {videoData.map(item => (
                    <div key={item.poster}>
                        <img src={item.poster} alt="Video thumbnail" />
                    </div>
                ))}
            </Carousel>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={[...photoData, ...videoData]}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
            />
        </div>
    );
};

export default React.memo(ImageViewer);
import React, {useNavigate} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const Navigation = ({ direction = 'column', navItems = [], title }) => {
    const { t } = useTranslation(["navigation"]);
  
    const translatedNavItems = navItems.map(item => ({
        ...item,
        title: t(item.title)
    }));

   

    const classNames = ['navigation-wrapper', direction];

    return (
        <div className={classNames.join(' ')}>
            {title && <p className='navigation-title'>{title}</p>}
            <nav>
                <ul>
                    {translatedNavItems.map((item, index) => (
                        <li key={`nav-item-${index}`}>
                            <Link onClick={() => sessionStorage.removeItem('currentPage')} to={item.path} className='bold'>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )
}

import { FavoriteBorderOutlined } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../../../contexts/userContext";
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import ListingAPI from '../../../../axios/listing';

export const ListingLovedBy = ({className, listing}) => {


    const { user, setUser } = useContext(UserContext);
    const [numLiked, setNumLiked] = useState(0);
    const [userLiked, setUserLiked] = useState(false);


    useEffect(() => { 

        ListingAPI.countLikes(listing).then(res => {
            setNumLiked(res.data.count);
        }).catch(e =>{

        })
        
        if (user){
            ListingAPI.isListingLikedByUser(listing).then(res => {
                setUserLiked(res.data.is_liked)
            }).catch(e => {

            })
        }

        
    },[]);


    const handleClick = (e) => {
        e.stopPropagation();
        if (user){
            if (userLiked){
                ListingAPI.unlikeListing(listing).then(res => {
                    setUserLiked(false);
                    setNumLiked(numLiked-1);
                }).catch(e => {

                });

            }
            else{
                ListingAPI.likeListing(listing).then(res => {
                    setUserLiked(true);
                    setNumLiked(numLiked+1);
                }).catch(e => {

                });

            }
        }
    }

    return(
        <div className={`flex row a-center jc-center ${className && className}`}>
            <span className='small gray'>{numLiked}</span>
            {userLiked ? 
            <FavoriteOutlinedIcon onClick={handleClick} className='orange'/>
            :
            <FavoriteBorderOutlined onClick={handleClick} className='orange'/>
            }
            
        </div>
    )
}
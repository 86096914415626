import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const SectionHeading = ({color='blue', title, height, maxHeight, children, className}) => {

    return(
        <div className={`section-heading-wrap ${className && className}`}>
            <p className={`section-heading ${color}`}>{title}</p>
            <div className={`section-heading-content`} style={{height, maxHeight}}>
                <PerfectScrollbar>
                    {children}
                </PerfectScrollbar>
            </div>
        </div>
    )
}
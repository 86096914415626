import { Close } from '@mui/icons-material';
import React, { useContext } from 'react';
import { InterfaceContext } from '../../contexts/interface-context';
import { MainFilterMenu } from '../shared-components/search/main-filter-menu/main-filter-menu';

export const ResponsiveFilterMenu = () => {
    const {closeFilterMenu, filterMenuOpen} = useContext(InterfaceContext);
    return(
        <div className={`responsive-filter-menu-wrap ${filterMenuOpen && 'open'}`}>
            <div className="responsive-navigation">
                <Close onClick={() => closeFilterMenu()} sx={{cursor:'pointer'}} />
            </div>
            <MainFilterMenu/>
        </div>
    )
}